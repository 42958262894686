import { Document, View, Page, Image } from "@react-pdf/renderer";

const AnalyticsPdfComponent = ({ pageImage }) => (
  <Document>
    <Page>
      <View>
        <Image src={pageImage} />
      </View>
    </Page>
  </Document>
);

export default AnalyticsPdfComponent;
