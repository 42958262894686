import React, { ChangeEvent, useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import {
  IconContainer,
  RadioContainer,
  RadioExternal,
  RadioInternal,
  LabelInput,
  LeftContainer,
  Label,
} from "./styles";
import { TrashIcon } from "../../assets";
import { OPTION_PLACEHOLDER } from "../../utils/constants";

interface RadioProps {
  labelValue?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>, idToChange: number) => void;
  onRadioChange?: (id: number) => void;
  id?: number;
  onClickDeleteIcon?: (idToDelete: number) => void;
  checked?: boolean;
  className?: string;
  editable?: boolean;
  disable?: boolean;
}

const RadioButton: React.FC<RadioProps> = ({
  labelValue,
  id,
  onClickDeleteIcon,
  onChange,
  onRadioChange = () => {},
  checked = false,
  className = "",
  editable = false,
  disable = false,
}) => {
  const [focused, setFocused] = useState<boolean>(checked);

  useEffect(() => setFocused(checked), [checked]);

  const handleClick = (id: number) => {
    onRadioChange?.(id);
  };

  return (
    <RadioContainer>
      <LeftContainer>
        <RadioExternal
          className={className}
          active={checked}
          onClick={() => {
            !disable && onRadioChange(id);
          }}
        >
          <RadioInternal active={checked} />
        </RadioExternal>
        {editable ? (
          <LabelInput
            type="text"
            value={labelValue}
            onChange={(e) => onChange && onChange(e, id)}
            placeholder={OPTION_PLACEHOLDER}
          />
        ) : (
          <Label>{labelValue}</Label>
        )}
      </LeftContainer>
      {onClickDeleteIcon && (
        <IconContainer>
          <IconButton onClick={() => onClickDeleteIcon(id)}>
            <TrashIcon />
          </IconButton>
        </IconContainer>
      )}
    </RadioContainer>
  );
};

export default RadioButton;
