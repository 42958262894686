import { useEffect, useState } from "react";

import {
  CLICK_TO_ADD,
  ENTER_YOUR_TEXT_HERE,
  ADD_TAG,
} from "../../utils/constants";
import {
  Label,
  StyledAutocomplete,
  StyledTextField,
  TagsContainer,
  AddTagLabel,
  InputContainer,
} from "./styles";
import { QuestionOptionState } from "../../types";
import Tags from "../Tags";
import { compareTags } from "utils/methods/isInArray";

export interface TagsProps {
  questionOptions: QuestionOptionState[];
  questionId: number;
  initialResponses: string[];
  onChangeTags: (
    event: React.ChangeEvent<HTMLInputElement> | string,
    questionId: number
  ) => void;
  techStack: { primary: string[]; secondary: string[] };
}

const TagsInput = ({
  questionOptions,
  onChangeTags,
  questionId,
  initialResponses,
  techStack,
}: TagsProps) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedTags, setSelectedTags] = useState<string[]>(initialResponses);
  const [defaultTags, setDefaultTags] = useState<QuestionOptionState[]>(
    questionOptions.filter(
      (option) =>
        !initialResponses.includes(option.optionText) &&
        option.optionText &&
        !option.deleted_at
    )
  );

  const addExistingTag = (tag: QuestionOptionState) => {
    setSelectedTags((prev) => [...prev, tag.optionText]);
    setDefaultTags((prev) => prev.filter((t) => t.id !== tag.id));
  };

  const addNewTag = (tag: string) => {
    const existingDefaultTag = defaultTags.find((t) =>
      compareTags(t.optionText, tag)
    );
    const existingSelectedTag = selectedTags.find((t) => compareTags(t, tag));

    if (existingDefaultTag) return addExistingTag(existingDefaultTag);
    if (existingSelectedTag) return;

    setSelectedTags((prev) => [...prev, tag]);
  };

  const removeExistingTag = (tag: string) => {
    setSelectedTags((prev) => prev.filter((t) => t !== tag));
    const defaultTag = questionOptions.filter((t) => t.optionText === tag);
    if (defaultTag[0]) setDefaultTags((prev) => [...prev, defaultTag[0]]);
  };

  useEffect(() => {
    if (selectedTags.length > 0) {
      const concatenatedText = selectedTags.join(", ");
      onChangeTags(concatenatedText, questionId);
    } else onChangeTags("", questionId);
  }, [selectedTags]);

  return (
    <InputContainer>
      <StyledAutocomplete
        value={selectedTags}
        onChange={(e, newValue) => setInputValue(newValue as string)}
        clearOnEscape={false}
        clearIcon={null}
        open={false}
        onKeyDown={(e) => e.key === "Enter" && addNewTag(inputValue)}
        freeSolo
        multiple
        options={[]}
        renderTags={() => (
          <>
            {selectedTags.map((tag, index) => (
              <Tags
                addTag={addExistingTag}
                key={index}
                hasMargin
                label={tag}
                variantType="active"
                removeTag={removeExistingTag}
                techStack={techStack}
              />
            ))}
          </>
        )}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={ENTER_YOUR_TEXT_HERE}
            value={inputValue}
          />
        )}
        hasError={false}
      />
      <Label>{ADD_TAG}</Label>
      {defaultTags.length > 0 && <AddTagLabel>{CLICK_TO_ADD}</AddTagLabel>}
      <TagsContainer>
        {defaultTags.map((option) => (
          <Tags
            key={option.id}
            label={option.optionText}
            addTag={addExistingTag}
            removeTag={removeExistingTag}
            existingTag={option}
            techStack={techStack}
          />
        ))}
      </TagsContainer>
    </InputContainer>
  );
};
export default TagsInput;
