import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import PrivateRoutes from "./routes/PrivateRoutes";
import Login from "./pages/Login";
import Survey from "./pages/Survey";
import SurveyDetails from "./pages/SurveyDetails";
import Design from "./pages/Design";
import theme from "./definitions/theme";
import Mobile from "./pages/Mobile";
import Settings from "pages/Settings";
import Error from "./pages/Error";
import PathRules from "pages/PathRules";
import Responses from "./pages/Responses";
import ResponseDetails from "pages/ResponseDetails";
import Analytics from "pages/Analytics";
function App(): JSX.Element {
  const isMobile = useMediaQuery(theme.breakpoints.down(760));

  return (
    <div className="App">
      <Router>
        <Routes>
          {isMobile ? (
            <Route element={<Mobile />} path="/" />
          ) : (
            <>
              <Route element={<PrivateRoutes />}>
                <Route element={<Survey />} path="/survey" />
                <Route element={<SurveyDetails />} path="/survey/:id" />
                <Route element={<Design />} path="/design" />
                <Route element={<Settings />} path="/settings" />
                <Route element={<Responses />} path="/responses" />
                <Route element={<Analytics />} path="/analytics" />
                <Route element={<PathRules />} path="/survey/:id/path-rules" />
                <Route element={<ResponseDetails />} path="/responses/:id" />
              </Route>
              <Route element={<Login />} path="/" />
            </>
          )}
          <Route path="*" element={<Error />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
