import styled from "styled-components";
import { Divider } from "@mui/material";

export const Container = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100vh",
    padding: "40px 84px 40px 84px",
  };
});

export const Title = styled("h1")(({ theme }) => {
  const {
    customSizes: {
      heading: {
        h6: { fontSize, fontWeight, lineHeight },
      },
      body: {
        title1: { regular },
      },
    },
    customPalette: { blackGrey },
    customFonts: { poppinsSemiBold },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    color: blackGrey,
    fontFamily: poppinsSemiBold,

    [theme.breakpoints.down(760)]: {
      lineHeight: "16px",
      fontSize: regular.fontSize,
    },
  };
});

export const Line = styled(Divider)(({ theme }) => {
  return {
    "&.MuiDivider-root": {
      width: "1080px",
      borderColor: theme.customPalette.mediumLightGrey,
    },
    [theme.breakpoints.down(760)]: {
      "&.MuiDivider-root": {
        width: "100%",
      },
    },
  };
});
