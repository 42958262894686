import { AxiosResponse } from "axios";

import api from "../../../api";
import { setApiError, clearApiError } from "../../../redux/slices/companySlice";
import store from "../../../redux/store";
import { updateQuestionGroup } from "redux/slices/surveySlice";

const { dispatch } = store;

export const deletePathRule = async (
  questionGroup,
  id: number,
  surveyId: number
) => {
  await api
    .delete(`/path-rules/${id}`)
    .then((res: AxiosResponse) => {
      dispatch(clearApiError());
      const updatedGroup = {
        ...questionGroup,
        pathRule: questionGroup.pathRule.filter((rule) => rule.id !== id),
      };
      dispatch(updateQuestionGroup({ surveyId, questionGroup: updatedGroup }));
    })
    .catch((error) => {
      console.log("[deletePathRule error]: ", error);
      dispatch(setApiError({ error: true }));
    });
};
