// src/utils/linkUtils.ts

import { GroupedQuestionResponses, UserResponseState } from "types";
import { generateToken } from "../../pages/Responses/services/generateToken";
import { parseNameToPath } from "./parseNameToPath";

export const getBifurcateAnswer = (
  bifurcateQuestion: GroupedQuestionResponses
) => {
  const bifurcateAdminAnswer =
    bifurcateQuestion?.questionResponses[0].adminQuestionOption;
  const bifurcateClientAnswer =
    bifurcateQuestion?.questionResponses[0].clientQuestionOption;
  return bifurcateQuestion
    ? bifurcateAdminAnswer || bifurcateClientAnswer
    : true;
};

export const enableLinkGeneration = (
  userResponses: UserResponseState[],
  index: number
) => {
  const bifurcateQuestion = userResponses[index].questionResponses.find(
    (questionResponse: any) => questionResponse.question.type === "bifurcate"
  );

  const bifurcateAnswer = getBifurcateAnswer(bifurcateQuestion);

  return !!bifurcateAnswer && !userResponses[index].deleted_at;
};

export const handleLinkGeneration = async (
  userResponseId: number,
  surveyName: string,
  clientURL: string | undefined,
  setLinkForUser: (url: string) => void,
  setLinkModalOpen?: (isOpen: boolean) => void
) => {
  const token = await generateToken(userResponseId);
  const path = parseNameToPath(surveyName);
  const queryParams = `/${path}?token=${token}`;
  const fullUrl = `${
    clientURL || process.env.REACT_APP_BASE_URL
  }${queryParams}`;
  setLinkForUser(fullUrl);
  if (setLinkModalOpen) setLinkModalOpen(true);
};
