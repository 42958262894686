import { useState } from "react";

import { updateResponses } from "../pages/ResponseDetails/services/updateResponses";
import { UpdateResponseState, AdminResponsesState } from "../types";
import {
  convertGroupedToAdminResponses,
  groupQuestionResponses,
  QuestionType,
} from "../utils/utils";

interface ResponseLogicHookProps {
  userResponseId: number;
  responses: AdminResponsesState[];
}

const { CHECKBOX, NUMERIC_INPUT, BIFURCATE } = QuestionType;

const useResponseLogic = ({
  userResponseId,
  responses,
}: ResponseLogicHookProps) => {
  const [newResponses, setNewResponses] = useState<UpdateResponseState>({
    id: userResponseId,
    responses,
  });

  const handleSaveChanges = async () => {
    const res = await updateResponses(newResponses);
    if (
      newResponses.responses.length === 1 &&
      newResponses.responses[0].questionType === BIFURCATE
    ) {
      const groupedResponses = groupQuestionResponses(res.questionResponses);
      const responses = convertGroupedToAdminResponses(groupedResponses);
      setNewResponses((prev) => {
        return {
          ...prev,
          responses,
        };
      });
    }
  };

  const onChangeText = (value: string, questionId: number) => {
    setNewResponses((prevResponses) => {
      if (!prevResponses || !prevResponses.responses) return prevResponses;

      const updatedResponses = prevResponses.responses.map((response) => {
        if (response.questionId === questionId) {
          const updatedQuestionResponses = [{ adminText: value }];

          return {
            ...response,
            questionResponses: updatedQuestionResponses,
          };
        }
        return response;
      });

      return {
        ...prevResponses,
        responses: updatedResponses,
      };
    });
  };

  const onChangeQuestionOption = (
    questionId: number,
    questionType: string,
    optionText?: string,
    optionId?: number,
    active?: boolean
  ) => {
    setNewResponses((prevResponses) => {
      if (!prevResponses || !prevResponses.responses) return prevResponses;

      const updatedResponses = prevResponses.responses.map((response) => {
        if (response.questionId === questionId) {
          let updatedQuestionResponses;

          if (questionType === CHECKBOX) {
            if (active) {
              // Add new checkbox option
              updatedQuestionResponses = [
                ...response.questionResponses,
                {
                  ...(optionId && { adminQuestionOptionId: optionId }),
                  ...(optionText && { adminText: optionText }),
                },
              ];
            } else {
              // Remove existing checkbox option
              updatedQuestionResponses = response.questionResponses.filter(
                (resp) =>
                  resp.adminQuestionOptionId !== optionId ||
                  resp.adminText !== optionText
              );
            }
          } else if (questionType === NUMERIC_INPUT) {
            const existingResponseIndex = response.questionResponses.findIndex(
              (qr) => qr.adminQuestionOptionId === optionId
            );

            if (existingResponseIndex !== -1) {
              updatedQuestionResponses = response.questionResponses.map(
                (qr, index) =>
                  index === existingResponseIndex
                    ? { ...qr, adminText: optionText }
                    : qr
              );
            } else {
              updatedQuestionResponses = [
                ...response.questionResponses,
                { adminQuestionOptionId: optionId, adminText: optionText },
              ];
            }
          } else {
            updatedQuestionResponses = [
              {
                ...(optionId && { adminQuestionOptionId: optionId }),
                ...(optionText && { adminText: optionText }),
              },
            ];
          }

          return {
            ...response,
            questionResponses: updatedQuestionResponses,
          };
        }
        return response;
      });

      return {
        ...prevResponses,
        responses: updatedResponses,
      };
    });
  };

  return {
    onChangeText,
    onChangeQuestionOption,
    handleSaveChanges,
  };
};

export default useResponseLogic;
