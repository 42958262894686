import { AxiosResponse } from "axios";

import api from "../../../api";
import store from "../../../redux/store";
import { setApiError } from "../../../redux/slices/companySlice";
import { CategoryState } from "types";

const { dispatch } = store;

export const getMaturities = async (): Promise<CategoryState[]> => {
  const maturities = await api
    .get("/categories/maturities")
    .then((res: AxiosResponse) => {
      return res?.data;
    })
    .catch((error) => {
      console.log("[getMaturities error]: ", error);
      dispatch(setApiError({ error: true }));
    });

  return maturities;
};
