import styled from "styled-components";

interface NoDataTextProps {
  isTitle?: boolean;
}

export const NoDataContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "32px",
  marginTop: "32px",
});

export const TextContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "16px",
  marginBottom: "32px",
});

export const NoDataText = styled("span")<NoDataTextProps>(
  ({ theme, isTitle }) => {
    const {
      customSizes: {
        body: {
          description: {
            regular: { regularFontSize, regularFontWeight, regularLineHeight },
          },
        },
        card: {
          mobile: {
            title: {
              fontSize: cardMobileTitleFontSize,
              fontWeight: cardMobileTitleFontWeight,
              lineHeight: cardMobileTitleLineHeight,
            },
          },
        },
      },
      customFonts: { poppins },
      customPalette: { darkGrey, darkMediumGrey },
    } = theme;

    return {
      fontSize: isTitle ? cardMobileTitleFontSize : regularFontSize,
      fontWeight: isTitle ? cardMobileTitleFontWeight : regularFontWeight,
      lineHeight: isTitle ? cardMobileTitleLineHeight : regularLineHeight,
      color: isTitle ? darkGrey : darkMediumGrey,
      fontFamily: poppins,
    };
  }
);
