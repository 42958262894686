import theme from "definitions/theme";
import styled from "styled-components";
import { ExpandMore as ExpandMoreIcon } from "assets";

export const StyledExpandMore = styled(ExpandMoreIcon)<{ expanded: boolean }>`
  transform: ${({ expanded }) =>
    expanded ? "rotate(180deg)" : "rotate(0deg)"};
  transition: transform 0.3s;
`;

export const FiltersContainer = styled("div")(({ theme }) => {
  const {
    customPalette: {
      card: { boxShadow },
    },
  } = theme;

  return {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "8px 8px 16px 30px",
    backgroundColor: theme.customPalette.white,
    borderRadius: "10px",
    boxShadow: boxShadow,
    marginTop: "16px",
  };
});

export const TitleContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  justifyContent: "space-between",
}));

export const Title = styled("h3")(() => ({
  marginBottom: "16px",
  fontSize: "16px",
  fontWeight: "bold",
  color: "#333",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
}));

export const FilterRow = styled("div")(() => ({
  display: "flex",
  width: "80%",
  alignItems: "center",
  marginBottom: "16px",
  gap: "30px",
}));

interface LabelProps {
  error?: boolean;
  budget?: boolean;
}

export const Label = styled("p")<LabelProps>`
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.customSizes.textField.label.fontSize};
  font-weight: ${theme.customSizes.textField.label.fontWeight};
  line-height: ${theme.customSizes.textField.label.lineHeight};
  color: ${({ error }) =>
    error ? theme.customPalette.error : theme.customPalette.darkMediumGrey};
  height: 20px;
  margin: 0 0 10px 0;
`;

export const StyledTextField = styled("label")(() => ({
  fontSize: "14px",
  color: "#666",
}));

export const AddFilterButton = styled("h1")(({ theme }) => {
  const {
    customPalette: { secondary },
    customFonts: { poppins },
  } = theme;

  return {
    fontWeight: 400,
    lineHeight: "24px",
    fontFamily: poppins,
    opacity: "100%",
    marginTop: 0,
    paddingLeft: "50px",
    fontSize: "14px",
    color: secondary,
    padding: 0,
    margin: 0,
    cursor: "pointer",
  };
});

export const IconButton = styled("div")(({ theme }) => {
  const {
    customPalette: { secondary },
  } = theme;

  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    cursor: "pointer",
    height: "35px",
    width: "35px",
    borderRadius: "5px",
    ":hover": {
      "&.fill": {
        path: {
          fill: secondary,
        },
      },
      "&.stroke": {
        path: {
          stroke: secondary,
        },
      },
    },

    "&.ml": {
      margin: "0 0px 0 16px",
    },
    "&.mobile": {
      margin: "0",
    },
  };
});
