import { AxiosResponse } from "axios";

import api from "../../../api";
import store from "../../../redux/store";
import {
  clearNewResponseError,
  setNewResponseError,
} from "redux/slices/userResponseSlice";

const { dispatch } = store;

interface UserBody {
  name: string;
  surname: string;
  email: string;
  company: string;
}

interface Body {
  companyId: number;
  user: UserBody;
  surveyId: number;
}

export const sendNewUser = async (body: UserBody) =>
  await api
    .post("/users", body)
    .then((res: AxiosResponse) => {
      dispatch(clearNewResponseError());
      return res?.data;
    })
    .catch((error) => {
      dispatch(setNewResponseError({ error: true }));
    });

export const sendNewResponse = async (body: Body) =>
  await api
    .post("/user-responses/empty", body)
    .then((res: AxiosResponse) => {
      dispatch(clearNewResponseError());
      return res?.data;
    })
    .catch((error) => {
      dispatch(setNewResponseError({ error: true }));
    });
