import React from "react";
import { DraggableProvided } from "react-beautiful-dnd";

import QuestionCard from "../questionCard";
import TextInput from "../textInput";
import Tag from "../tag";
import { InputContainer, TagsContainer } from "./styles";
import { ADD_NEW_TAG } from "../../utils/constants";
import { QuestionState } from "../../types";
import useCardLogic, { DataType } from "../../hooks/useCardLogic";

interface TagsCardProps {
  provided: DraggableProvided;
  id: number;
  questionGroupId: number;
  data: DataType;
  setQuestions: (value: React.SetStateAction<QuestionState[]>) => void;
  lastIndex: number;
}

const TagsCard = ({
  provided,
  id,
  questionGroupId,
  data,
  setQuestions,
  lastIndex,
}: TagsCardProps) => {
  const {
    title,
    onChangeTitle,
    subtitle,
    onChangeSubtitle,
    onClickAddButton,
    checked,
    switchHandler,
    onChangeLabel,
    onClickDeleteButton,
    options,
    newData,
  } = useCardLogic({ id, initialData: data, setQuestions });

  return (
    <QuestionCard
      provided={provided}
      id={id}
      questionGroupId={questionGroupId}
      titleValue={title}
      onChangeTitle={onChangeTitle}
      subtitleValue={subtitle}
      onChangeSubtitle={onChangeSubtitle}
      checkedSwitch={checked}
      switchHandler={switchHandler}
      onClickAddButton={onClickAddButton}
      addButtonText={ADD_NEW_TAG}
      data={newData}
      lastIndex={lastIndex}
    >
      <InputContainer>
        <TextInput
          placeholder="Type tags options"
          showErrorLabel={false}
          disabled
          className="tag"
        />
      </InputContainer>
      <TagsContainer>
        {options?.map(({ optionText, id }) => (
          <Tag
            key={id}
            id={id}
            labelValue={optionText}
            onChange={onChangeLabel}
            onClickDeleteIcon={onClickDeleteButton}
          />
        ))}
      </TagsContainer>
    </QuestionCard>
  );
};

export default TagsCard;
