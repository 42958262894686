import { useState, useEffect } from "react";
import { Collapse } from "@mui/material";

import {
  FiltersContainer,
  Title,
  FilterRow,
  Label,
  AddFilterButton,
  StyledTextField,
  IconButton,
  StyledExpandMore,
  TitleContainer,
} from "./styles";
import CustomSelect from "../customSelect";
import { CategoryState } from "types";
import StyledSelect from "../../pages/Analytics/select";
import {
  ADD_FILTER_ROW,
  FILTERS,
  SELECT_X_AXIS,
  SELECT_DATA_FOR_X_AXIS,
} from "utils/constants";
import { InputState } from "types";
import { getMaturities } from "pages/Analytics/services/getMaturities";
import { getTemperatures } from "pages/Analytics/services/getTemperatures";
import { getIndustries } from "pages/Analytics/services/getIndustries";
import { getLocations } from "pages/Analytics/services/getLocations";
import { getTechStacks } from "pages/Analytics/services/getTechStacks";
import { FiltersVector, TrashIcon } from "assets";
import { xAxisOptions, filters } from "pages/Analytics/common/filters";

interface FiltersSectionProps {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  selectedTags: {
    selectedFilter: string | null;
    selectedIds: CategoryState[];
  }[];
  setSelectedTags: (selectedTags) => void;
  filterRows: {
    selectedFilter: number | null;
    selectedFilterValue: string | null;
    styledSelectOptions: CategoryState[];
  }[];
  setFilterRows: (
    filterRows: {
      selectedFilter: number | null;
      selectedFilterValue: string | null;
      styledSelectOptions: CategoryState[];
    }[]
  ) => void;
  xAxis: string;
  setXAxis: (xAxis: string) => void;
}

const FiltersSection = ({
  expanded,
  setExpanded,
  selectedTags,
  setSelectedTags,
  filterRows,
  setFilterRows,
  xAxis,
  setXAxis,
}: FiltersSectionProps) => {
  const [industryOptions, setIndustryOptions] = useState<CategoryState[]>([]);
  const [locationOptions, setLocationOptions] = useState<CategoryState[]>([]);
  const [maturityOptions, setMaturityOptions] = useState<CategoryState[]>([]);
  const [technologyOptions, setTechnologyOptions] = useState<CategoryState[]>(
    []
  );
  const [temperatureOptions, setTemperatureOptions] = useState<CategoryState[]>(
    []
  );

  const xAxisInput: InputState = {
    label: "xAxis",
    placeholder: "Select X",
    value: xAxis,
    setValue: setXAxis,
    isSelect: true,
    selectOptions: xAxisOptions,
  };

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const [maturities, temperatures, industries, locations, technologies] =
          await Promise.all([
            getMaturities(),
            getTemperatures(),
            getIndustries(),
            getLocations(),
            getTechStacks(),
          ]);

        setMaturityOptions(maturities);
        setTemperatureOptions(temperatures);
        setIndustryOptions(industries);
        setLocationOptions(locations);
        setTechnologyOptions(technologies);
      } catch (error) {
        console.error("Failed to fetch options:", error);
      }
    };

    fetchOptions();
  }, []);

  const handleXAxisValueClick = (selectedId: number, input: InputState) => {
    const selectedFilter = xAxisOptions.find(
      (filter) => filter.key === selectedId
    );

    if (selectedFilter) {
      setXAxis(selectedFilter.value);
      input.setValue(selectedFilter.value);
    }
  };

  const handleFilterValueClick = (selectedId: number, index: number) => {
    const newFilterRows = [...filterRows];
    newFilterRows[index].selectedFilter = selectedId;
    newFilterRows[index].selectedFilterValue = filters.find(
      (filter) => filter.key === selectedId
    )?.value;
    switch (selectedId) {
      case 1:
        newFilterRows[index].styledSelectOptions = industryOptions;
        break;
      case 2:
        newFilterRows[index].styledSelectOptions = locationOptions;
        break;
      case 3:
        newFilterRows[index].styledSelectOptions = temperatureOptions;
        break;
      case 4:
        newFilterRows[index].styledSelectOptions = technologyOptions;
        break;
      case 5:
        newFilterRows[index].styledSelectOptions = maturityOptions;
        break;
      default:
        newFilterRows[index].styledSelectOptions = [];
    }

    setFilterRows(newFilterRows);

    setSelectedTags((prevTags) => {
      const newTags = [...prevTags];
      if (newTags[index]) {
        newTags[index].selectedIds = [];
      }
      return newTags;
    });
  };

  const addFilterRow = () => {
    setFilterRows([
      ...filterRows,
      {
        selectedFilter: null,
        selectedFilterValue: null,
        styledSelectOptions: [],
      },
    ]);
  };

  const removeFilterRow = (index: number) => {
    const newFilterRows = [...filterRows];
    newFilterRows.splice(index, 1);
    setFilterRows(newFilterRows);

    setSelectedTags((prevTags) => {
      const newTags = [...prevTags];
      newTags.splice(index, 1);
      return newTags;
    });
  };

  const handleTagClick = (
    value: CategoryState,
    rowIndex: number,
    selectedFilter: string | null
  ) => {
    setSelectedTags((prevTags) => {
      const newTags = [...prevTags];
      const currentRow = newTags[rowIndex];

      if (currentRow) {
        if (currentRow.selectedIds.some((tag) => tag.id === value.id)) {
          currentRow.selectedIds = currentRow.selectedIds.filter(
            (tag) => tag.id !== value.id
          );
        } else {
          currentRow.selectedIds = [...currentRow.selectedIds, value];
        }
        currentRow.selectedFilter = selectedFilter;
      } else {
        newTags[rowIndex] = {
          selectedFilter,
          selectedIds: [value],
        };
      }
      return newTags;
    });
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <FiltersContainer>
      <TitleContainer onClick={handleExpandClick}>
        <Title>
          <FiltersVector style={{ margin: "0px 10px 0 0" }} />
          {SELECT_DATA_FOR_X_AXIS}
        </Title>
        <IconButton>
          <StyledExpandMore expanded={expanded} />
        </IconButton>
      </TitleContainer>
      <Collapse in={expanded}>
        <Label>{SELECT_X_AXIS}</Label>
        <FilterRow>
          <CustomSelect
            selectedValues={
              xAxis
                ? [
                    {
                      key: filters.find((filter) => filter.value === xAxis)
                        ?.key,
                      value: xAxis,
                    },
                  ]
                : []
            }
            handleValueClick={(selectedId: number) => {
              handleXAxisValueClick(selectedId, xAxisInput);
            }}
            options={xAxisOptions.reduce(
              (acc, filter) => ({ ...acc, [filter.key]: filter.value }),
              {}
            )}
            placeholder="Select X axis"
          />
        </FilterRow>
        <Label>{FILTERS}</Label>
        {filterRows.map((filterRow, index) => {
          return (
            <FilterRow key={index}>
              <CustomSelect
                selectedValues={
                  filterRow.selectedFilter
                    ? [
                        {
                          key: filterRow.selectedFilter,
                          value: filters.find(
                            (filter) => filter.key === filterRow.selectedFilter
                          )?.value,
                        },
                      ]
                    : []
                }
                handleValueClick={(selectedId: number) =>
                  handleFilterValueClick(selectedId, index)
                }
                options={filters
                  .filter(
                    (filter) =>
                      !filterRows.some(
                        (row) => row.selectedFilter === filter.key
                      )
                  )
                  .reduce(
                    (acc, filter) => ({ ...acc, [filter.key]: filter.value }),
                    {}
                  )}
                placeholder="Select filter"
              />
              <StyledTextField>Is</StyledTextField>
              <StyledSelect
                selectedTags={selectedTags[index]?.selectedIds || []}
                handleTagClick={(value: CategoryState) => {
                  handleTagClick(value, index, filterRow.selectedFilterValue);
                }}
                options={filterRow.styledSelectOptions.reduce(
                  (acc, option) => ({ ...acc, [option.id]: option.name }),
                  {}
                )}
                disabled={!filterRow.selectedFilter}
              />
              <IconButton
                className="fill"
                onClick={(event) => removeFilterRow(index)}
              >
                <TrashIcon />
              </IconButton>
            </FilterRow>
          );
        })}

        <AddFilterButton onClick={addFilterRow}>
          {ADD_FILTER_ROW}
        </AddFilterButton>
      </Collapse>
    </FiltersContainer>
  );
};

export default FiltersSection;
