import styled from "styled-components";

export const Container = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    margin: "20vh 30vh",
    gap: "100px",
  };
});

export const LeftContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "720px",
    gap: "40px",
  };
});

export const RightContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  };
});

export const Title = styled("text")(({ theme }) => {
  const {
    customSizes: {
      heading: {
        h4: { fontSize, fontWeight, lineHeight },
      },
    },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    fontFamily: poppins,
    color: theme.customPalette.primary,
  };
});

export const Subtitle = styled("text")(({ theme }) => {
  const {
    customSizes: {
      body: {
        title1: {
          semibold: { fontSize, fontWeight, lineHeight },
        },
      },
    },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    fontFamily: poppins,
    color: theme.customPalette.primary,
  };
});

export const Text = styled("text")(({ theme }) => {
  const {
    customSizes: {
      body: {
        description: {
          regular: { fontSize, fontWeight, lineHeight },
        },
      },
    },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    fontFamily: poppins,
    color: theme.customPalette.primary,
  };
});

export const IconContainer = styled("div")(({ theme }) => {
  return {
    justifyContent: "center",
    alignItems: "center",
  };
});

export const ButtonContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "left",
  };
});
