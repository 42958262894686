import { AxiosResponse } from "axios";

import api from "../../../api";
import store from "../../../redux/store";
import {
  clearApiError,
  setApiError,
  setTotalResponses,
  setUserResponses,
} from "redux/slices/userResponseSlice";

const { dispatch } = store;

interface Params {
  term?: string;
  page: number;
  startDate?: string;
  endDate?: string;
  tags?: string[];
  surveyId?: number;
}

export const filterResponses = async ({
  term,
  page,
  surveyId,
  startDate,
  endDate,
  tags,
}: Params) => {
  const url = `/user-responses/search?page=${page}${
    term ? `&term=${term}` : ""
  }${startDate ? `&startDate=${startDate}` : ""}${
    endDate ? `&endDate=${endDate}` : ""
  }${tags ? `&tags=${tags}` : ""
  }${surveyId ? `&surveyId=${surveyId}` : ""}`;

  await api
    .get(url)
    .then((res: AxiosResponse) => {
      dispatch(clearApiError());
      dispatch(setUserResponses(res?.data.data));
      dispatch(setTotalResponses(res?.data.total));
    })
    .catch((error) => {
      console.log("[filterResponses error]: ", error);
      dispatch(setApiError({ error: true }));
    });
};
