import styled from "styled-components";

import { ExpandMore, TrashIcon } from "assets";

export const DropdownContainer = styled("div")({
  position: "relative",
  display: "inline-block",
  zIndex: 1001,
});

export const ButtonsContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: "48px",
  width: "165px",
});

export const SaveFilterButton = styled("button")(({ theme }) => {
  const {
    customFonts: { poppins },
    customPalette: { white, secondary, hover },
    customSizes: {
      body: {
        smallDescription: { medium },
      },
    },
  } = theme;

  return {
    backgroundColor: secondary,
    border: `1px solid ${white}`,
    cursor: "pointer",
    color: white,
    fontFamily: poppins,
    fontSize: medium.fontSize,
    fontWeight: medium.fontWeight,
    lineHeight: medium.lineHeight,
    display: "flex",
    alignItems: "center",
    padding: "0 4px 0 16px",
    justifyContent: "space-between",
    height: "48px",
    width: "128px",
    borderRadius: "8px 0 0 8px",
    "&:hover": {
      backgroundColor: hover,
    },
  };
});

export const DownArrowButton = styled("button")<{ isOpen: boolean }>(
  ({ theme, isOpen }) => {
    const {
      customPalette: { white, secondary, hover },
    } = theme;

    return {
      backgroundColor: isOpen ? hover : secondary,
      border: `1px solid ${white}`,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "48px",
      width: "37px",
      borderRadius: "0 8px 8px 0",
      "&:hover": {
        backgroundColor: hover,
      },
    };
  }
);

export const DropdownContent = styled("div")(({ theme }) => {
  const {
    customPalette: { white, secondary },
  } = theme;

  return {
    position: "absolute",
    backgroundColor: white,
    minWidth: "270px",
    borderRadius: "8px",
    padding: "16px",
    zIndex: 1,
    right: 0,
    marginTop: "16px",
    border: `2px solid ${secondary}`,
    width: "100%",
  };
});

export const FilterItem = styled("div")(({ theme }) => {
  const {
    customPalette: { lightGrey },
  } = theme;

  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    width: "266px",
    marginLeft: "-16px",
    padding: "0 16px 0 18px",
    "&:hover": {
      backgroundColor: lightGrey,
    },
  };
});

export const DropdownArrow = styled(ExpandMore)<{ isOpen: boolean }>(
  ({ theme, isOpen }) => {
    const {
      customPalette: { white },
    } = theme;

    return {
      path: {
        fill: white,
      },
      transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
    };
  }
);

export const Text = styled("p")(({ theme }) => {
  const {
    customFonts: { poppins },
    customSizes: {
      uploadStatus: { fontSize, fontWeight, lineHeight },
    },
  } = theme;

  return {
    fontFamily: poppins,
    fontSize,
    fontWeight,
    lineHeight,
  };
});

export const StyledTrashIcon = styled(TrashIcon)(({ theme }) => {
  const {
    customPalette: { secondary },
  } = theme;

  return {
    cursor: "pointer",
    "&:hover": {
      path: {
        fill: secondary,
      },
    },
  };
});
