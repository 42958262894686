import { createGlobalStyle } from "styled-components";

import PoppinsBold from "./fonts/Poppins-Bold.ttf";
import PoppinsExtraBold from "./fonts/Poppins-ExtraBold.ttf";
import PoppinsLight from "./fonts/Poppins-Light.ttf";
import PoppinsRegular from "./fonts/Poppins-Regular.ttf";
import PoppinsSemiBold from "./fonts/Poppins-SemiBold.ttf";

const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    padding: 0;
  }

  @font-face {
    font-family: 'Poppins-Bold';
    src: local('PoppinsBold'), url(${PoppinsBold}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins-ExtraBold';
    src: local('PoppinsExtraBold'), url(${PoppinsExtraBold}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins-Light';
    src: local('PoppinsLight'), url(${PoppinsLight}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins-Regular';
    src: local('PoppinsRegular'), url(${PoppinsRegular}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins-SemiBold';
    src: local('Poppins-SemiBold'), url(${PoppinsSemiBold}) format('truetype');
    font-display: swap;
  }
`;

export default GlobalStyle;
