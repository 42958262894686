import React from "react";
import { useNavigate } from "react-router-dom";

import {
  ButtonContainer,
  Container,
  IconContainer,
  LeftContainer,
  RightContainer,
  Subtitle,
  Text,
  Title,
} from "./styles";
import { NotFoundIcon } from "../../assets";
import Button from "../button";
import {
  GO_BACK,
  OOPS,
  PAGE_NOT_FOUND,
  SORRY_WE_CANT_FIND_THE_PAGE_YOU_ARE_LOOKING_FOR,
} from "../../utils/constants";

const ErrorNotFound: React.FC = ({}) => {
  const navigate = useNavigate();

  return (
    <Container>
      <LeftContainer>
        <Title>{OOPS}</Title>
        <Subtitle>{PAGE_NOT_FOUND}</Subtitle>
        <Text>{SORRY_WE_CANT_FIND_THE_PAGE_YOU_ARE_LOOKING_FOR}</Text>
        <ButtonContainer>
          <Button
            id="btn"
            variantType="primary"
            onClick={() => navigate(-1)}
            label={GO_BACK}
            width={264}
            height={48}
          />
        </ButtonContainer>
      </LeftContainer>
      <RightContainer>
        <IconContainer>
          <NotFoundIcon />
        </IconContainer>
      </RightContainer>
    </Container>
  );
};

export default ErrorNotFound;
