import { AxiosResponse } from "axios";

import api from "../../../api";
import store from "../../../redux/store";
import {
  clearApiError,
  setApiError,
  updateQuestionGroup,
} from "../../../redux/slices/surveySlice";

const { dispatch } = store;

export const getQuestionGroup = async (id: number, surveyId: number) =>
  await api
    .get(`/question-groups/${id}`)
    .then((res: AxiosResponse) => {
      const data = res?.data;
      delete data?.survey;

      dispatch(updateQuestionGroup({ surveyId, questionGroup: data }));
      dispatch(clearApiError());

      return res?.data;
    })
    .catch((error) => {
      console.log("[getQuestionGroup error]: ", error);
      dispatch(setApiError({ error: true }));
    });
