import { ColorBox } from "material-ui-color";
import styled from "styled-components";
import { PencilIcon } from "../../assets";

export const LabelContainer = styled("div")({
  width: "160px",
  display: "flex",
  justifyContent: "center",
});

export const ColorLabel = styled("p")(({ theme }) => {
  const {
    customSizes: {
      body: {
        description: { regular },
      },
    },
    customPalette: { blackGrey },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize: regular.fontSize,
    fontWeight: regular.fontWeight,
    lineHeight: regular.lineHeight,
    fontFamily: poppins,
    color: blackGrey,
    margin: 0,
    textAlign: "center",
  };
});

export const ColorPickerContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  border: "1px solid #E4E4E4",
  borderRadius: "8px",
  padding: "8px 10px 8px 10px",
  width: "336px",
  height: "72px",
  zIndex: 1,
});

export const ColorPickerBox = styled(ColorBox)(() => {
  return {
    "&.MuiBox-root": {
      padding: "8px",
      backgroundColor: "white",
      borderRadius: "8px",
    },
  };
});

export const ButtonLogo = styled(PencilIcon as any)(() => {
  return {
    width: "32px",
    height: "32px",
  };
});

export const ColorBoxContainer = styled("div")({
  border: "1px solid #ccc",
  borderRadius: "8px",
  position: "absolute",
  height: "fit-content",
  width: "fit-content",
  marginLeft: "336px",
  marginTop: "264px",
});

export const ColorButton = styled("button")`
  background-color: ${({ color }) => color};
  width: 60px;
  height: 60px;
  border: 1px solid #cacaca;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
