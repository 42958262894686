import React from "react";

import NumericInputCard from "../numericInputCard";
import { QuestionType } from "../../utils/utils";
import DatePickerCard from "../datePickerCard";
import CheckboxCard from "../checkboxCard";
import TagsCard from "../tagsCard";
import RadioButtonCard from "../radioButtonCard";
import ShortTextCard from "../shortTextCard";
import LongTextCard from "../longTextCard";
import DropdownCard from "../dropdownCard";
import DropdownInputCard from "../dropdownInputCard";
import { QuestionState } from "../../types";
import { DataType } from "../../hooks/useCardLogic";
import { DraggableProvided } from "react-beautiful-dnd";

interface QuestionMatcherProps {
  provided: DraggableProvided;
  type: string;
  id: number;
  questionGroupId: number;
  data: DataType;
  setQuestions: (value: React.SetStateAction<QuestionState[]>) => void;
  lastIndex: number;
}

const QuestionMatcherComponent = ({
  provided,
  type,
  id,
  questionGroupId,
  data,
  setQuestions,
  lastIndex,
}: QuestionMatcherProps) => {
  const {
    DATE,
    CHECKBOX,
    TAG_TEXT,
    RADIO,
    SHORT_TEXT,
    LONG_TEXT,
    NUMERIC_INPUT,
    DROPDOWN,
    DROPDOWN_INPUT,
  } = QuestionType;

  return (
    <>
      {type === NUMERIC_INPUT && (
        <NumericInputCard
          provided={provided}
          key={id}
          id={id}
          questionGroupId={questionGroupId}
          data={data}
          setQuestions={setQuestions}
          lastIndex={lastIndex}
        />
      )}
      {type === DATE && (
        <DatePickerCard
          provided={provided}
          key={id}
          id={id}
          questionGroupId={questionGroupId}
          data={data}
          setQuestions={setQuestions}
          lastIndex={lastIndex}
        />
      )}
      {type === CHECKBOX && (
        <CheckboxCard
          provided={provided}
          key={id}
          id={id}
          questionGroupId={questionGroupId}
          data={data}
          setQuestions={setQuestions}
          lastIndex={lastIndex}
        />
      )}
      {type === TAG_TEXT && (
        <TagsCard
          provided={provided}
          key={id}
          id={id}
          questionGroupId={questionGroupId}
          data={data}
          setQuestions={setQuestions}
          lastIndex={lastIndex}
        />
      )}
      {type === RADIO && (
        <RadioButtonCard
          provided={provided}
          key={id}
          id={id}
          questionGroupId={questionGroupId}
          data={data}
          setQuestions={setQuestions}
          lastIndex={lastIndex}
        />
      )}
      {type === SHORT_TEXT && (
        <ShortTextCard
          provided={provided}
          key={id}
          id={id}
          questionGroupId={questionGroupId}
          data={data}
          setQuestions={setQuestions}
          lastIndex={lastIndex}
        />
      )}
      {type === LONG_TEXT && (
        <LongTextCard
          provided={provided}
          key={id}
          id={id}
          questionGroupId={questionGroupId}
          data={data}
          setQuestions={setQuestions}
          lastIndex={lastIndex}
        />
      )}
      {type === DROPDOWN && (
        <DropdownCard
          provided={provided}
          key={id}
          id={id}
          questionGroupId={questionGroupId}
          data={data}
          setQuestions={setQuestions}
          lastIndex={lastIndex}
        />
      )}
      {type === DROPDOWN_INPUT && (
        <DropdownInputCard
          provided={provided}
          key={id}
          id={id}
          questionGroupId={questionGroupId}
          data={data}
          setQuestions={setQuestions}
          lastIndex={lastIndex}
        />
      )}
    </>
  );
};

export default QuestionMatcherComponent;
