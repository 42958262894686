import React, { ChangeEvent } from "react";
import { IconButton } from "@mui/material";

import {
  LabelInput,
  RowContainer,
  IconContainer,
  LeftContainer,
} from "./styles";
import { TrashIcon } from "../../assets";
import { OPTION_PLACEHOLDER } from "../../utils/constants";

interface DropdownProps {
  labelValue?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>, idToChange: number) => void;
  id?: number;
  onClickDeleteIcon?: (idToDelete: number) => void;
  focus?: boolean;
  onClick?: () => void;
  className?: string;
  disable?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  labelValue,
  onChange,
  id,
  onClickDeleteIcon,
  focus = false,
  onClick,
  className = "",
  disable = false,
}) => {
  return (
    <RowContainer>
      <LeftContainer>
        <LabelInput
          type="text"
          value={labelValue}
          onChange={(e) => onChange(e, id)}
          placeholder={OPTION_PLACEHOLDER}
        />
      </LeftContainer>
      <IconContainer>
        <IconButton onClick={() => onClickDeleteIcon(id)}>
          <TrashIcon />
        </IconButton>
      </IconContainer>
    </RowContainer>
  );
};
export default Dropdown;
