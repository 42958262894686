import styled from "styled-components";

export const ColorPaletteContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    [theme.breakpoints.down(760)]: {
      alignItems: "center",
    },
  };
});

export const TopContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "32px",
}));

export const ResetButton = styled("button")(({ theme }) => {
  const {
    customSizes: {
      body: {
        description: { regular },
      },
    },
    customPalette: { grey },
    customFonts: { poppins },
  } = theme;
  return {
    fontSize: regular.fontSize,
    fontWeight: regular.fontWeight,
    lineHeight: regular.lineHeight,
    fontFamily: poppins,
    border: "none",
    cursor: "pointer",
    background: "none",
    color: grey,
    opacity: "50%",
    ":hover": {
      textDecoration: "underline",
    },
  };
});

export const ColorContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "424px",
  height: "fit-content",
  paddingTop: "56px",
  zIndex: 0,
}));

export const Subtitle = styled("h2")(({ theme }) => {
  const {
    customSizes: {
      body: {
        title2: { semibold },
      },
      textField: { text },
    },
    customPalette: { primary },
    customFonts: { poppinsSemiBold },
  } = theme;

  return {
    fontSize: semibold.fontSize,
    fontWeight: semibold.fontWeight,
    lineHeight: semibold.lineHeight,
    color: primary,
    fontFamily: poppinsSemiBold,
    margin: "0px",

    [theme.breakpoints.down(760)]: {
      fontSize: text.fontSize,
    },
  };
});

export const FileUploadContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "32px",
    gap: "8px",
  };
});

export const FileUploadButton = styled("div")(({ theme }) => {
  const {
    colors: { white },
    customPalette: { secondary },
  } = theme;
  return {
    width: "337px",
    height: "48px",
    borderRadius: "8px",
    backgroundColor: white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    border: `1px solid ${secondary}`,
  };
});

export const FileUploadLabel = styled("label")(({ theme }) => {
  const {
    customSizes: {
      body: {
        description: {
          medium: { fontSize, fontWeight, lineHeight },
        },
      },
    },
    customPalette: { secondary },
    customFonts: { poppins },
  } = theme;
  return {
    fontSize,
    fontWeight,
    lineHeight,
    fontFamily: poppins,
    color: secondary,
    cursor: "pointer",
  };
});

export const FileUploadInput = styled("input")(({ theme }) => {
  return {
    display: "none",
  };
});

export const InputAdornment = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "45px",
    "&.pointer": {
      cursor: "pointer",
    },
  };
});

export const Text = styled("h1")(({ theme }) => {
  const {
    customSizes: {
      body: {
        description: {
          semibold: { fontSize, fontWeight, lineHeight },
        },
      },
    },
    customPalette: { primary },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    color: primary,
    fontFamily: poppins,
  };
});

interface Props {
  fontFamily: string;
}

export const FontName = styled("label")<Props>(({ theme, fontFamily }) => {
  const {
    customSizes: {
      body: {
        smallDescription: {
          semibold: { fontSize, lineHeight },
        },
      },
    },
    customPalette: { darkMediumGrey },
  } = theme;

  return {
    fontSize,

    lineHeight,
    color: darkMediumGrey,
    fontFamily,
  };
});

interface ColorProps {
  isSuccessful: boolean;
}

export const UploadFileMessage = styled("h1")<ColorProps>(
  ({ theme, isSuccessful }) => {
    const {
      customSizes: {
        uploadStatus: { fontSize, fontWeight, lineHeight },
      },
      customPalette: { success, error },
      customFonts: { poppins },
    } = theme;

    return {
      fontSize,
      fontWeight,
      lineHeight,
      color: isSuccessful ? success : error,
      fontFamily: poppins,
      margin: "0px",
    };
  }
);

export const NameContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  gap: "4px",
});

export const UploadedFileContainer = styled("div")(({ theme }) => {
  const {
    customPalette: { darkTransparent, mediumLightGrey },
  } = theme;

  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: darkTransparent,
    borderRadius: "7px",
    width: "337px",
    height: "60px",
    position: "absolute",
    justifyContent: "space-between",
    border: `1px solid ${mediumLightGrey}`,
    paddingRight: "16px",
  };
});

export const ContentContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px",
    padding: "16px",
  };
});

export const TextContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
  };
});

export const MessageContainer = styled("div")(({ theme }) => {
  const {
    customPalette: { darkGrey },
  } = theme;

  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px",
    backgroundColor: darkGrey,
    borderRadius: "8px",
    height: "49px",
    width: "294px",
    position: "fixed",
    top: "80%",
    left: "673px",
    justifyContent: "space-between",
    paddingRight: "16px",
    paddingLeft: "16px",
    paddingTop: "8px",
    paddingBottom: "8px",
  };
});

export const Container = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    paddingBottom: "56px",
  };
});
