import styled from "styled-components";
import { InfoErrorIcon } from "../../assets";

export const ModalContainer = styled("div")(({ theme }) => {
  const {
    customPalette: { white },
  } = theme;

  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: "8px",
    top: "50%",
    left: "50%",
    width: "640px",
    height: "304px",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    backgroundColor: white,
    padding: "40px",
    gap: "40px",
  };
});

export const TopContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "24px",
  padding: "0px",
});

export const CrossButton = styled("button")({
  border: "none",
  background: "none",
  height: "fit-content",
  cursor: "pointer",
});

export const ButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  gap: "16px",
});

export const TextContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
});

export const IconsContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
});

export const Icon = styled(InfoErrorIcon)({
  marginLeft: "252px",
});

export const Label = styled("label")(({ theme }) => {
  const {
    customSizes: {
      body: {
        smallDescription: {
          regular: { fontSize, fontWeight, lineHeight },
        },
      },
    },
    customFonts: { poppins },
  } = theme;

  return {
    textAlign: "center",
    fontSize,
    fontWeight,
    lineHeight,
    fontFamily: poppins,
  };
});

export const TitleLabel = styled("label")(({ theme }) => {
  const {
    customSizes: {
      body: {
        title2: {
          semibold: { fontSize, fontWeight, lineHeight },
        },
      },
    },
    customFonts: { poppins },
  } = theme;

  return {
    textAlign: "center",
    fontSize,
    fontWeight,
    lineHeight,
    fontFamily: poppins,
  };
});
