import styled from "styled-components";

interface CheckboxProps {
  focused?: boolean;
}

interface CheckboxLabelProps {
  labelStyles?: React.CSSProperties;
}

export const CheckboxContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "fit-content",
  gap: "9px",
  height: "24px",
});

export const CheckboxStyled = styled("div")<CheckboxProps>(
  ({ theme, focused }) => {
    const {
      customPalette: { mediumLightGrey, secondary, white },
    } = theme;

    return {
      width: "24px",
      height: "24px",
      padding: "4.5px 0 0 2px",
      backgroundColor: white,
      border: `1px solid ${focused ? secondary : mediumLightGrey}`,
      borderRadius: "4px",
      boxShadow: focused ? `0px 0px 2px ${secondary}` : "",
      ":hover": {
        border: `1px solid ${secondary}`,
      },
      cursor: "pointer",
    };
  }
);

export const Label = styled("h1")<CheckboxLabelProps>(
  ({ theme, labelStyles = {} }) => {
    const {
      customPalette: { darkGrey },
    } = theme;

    return {
      fontSize: "13px",
      fontWeight: 400,
      lineHeight: "19.5px",
      color: darkGrey,
      ...labelStyles,
    };
  }
);
