import { AxiosResponse } from "axios";

import api from "../../../api";
import store from "../../../redux/store";
import { setApiError } from "../../../redux/slices/companySlice";

const { dispatch } = store;

export const deleteFilter = async (filterId: number): Promise<void> => {
  await api
    .delete(`/filter/${filterId}`)
    .then((res: AxiosResponse) => {
      return res?.data;
    })
    .catch((error) => {
      console.log("[deleteFilter error]: ", error);
      dispatch(setApiError({ error: true }));
    });
};
