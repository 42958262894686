import api from "../../../api";
import store from "../../../redux/store";
import { setApiError } from "redux/slices/userResponseSlice";

const { dispatch } = store;

export const generateToken = async (userResponseId: number) => {
  const url = "/auth/generate-token";
  const body = { userResponseId };

  return await api
    .post(url, body)
    .then((response) => {
      return response.data.accessToken;
    })
    .catch((error) => {
      console.log("[generateToken error]: ", error);
      dispatch(setApiError({ error: true }));
    });
};
