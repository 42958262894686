import { TARGET_USERS, ADD_TAG } from "../../utils/constants";
import { DeleteIcon } from "../../assets";
import {
  Label,
  Question,
  StyledTag,
  TagsContainer,
  TagQuestionContainer,
  TagLabel,
} from "./styles";
import { tagsOptions } from "../../utils/utils";
import { useAppSelector } from "../../redux/store";

interface Props {
  tagColor: string;
  subtitleColor: string;
  textColor: string;
}
const PreviewTag = ({ tagColor, subtitleColor, textColor }: Props) => {
  const { semiBoldFontFile, regularFontFile } = useAppSelector(
    (state) => state.companySlice.branding
  );
  return (
    <TagQuestionContainer>
      <Question fontFamily={semiBoldFontFile} color={subtitleColor}>
        {TARGET_USERS}
      </Question>
      <TagsContainer>
        {tagsOptions?.map(({ optionText }) => (
          <StyledTag color={tagColor}>
            <Label fontFamily={regularFontFile} color={textColor}>
              {optionText}
            </Label>
            <DeleteIcon style={{ cursor: "" }} />
          </StyledTag>
        ))}
      </TagsContainer>
      <TagLabel fontFamily={regularFontFile}>{ADD_TAG}</TagLabel>
    </TagQuestionContainer>
  );
};

export default PreviewTag;
