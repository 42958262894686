import {
  BACK,
  NEXT,
  PREVIEW,
  PARTNERS,
  ABOUT_SERVICE,
  COLLABORATORS,
  ENTER_YOUR_TEXT_HERE,
} from "../../utils/constants";
import { Button, TextInput } from "..";
import { useAppSelector } from "../../redux/store";
import { InputContainer } from "../longTextCard/styles";
import { ArrowIcon } from "../../assets";
import {
  Container,
  Line,
  PreviewContainer,
  Question,
  QuestionsContainer,
  Subtitle,
  TextLink,
  TopContainer,
  Title,
  PreviewTitle,
  BottomContainer,
  IconContainer,
} from "./styles";
import PreviewRadioButton from "./radioButton";
import PreviewTag from "./tag";
import { getTextColor } from "../../utils/utils";

interface PreviewProps {
  primaryColor: string;
  tagColor: string;
  linkColor: string;
  titleColor: string;
  subtitleColor: string;
}
const Preview = ({
  primaryColor,
  tagColor,
  linkColor,
  titleColor,
  subtitleColor,
}: PreviewProps) => {
  const { semiBoldFontFile, regularFontFile } = useAppSelector(
    (state) => state.companySlice.branding
  );

  return (
    <PreviewContainer>
      <TopContainer>
        <PreviewTitle>{PREVIEW}</PreviewTitle>
      </TopContainer>
      <Container>
        <QuestionsContainer>
          <Title fontFamily={semiBoldFontFile} color={titleColor}>
            {PARTNERS}
          </Title>
          <Subtitle fontFamily={regularFontFile} color={subtitleColor}>
            {ABOUT_SERVICE}
          </Subtitle>
          <InputContainer>
            <Question fontFamily={semiBoldFontFile} color={subtitleColor}>
              {COLLABORATORS}
            </Question>
            <TextInput
              fontFamily={regularFontFile}
              placeholder={ENTER_YOUR_TEXT_HERE}
              disabled
              preview
              showErrorLabel={false}
            />
          </InputContainer>
          <PreviewTag
            tagColor={tagColor}
            subtitleColor={subtitleColor}
            textColor={getTextColor(tagColor)}
          />
          <PreviewRadioButton
            primaryColor={primaryColor}
            subtitleColor={subtitleColor}
          />
          <Line />
          <BottomContainer>
            <IconContainer>
              <ArrowIcon fill={linkColor} />
              <TextLink fontFamily={regularFontFile} color={linkColor}>
                {BACK}
              </TextLink>
            </IconContainer>
            <Button
              label={NEXT}
              variantType="primary"
              disabled
              width={144}
              height={32}
              borderRadius={8}
              color={primaryColor}
              textColor={getTextColor(primaryColor)}
              fontFamily={regularFontFile}
            />
          </BottomContainer>
        </QuestionsContainer>
      </Container>
    </PreviewContainer>
  );
};

export default Preview;
