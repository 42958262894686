import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { deleteSurvey } from "../pages/Survey/services/deleteSurvey";
import { getSurveys } from "../pages/Survey/services/getSurveys";
import { clearApiError } from "../redux/slices/companySlice";
import { clearQuestionTypesError } from "../redux/slices/questionTypesSlice";
import { getQuestionTypes } from "../pages/Survey/services/getQuestionTypes";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { QuestionGroupState } from "../types";
import { editSurvey } from "pages/Survey/services/editSurvey";
import { cloneSurvey } from "../pages/Survey/services/cloneSurvey";

interface SurveyToDelete {
  name: string;
  id: number;
}

const useSurveyLogic = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { surveys, currentDefaultId } = useAppSelector(
    (state) => state.surveySlice
  );
  const { apiError: questionTypesApiError } = useAppSelector(
    (state) => state.questionTypesSlice
  );
  const { apiError } = useAppSelector((state) => state.companySlice);

  const [showModal, setShowModal] = useState(false);
  const [surveyToDelete, setSurveyToDelete] = useState<SurveyToDelete>();

  const handleDefaultClick = async (
    event: React.MouseEvent<HTMLElement>,
    surveyId: number
  ) => {
    event.stopPropagation();
    await editSurvey(surveyId, { isDefault: true }, currentDefaultId);
  };

  const handleDeleteClick = (
    event: React.MouseEvent<HTMLElement>,
    survey: SurveyToDelete
  ) => {
    event.stopPropagation();
    setSurveyToDelete(survey);
    setShowModal(true);
  };

  const handleCloneSurvey = async (
    event: React.MouseEvent<HTMLElement>,
    id: number
  ) => {
    event.stopPropagation();
    await cloneSurvey(id);
  };

  const handleDeleteSurvey = async () => {
    await deleteSurvey(surveyToDelete?.id);
    setShowModal(false);
  };

  const handleCloseErrorModal = () => {
    if (apiError) dispatch(clearApiError());
    if (questionTypesApiError) dispatch(clearQuestionTypesError());
  };

  const handleTryAgainErrorModal = () => {
    if (apiError) getSurveys();
    if (questionTypesApiError) getQuestionTypes();
  };

  const surveyQuestionsAmount = (questionGroups: QuestionGroupState[]) =>
    questionGroups.reduce(
      (accumulator, { questions }) => accumulator + questions?.length,
      0
    );

  const handlePathRuleClick = (
    event: React.MouseEvent<HTMLElement>,
    surveyId: number
  ) => {
    event.stopPropagation();
    navigate(`/survey/${surveyId}/path-rules`);
  };

  useEffect(() => {
    clearApiError();
    clearQuestionTypesError();
    getQuestionTypes();
    getSurveys();
  }, []);

  return {
    handleDefaultClick,
    handleDeleteClick,
    handleDeleteSurvey,
    showModal,
    setShowModal,
    surveyToDelete,
    surveys,
    handleCloseErrorModal,
    handleTryAgainErrorModal,
    apiError: apiError || questionTypesApiError,
    surveyQuestionsAmount,
    handlePathRuleClick,
    handleCloneSurvey,
  };
};

export default useSurveyLogic;
