import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoaderContainer = styled.div`
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 3px solid ${({ theme }) => theme.customPalette.mediumGrey};
  border-top: 3px solid ${({ theme }) => theme.customPalette.white};
  border-radius: 50%;
  animation: ${spin} 2s linear infinite;
`;
