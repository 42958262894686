import { TextField } from "@mui/material";
import styled from "styled-components";

import theme from "../../definitions/theme";

const getTextColor = (
  disabled: boolean | undefined,
  error: boolean | undefined
) => {
  return disabled
    ? theme.customPalette.textField.disabled.text
    : error
    ? theme.customPalette.textField.error.text
    : theme.customPalette.textAreaInput.activeText;
};

const getBorderColor = (
  disabled: boolean | undefined,
  error: boolean | undefined
) => {
  return disabled
    ? theme.customPalette.textField.disabled.border
    : error
    ? theme.customPalette.textField.error.border
    : theme.customPalette.textField.default.border;
};

const getBorderHoverColor = (
  disabled: boolean | undefined,
  error: boolean | undefined
) => {
  return disabled
    ? theme.customPalette.textField.disabled.border
    : error
    ? theme.customPalette.textField.error.border
    : theme.customPalette.textField.hover.border;
};

interface InputProps {
  id?: string;
  placeholder: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  disabled?: boolean;
  error?: boolean;
  width?: number | string;
  label?: string;
  type?: string;
  isResponseField?: boolean;
}

export const StyledTextField = styled(TextField)<InputProps>(
  ({ theme, disabled, error, isResponseField }) => {
    return {
      cursor: disabled ? "initial" : "pointer",
      height: "48px",
      width: isResponseField ? "833px" : "100%",

      ".MuiInputBase-root": {
        padding: "0",
      },

      ".MuiOutlinedInput-root": {
        height: "48px !important",
        "&.Mui-focused fieldset": {
          borderColor: `2px solid ${getBorderHoverColor(disabled, error)} `,
        },
      },

      ".MuiInputBase-input": {
        fontSize: theme.customSizes.textField.text.fontSize,
        fontWeight: theme.customSizes.textField.text.fontWeight,
        lineHeight: theme.customSizes.textField.text.lineHeight,
        color: getTextColor(disabled, error),
      },

      "&.MuiOutlinedInput-notchedOutline": {
        borderRadius: "7px",
        border: `2px solid ${getBorderColor(disabled, error)} !important`,
      },

      ":hover": {
        ".MuiOutlinedInput-notchedOutline": {
          border: `2px solid ${getBorderHoverColor(
            disabled,
            error
          )} !important`,
        },
        color: `${theme.customPalette.textAreaInput.activeText}`,
      },

      ".Mui-focused": {
        ".MuiOutlinedInput-notchedOutline": {
          border: `2px solid
          ${
            disabled
              ? theme.customPalette.textField.disabled.border
              : theme.customPalette.textField.active.border
          } !important`,
        },
        ".MuiInputBase-input": {
          color: `${theme.customPalette.textField.active.text}`,
        },
        color: `${theme.customPalette.textField.active.text}`,
        borderColor: `${
          disabled
            ? theme.customPalette.textField.disabled.border
            : theme.customPalette.textField.active.border
        }`,
      },

      ".Mui-error": {
        ".MuiOutlinedInput-notchedOutline": {
          border: `2px solid ${theme.customPalette.textField.error.border} !important`,
        },
        ".MuiInputBase-input": {
          color: `${theme.customPalette.textField.error.text}`,
        },
      },

      ".Mui-disabled": {
        ".MuiOutlinedInput-notchedOutline": {
          border: `2px solid ${theme.customPalette.textField.disabled.border} !important`,
        },
        ".MuiInputBase-input": {
          color: `${theme.customPalette.textField.disabled.text}`,
        },
      },
    };
  }
);

interface ContainerProps {
  isResponseField?: boolean;
}

export const Container = styled("div")<ContainerProps>(
  ({ theme, isResponseField }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    height: "fit-content",
    width: "100%",
    paddingLeft: isResponseField ? "32px" : "0px",
  })
);

export const SuggestionContainer = styled("div")`
  margin-top: 21px;
`;

export const TagsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 9px;
  gap: 14px;
  height: fit-content;
  width: "100%";
`;
