import { useState, useCallback } from "react";

import { getAnalytics } from "../pages/Analytics/services/getAnalytics";
import { getKpi } from "../pages/Analytics/services/getKpi";
import { AnalyticsState, CategoryState, SurveyState, KpiState } from "../types";

const useAnalyticsData = (
  selectedSurvey: SurveyState,
  xAxis: string,
  startDate: Date | null,
  endDate: Date | null,
  selectedTags: {
    selectedFilter: string | null;
    selectedIds: CategoryState[];
  }[]
) => {
  const [analyticsData, setAnalyticsData] = useState<AnalyticsState[]>([]);
  const [kpiData, setKpiData] = useState<KpiState | null>(null);

  const fetchAnalyticsData = useCallback(async () => {
    const data = await getAnalytics(
      selectedSurvey.id,
      xAxis,
      startDate,
      endDate,
      selectedTags
    );
    setAnalyticsData(data);
  }, [selectedSurvey, xAxis, startDate, endDate, selectedTags]);

  const fetchKpiData = useCallback(async () => {
    const data = await getKpi(
      selectedSurvey.id,
      startDate,
      endDate,
      selectedTags
    );
    setKpiData(data);
  }, [selectedSurvey, startDate, endDate, selectedTags]);

  return { analyticsData, fetchAnalyticsData, kpiData, fetchKpiData };
};

export default useAnalyticsData;
