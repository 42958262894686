import { TickIcon } from "assets";
import styled from "styled-components";

export const ModalContainer = styled("div")(({ theme }) => {
  const {
    customPalette: { darkGrey },
  } = theme;

  return {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    backgroundColor: darkGrey,
    borderRadius: "8px",
    height: "50px",
    width: "300px",
    position: "fixed",
    bottom: 24,
    left: 36,
    justifyContent: "center",
    zIndex: 1301,
  };
});

export const Text = styled("label")(({ theme }) => {
  const {
    customSizes: {
      body: {
        smallDescription: {
          semibold: { fontSize, fontWeight, lineHeight },
        },
      },
    },
    customPalette: { white },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    color: white,
    fontFamily: poppins,
  };
});

export const StyledTickIcon = styled(TickIcon)(({ theme }) => {
  const {
    customPalette: { white },
  } = theme;

  return {
    path: {
      fill: white,
    },
  };
});
