import { AxiosResponse } from "axios";

import api from "../../../api";
import { QuestionType } from "../../../utils/utils";
import { QuestionOptionState } from "../../../types";
import store from "../../../redux/store";
import { clearApiError, setApiError } from "../../../redux/slices/surveySlice";

interface BodyData {
  type: QuestionType;
  questionGroupId: number;
  questionText: string;
  index: number;
  required?: boolean;
  label?: string;
  questionSubtitleText?: string;
  currency?: string;
  questionOptions?: QuestionOptionState[];
}

const { dispatch } = store;

export const createQuestion = async (body: BodyData) =>
  await api
    .post("/questions", body)
    .then((res: AxiosResponse) => {
      dispatch(clearApiError());
      return res?.data;
    })
    .catch((error) => {
      console.log("[createQuestion error]: ", error);
      dispatch(setApiError({ error: true }));
    });
