import { ESTIMATED_BUDGET } from "../../utils/constants";
import { LeftContainer, RadioContainer } from "../radioButton/styles";
import {
  Label,
  Question,
  RadioExternal,
  RadioInternal,
  RadioQuestionContainer,
  RadioButtonsContainer,
  RadioButton,
} from "./styles";
import { radioButtonOptions } from "../../utils/utils";
import { useAppSelector } from "../../redux/store";

interface Props {
  primaryColor: string;
  subtitleColor: string;
}
const PreviewRadioButton = ({ primaryColor, subtitleColor }: Props) => {
  const { semiBoldFontFile, regularFontFile } = useAppSelector(
    (state) => state.companySlice.branding
  );

  return (
    <RadioQuestionContainer>
      <Question fontFamily={semiBoldFontFile} color={subtitleColor}>
        {ESTIMATED_BUDGET}
      </Question>
      <RadioButtonsContainer>
        {radioButtonOptions?.map(({ optionText, checked }) => (
          <RadioButton>
            <RadioContainer>
              <LeftContainer>
                <RadioExternal active={checked} color={primaryColor}>
                  <RadioInternal active={checked} color={primaryColor} />
                </RadioExternal>
                <Label fontFamily={regularFontFile}>{optionText}</Label>
              </LeftContainer>
            </RadioContainer>
          </RadioButton>
        ))}
      </RadioButtonsContainer>
    </RadioQuestionContainer>
  );
};

export default PreviewRadioButton;
