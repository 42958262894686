import styled from "styled-components";

export const Container = styled("div")({
  marginLeft: "32px",
});

export const LongTextContainer = styled("div")({
  marginLeft: "32px",
  width: "488px",
});

export const NumericInputContainer = styled("div")({
  marginLeft: "22px",
  width: "328px",
});
