import styled from "styled-components";

export const Text = styled("h1")<{ color?: string }>(({ theme, color }) => {
  const {
    customSizes: {
      tagLabel: { fontSize, fontWeight },
    },
    customPalette: { darkMediumGrey },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight: "4px",
    padding: "0px",
    textTransform: "capitalize",
    color: color || darkMediumGrey,
    fontFamily: poppins,
  };
});

export const Tag = styled("div")<{ backgroundColor: string }>(
  ({ backgroundColor }) => {
    return {
      backgroundColor,
      borderRadius: "8px",
      padding: "4px 8px 4px 8px",
    };
  }
);
