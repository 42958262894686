import { AxiosResponse } from "axios";

import api from "../../../api";
import { setApiError, clearApiError } from "../../../redux/slices/companySlice";
import store from "../../../redux/store";

interface BodyData {
  content: string;
}

const { dispatch } = store;

export const postNda = async (body: BodyData) =>
  await api
    .post(`/nda`, body)
    .then((res: AxiosResponse) => {
      dispatch(clearApiError());
      return res?.data;
    })
    .catch((error) => {
      console.log("[postNda error]: ", error);
      dispatch(setApiError({ error: true }));
    });
