import Modal from "@mui/material/Modal";

import Button from "../button";
import { DeleteModalIcon, InfoIcon } from "../../assets/index";
import { ModalContainer, TopContainer, Label, ButtonContainer } from "./styles";
import {
  CANCEL,
  DELETE,
  DELETE_QUESTION_GROUP_CONFIRMATION_MESSAGE,
  DELETION_WARNING_MESSAGE,
  OK,
} from "../../utils/constants";

interface Params {
  isOpen: boolean;
  handleClose: () => void;
  handleDelete?: () => void;
  message?: string;
  description?: string;
  information?: boolean;
}

const BasicModal = ({
  isOpen,
  handleClose,
  handleDelete,
  message = "",
  description = "",
  information = false,
}: Params) => (
  <Modal
    open={isOpen}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <ModalContainer>
      <TopContainer>
        {information ? <InfoIcon /> : <DeleteModalIcon />}
        {message && (
          <div>
            <Label>{message}</Label>
            <Label>{description}</Label>
          </div>
        )}
      </TopContainer>
      <ButtonContainer>
        <Button
          label={information ? OK : CANCEL}
          variantType={information ? "primary" : "secondary"}
          width={187}
          height={48}
          onClick={handleClose}
        />
        {handleDelete && (
          <Button
            label={DELETE}
            variantType="primary"
            width={187}
            height={48}
            onClick={handleDelete}
          />
        )}
      </ButtonContainer>
    </ModalContainer>
  </Modal>
);

export default BasicModal;
