import { Divider, Typography } from "@mui/material";
import styled from "styled-components";

interface Props {
  color?: string;
  fontFamily: string;
}
export const PreviewTitle = styled("h2")(({ theme }) => {
  const {
    customSizes: {
      body: {
        title2: { semibold },
      },
    },
    customPalette: { primary },
    customFonts: { poppinsSemiBold },
  } = theme;

  return {
    fontSize: semibold.fontSize,
    fontWeight: semibold.fontWeight,
    lineHeight: semibold.lineHeight,
    color: primary,
    fontFamily: poppinsSemiBold,
    margin: "0px",
  };
});
export const Container = styled("div")(({ theme }) => {
  return {
    width: "584px",
    border: `1px solid ${theme.customPalette.mediumLightGrey}`,
    boxShadow: theme.customSizes.preview.boxShadow,
    borderRadius: "16px",
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop: "32px",
    paddingBottom: "32px",
  };
});

export const TagQuestionContainer = styled("div")({
  height: "114px",
  display: "flex",
  flexDirection: "column",
  justifyItems: "space-between",
  marginTop: "32px",
  zIndex: -1,
});

export const RadioButtonsContainer = styled("div")({
  height: "256px",
});

export const RadioButton = styled("div")({
  height: "24px",
  marginBottom: "34px",
});

export const IconContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
});

export const BottomContainer = styled("div")({
  display: "flex",
  width: "100%",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: "16px",
});

export const RadioQuestionContainer = styled("div")({
  height: "295px",
  width: "488px",
  marginTop: "32px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

export const TagLabel = styled("label")<Props>(({ theme, fontFamily }) => {
  const {
    customSizes: {
      preview: { tag },
    },

    customFonts: { poppins },
  } = theme;
  return {
    fontFamily: fontFamily || poppins,
    fontSize: tag.fontSize,
    fontWeight: tag.fontWeight,
    lineHeight: tag.lineHeight,
    color: theme.darkMediumGrey,
    opacity: "60%",
  };
});

export const TagsContainer = styled("div")(({ theme }) => {
  return {
    border: `2px solid ${theme.customPalette.mediumLightGrey}`,
    display: "flex",
    flexDirection: "row",
    width: "488px",
    height: "48px",
    borderRadius: "8px",
    alignItems: "center",
    paddingLeft: "8px",
    marginBottom: "6px",
  };
});

export const QuestionsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

export const PreviewContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "32px",
});

export const TopContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

interface RadioProps {
  active: boolean;
  color: string;
}

export const RadioInternal = styled("div")<RadioProps>(
  ({ theme, active, color }) => {
    const {
      customPalette: {
        radio: { normal },
      },
    } = theme;
    return {
      width: "12px",
      height: "12px",
      borderRadius: "6px",
      border: active ? `1px solid ${color}` : `1px solid ${normal.background}`,
      backgroundColor: active ? `${color}` : `${normal.background}`,
    };
  }
);

export const ResetButton = styled("button")(({ theme }) => {
  const {
    customSizes: {
      body: {
        description: { regular },
      },
    },
    customPalette: { grey },
    customFonts: { poppins },
  } = theme;
  return {
    fontSize: regular.fontSize,
    fontWeight: regular.fontWeight,
    lineHeight: regular.lineHeight,
    fontFamily: poppins,
    border: "none",
    cursor: "pointer",
    background: "none",
    color: grey,
    opacity: "50%",
    ":hover": {
      textDecoration: "underline",
    },
  };
});

interface LabelProps {
  fontFamily: string;
  color?: string;
}

export const Label = styled("label")<LabelProps>(
  ({ theme, fontFamily, color }) => {
    const {
      customPalette: { darkGray },
      customFonts: { poppins },
      customSizes: {
        preview: { label },
      },
    } = theme;

    return {
      alignSelf: "center",
      marginLeft: "8px",
      fontSize: label.fontSize,
      fontWeight: label.fontWeight,
      lineHeight: label.lineHeight,
      color: color || darkGray,
      fontFamily: fontFamily || poppins,
    };
  }
);

interface TagProps {
  color: string;
}

export const StyledTag = styled("div")<TagProps>(({ color }) => {
  return {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width: "fit-content",
    height: "32px",
    padding: "0px 12px 0px 12px",
    gap: "5px",
    borderRadius: "7px",
    backgroundColor: color,
    marginRight: "8px",
  };
});

export const RadioExternal = styled("div")<RadioProps>(
  ({ theme, active, color }) => {
    const {
      customPalette: {
        radio: { normal },
      },
    } = theme;
    return {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "24px",
      height: "24px",
      borderRadius: "12px",
      border: active ? `1px solid ${color}` : `1px solid ${normal.border}`,
      backgroundColor: `${normal.background}`,
    };
  }
);

export const ColorContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "424px",
  height: "512px",
});

export const Subtitle = styled("h2")<Props>(({ theme, color, fontFamily }) => {
  const {
    customSizes: {
      preview: { subtitle },
    },

    customFonts: { poppins },
  } = theme;

  return {
    fontSize: subtitle.fontSize,
    fontWeight: subtitle.fontWeight,
    lineHeight: subtitle.lineHeight,
    color,
    fontFamily: fontFamily || poppins,
    margin: "0px",
    marginTop: "8px",
  };
});

export const Question = styled("label")<Props>(
  ({ theme, color, fontFamily }) => {
    const {
      customFonts: { poppinsSemiBold },
      customSizes: {
        preview: { question },
      },
    } = theme;

    return {
      fontFamily: fontFamily || poppinsSemiBold,
      marginBottom: "16px",
      fontSize: question.fontSize,
      fontWeight: question.fontWeight,
      lineHeight: question.lineHeight,
      color,
    };
  }
);

export const TextLink = styled(Typography)<Props>(
  ({ theme, color, fontFamily }) => {
    const {
      customFonts: { poppins },
      customSizes: {
        preview: { link },
      },
    } = theme;

    return {
      fontFamily: fontFamily || poppins,
      fontSize: link.fontSize,
      fontWeight: link.fontWeight,
      lineHeight: link.lineHeight,
      color,
    };
  }
);

export const Title = styled("label")<Props>(({ theme, color, fontFamily }) => {
  const {
    customFonts: { poppinsSemiBold },
    customSizes: {
      body: {
        description: { semibold },
      },
    },
  } = theme;

  return {
    fontFamily: fontFamily || poppinsSemiBold,
    fontSize: semibold.fontSize,
    fontWeight: semibold.fontWeight,
    lineHeight: semibold.lineHeight,
    color,
    margin: 0,
  };
});

export const Line = styled(Divider)(({ theme }) => {
  return {
    "&.MuiDivider-root": {
      width: "483px",
      marginTop: "56px",
      borderColor: theme.customPalette.mediumLightGrey,
    },
  };
});
