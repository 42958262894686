import { SvgIcon, IconButton } from "@mui/material";
import React, { useState, ChangeEvent, useEffect } from "react";

import {
  Checkbox,
  LabelInput,
  RowContainer,
  IconContainer,
  LeftContainer,
  Label,
  Tag,
} from "./styles";
import { CheckedIcon, TrashIcon } from "../../assets";
import { OPTION_PLACEHOLDER } from "../../utils/constants";

interface CheckboxProps {
  labelValue?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>, idToChange: number) => void;
  id?: number;
  onClickDeleteIcon?: (idToDelete: number) => void;
  focus?: boolean;
  onClick?: (focused: boolean) => void;
  className?: string;
  disable?: boolean;
  editable?: boolean;
  tag?: boolean;
}

const CheckoutButton: React.FC<CheckboxProps> = ({
  labelValue,
  onChange,
  id,
  onClickDeleteIcon = () => {},
  focus = false,
  onClick,
  className = "",
  disable = false,
  editable = false,
  tag = false,
}) => {
  const [focused, setFocused] = useState<boolean>(focus);

  useEffect(() => setFocused(focus), [focus]);

  const handleClick = () => {
    onClick?.(!focused);
    setFocused((prev) => !prev);
  };

  return (
    <RowContainer>
      <LeftContainer>
        <Checkbox
          className={className}
          onClick={disable ? () => {} : handleClick}
          focused={focused}
        >
          {focused && (
            <SvgIcon>
              <CheckedIcon />
            </SvgIcon>
          )}
        </Checkbox>
        {editable ? (
          <LabelInput
            type="text"
            value={labelValue}
            onChange={(e) => onChange(e, id)}
            placeholder={OPTION_PLACEHOLDER}
          />
        ) : tag ? (
          <Tag>
            <Label>{labelValue}</Label>
          </Tag>
        ) : (
          <Label marginLeft>{labelValue}</Label>
        )}
      </LeftContainer>
      {editable && (
        <IconContainer>
          <IconButton onClick={() => onClickDeleteIcon(id)}>
            <TrashIcon />
          </IconButton>
        </IconContainer>
      )}
    </RowContainer>
  );
};
export default CheckoutButton;
