import React from "react";
import { DraggableProvided } from "react-beautiful-dnd";

import QuestionCard from "../questionCard";
import { QuestionState } from "../../types";
import useCardLogic, { DataType } from "../../hooks/useCardLogic";
import Dropdown from "../dropdown";
import {
  Container,
  SelectInput,
  SelectInputContainer,
  IconContainer,
} from "./styles";
import { Vector } from "assets";

interface DropdownCardProps {
  provided?: DraggableProvided;
  id: number;
  questionGroupId: number;
  data: DataType;
  setQuestions: (value: React.SetStateAction<QuestionState[]>) => void;
  lastIndex: number;
}

const DropdownCard = ({
  provided,
  id,
  questionGroupId,
  data,
  setQuestions,
  lastIndex,
}: DropdownCardProps) => {
  const {
    title,
    onChangeTitle,
    subtitle,
    onChangeSubtitle,
    onClickAddButton,
    checked,
    switchHandler,
    onChangeLabel,
    onClickDeleteButton,
    options,
    newData,
  } = useCardLogic({ id, initialData: data, setQuestions });

  return (
    <QuestionCard
      provided={provided}
      id={id}
      questionGroupId={questionGroupId}
      titleValue={title}
      onChangeTitle={onChangeTitle}
      subtitleValue={subtitle}
      onChangeSubtitle={onChangeSubtitle}
      onClickAddButton={onClickAddButton}
      checkedSwitch={checked}
      switchHandler={switchHandler}
      data={newData}
      lastIndex={lastIndex}
    >
      <Container>
        <SelectInputContainer>
          <SelectInput />
          <IconContainer>
            <Vector />
          </IconContainer>
        </SelectInputContainer>
        {options?.map(({ optionText, id }) => (
          <Dropdown
            key={id}
            id={id}
            labelValue={optionText}
            onChange={onChangeLabel}
            onClickDeleteIcon={onClickDeleteButton}
          />
        ))}
      </Container>
    </QuestionCard>
  );
};

export default DropdownCard;
