import { NoDataIcon } from "assets";
import { NoDataContainer, NoDataText, TextContainer } from "./styles";
import { NO_DATA_TITLE, NO_DATA_SUBTITLE } from "utils/constants";

const NoData = () => {
  return (
    <NoDataContainer>
      <TextContainer>
        <NoDataText isTitle={true}>{NO_DATA_TITLE}</NoDataText>
        <NoDataText isTitle={false}>{NO_DATA_SUBTITLE}</NoDataText>
      </TextContainer>
      <NoDataIcon />
    </NoDataContainer>
  );
};

export default NoData;
