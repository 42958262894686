import styled from "styled-components";
import { IconButton, IconButtonProps } from "@mui/material";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

export const Container = styled("div")<ExpandMoreProps>(({ theme, expand }) => {
  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: theme.customPalette.white,
    height: "auto",
    border: expand ? `1px solid ${theme.customPalette.responseColor}` : "none",
    borderRadius: "8px",
    paddingBottom: "8px",
    marginBottom: "16px",
    marginLeft: "12px",
    marginRight: "40px",
  };
});

export const RowContainer = styled("div")<ExpandMoreProps>(
  ({ theme, expand }) => {
    return {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: theme.customPalette.responseColor,
      borderRadius: expand ? "8px 8px 0px 0px" : "8px",
      width: "100%",
      height: "64px",
      marginBottom: expand ? "0px" : "8px",
    };
  }
);

interface TitleProps {
  questionTitle: boolean;
}

export const Title = styled("h1")<TitleProps>(({ theme, questionTitle }) => {
  const {
    customSizes: {
      body: {
        smallDescription: {
          semibold: { fontSize, fontWeight, lineHeight },
        },
      },
    },
    customPalette: { blackGrey },
    customFonts: { poppinsSemiBold },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    color: blackGrey,
    fontFamily: poppinsSemiBold,
    paddingLeft: questionTitle ? "32px" : "24px",
    marginBottom: "8px",
  };
});

export const ExpandMoreButton = styled(IconButton)<ExpandMoreProps>(
  ({ theme, expand }) => {
    return {
      transform: expand ? "rotate(0deg)" : "rotate(180deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
      display: "flex",
      justifyContent: "flex-end",
      flexGrow: 1,
    };
  }
);

export const Content = styled("div")(() => {
  return {
    paddingTop: "8px",
    width: "1090px",
    paddingBottom: "8px",
  };
});

export const AdminLabel = styled("div")(({theme}) => {
  return {
    background: theme.customPalette.tag.active.background,
    borderRadius: 8,
    padding: "4px 16px",
    marginLeft: 16,
    fontSize: 12,
    color: theme.customPalette.darkGrey
  };
});

export const TitleContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  };
});
