import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "redux/store";

import { DRAG_AND_DROP_ILLUSTRATION, UPLOAD_LINK } from "../../utils/constants";
import {
  FileUploadContainer,
  UploadFileMessage,
  FileUploadLabel,
  LabelContainer,
  UploadLinkText,
  TextContainer,
  AclarationText,
  InputAdornment,
  FileUploadInput,
  MessageContainer,
  Loader,
  UploadedFileContainer,
  Text,
  ContentContainer,
  ErrorMessage,
  LeftContainer,
} from "./styles";
import {
  CrossIcon,
  DragFile,
  GreyCrossIcon,
  PNGLogo,
  SuccessfulIcon,
} from "assets";
import useUploadStatus from "hooks/useUploadStatus";

interface Props {
  useUploadStatus: ReturnType<typeof useUploadStatus>;
  illustration: string;
  title: string;
  description: string;
}

const UploadContainer = ({
  useUploadStatus,
  illustration,
  title,
  description,
}: Props) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const {
    uploadStatus,
    isSuccessful,
    errorMessage,
    uploadedFile,
    resetUploadStatus,
    setUploadedFile,
    handleRemoveFile,
    handleFileUpload,
  } = useUploadStatus;

  const { emailIcon, successIcon } = useAppSelector(
    (state) => state.companySlice.branding
  );

  const image = illustration === "emailIcon" ? emailIcon : successIcon;

  useEffect(() => {
    if (image) {
      fetch(image)
        .then((response) => response.blob())
        .then((blob) => {
          const fileName = title;
          const file = new File([blob], fileName, { type: blob.type });
          setUploadedFile(file);
        })
        .catch((error) => console.error("Error loading file:", error));
    }
  }, [image]);

  const handleFileDrop = async (event: React.DragEvent) => {
    event.preventDefault();
    const file = event.dataTransfer.files ? event.dataTransfer.files[0] : null;
    handleFileUpload(file, illustration);
  };

  const handleFileInput = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files ? event.target.files[0] : null;
    handleFileUpload(file, illustration);
  };

  const handleDragOver = (event: React.DragEvent) => event.preventDefault();

  const handleDragEnter = (event: React.DragEvent) => {
    event.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (event: React.DragEvent) => {
    event.preventDefault();
    setIsDraggingOver(false);
  };

  const handleLinkClick = (inputRef: React.RefObject<HTMLInputElement>) =>
    inputRef?.current?.click();

  useEffect(() => {
    if (isSuccessful) setTimeout(resetUploadStatus, 2000);
  }, [isSuccessful]);

  return (
    <>
      <Text>{title}</Text>
      <Text description>{description}</Text>
      <FileUploadContainer
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleFileDrop}
        className={isDraggingOver ? "dragging" : ""}
      >
        {uploadedFile ? (
          <UploadedFileContainer>
            <ContentContainer>
              <PNGLogo />
              <TextContainer>
                <Text>{uploadedFile.name}</Text>
                <AclarationText>
                  {uploadedFile.type.split("/")[1]} - {uploadedFile.size}
                </AclarationText>
              </TextContainer>
            </ContentContainer>
            <InputAdornment
              onClick={async () => await handleRemoveFile(illustration)}
              className="pointer"
            >
              <GreyCrossIcon />
            </InputAdornment>
          </UploadedFileContainer>
        ) : (
          <>
            <InputAdornment>
              <DragFile />
            </InputAdornment>
            <LabelContainer>
              <FileUploadLabel htmlFor="fileUpload">
                {DRAG_AND_DROP_ILLUSTRATION}
                <UploadLinkText onClick={() => handleLinkClick(fileInputRef)}>
                  {UPLOAD_LINK}
                </UploadLinkText>
                <FileUploadInput
                  id="file-upload"
                  type="file"
                  onChange={handleFileInput}
                  ref={fileInputRef}
                />
              </FileUploadLabel>
            </LabelContainer>
          </>
        )}
      </FileUploadContainer>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {uploadStatus && (
        <MessageContainer>
          <LeftContainer>
            {!isSuccessful && <Loader />}
            {isSuccessful && (
              <InputAdornment>
                <SuccessfulIcon />
              </InputAdornment>
            )}
            <UploadFileMessage>{uploadStatus}</UploadFileMessage>
          </LeftContainer>
          {isSuccessful && (
            <InputAdornment
              onClick={() => resetUploadStatus()}
              className="pointer"
            >
              <CrossIcon />
            </InputAdornment>
          )}
        </MessageContainer>
      )}
      {uploadStatus && (
        <MessageContainer>
          <LeftContainer>
            {!isSuccessful && <Loader />}
            {isSuccessful && (
              <InputAdornment>
                <SuccessfulIcon />
              </InputAdornment>
            )}
            <UploadFileMessage>{uploadStatus}</UploadFileMessage>
          </LeftContainer>
          {isSuccessful && (
            <InputAdornment
              onClick={() => resetUploadStatus()}
              className="pointer"
            >
              <CrossIcon />
            </InputAdornment>
          )}
        </MessageContainer>
      )}
    </>
  );
};

export default UploadContainer;
