import styled from "styled-components";
import { DatePicker } from "antd";
import theme from "definitions/theme";

interface Props {
  disabled: boolean;
}

export const Container = styled("div")<Props>(({ disabled }) => {
  return {
    marginLeft: !disabled && "32px",
    maxWidth: "680px",
  };
});

export const Picker = styled(DatePicker)(({ theme }) => {
  const {
    typography: { fontFamily },
    customSizes: {
      textAreaInput: {
        desktop: { placeholder: textDesktop },
        mobile: { placeholder: textMobile },
      },
    },
    customPalette: {
      textAreaInput: { placeholder },
      textField: { hover },
      mediumLightGrey
    },
  } = theme;
  return {
    fontFamily: `${fontFamily} !important`,
    fontWeight: textDesktop.fontWeight,
    color: placeholder,
    minWidth: "100%",
    maxWidth: "680px",
    width: "100%",
    height: 48,
    borderRadius: "4px !important",
    border: `2px solid ${mediumLightGrey}`,
    ":focus-visible": {
      outline: 0,
    },
    '&.ant-picker-outlined:hover': {
      border: `2px solid ${hover.border} !important`,
    },
    '&.ant-picker-outlined:focus': {
      border: `2px solid ${hover.border} !important`,
    },
    '&.ant-picker-outlined:focus-within': {
      border: `2px solid ${hover.border} !important`,
      boxShadow: `none`,
    },
    ".ant-picker:hover": {
      border: "red !important"
    },
    [theme.breakpoints.down(768)]: {
      fontSize: textMobile.fontSize,
      fontWeight: textMobile.fontWeight,
      lineHeight: textMobile.lineHeight,
    },
  };
});

export const pickerPopupStyle = () => {
  return {
    width: "100px",
    borderRadius: "7px",
    minWidth: "fit-content",
    minHeight: "fit-content",
    border: `2px solid ${theme.customPalette.textField.hover.border}`,
  };
};
