import styled from "styled-components";

export const Text = styled("p")(({ theme }) => {
  const {
    customSizes: {
      textAreaInput: {
        desktop: {
          placeholder: { fontSize, lineHeight, fontWeight },
        },
      },
    },
    customPalette: { primary },
    customFonts: { poppinsRegular },
  } = theme;

  return {
    fontSize,
    lineHeight,
    fontWeight,
    color: primary,
    fontFamily: poppinsRegular,
    paddingLeft: "32px",
  };
});

export const TagsContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  height: fit-content;
  width: "100%";
  margin-left: 32px;
`;
