import { AxiosResponse } from "axios";

import api from "../../../api";
import { QuestionType } from "../../../utils/utils";
import { QuestionOptionState, QuestionState } from "../../../types";
import { clearApiError, setApiError } from "../../../redux/slices/surveySlice";
import store from "../../../redux/store";

interface BodyData {
  type: QuestionType;
  questionText: string;
  index?: string;
  required: boolean;
  label?: string;
  questionSubtitleText?: string;
  currency?: string;
  questionOptions?: QuestionOptionState[];
}

const { dispatch } = store;

export const postReorder = async (questions: QuestionState[], result?: any) => {
  if (result) {
    const { destination, source } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;
  }

  // eslint-disable-next-line array-callback-return
  questions.map((question, index) => {
    let questionUpdateDto = {
      type: question.type,
      questionText: question.questionText,
      index: (index + 1).toString(),
      required: question.required,
      label: question.label,
      questionSubtitleText: question.questionSubtitleText,
      currency: question.currency,
      questionOptions: question.questionOptions,
    };

    updateQuestion(question.id, questionUpdateDto);
  });
};

export const updateQuestion = async (id: number, body: BodyData) =>
  await api
    .put(`/questions/${id}`, body)
    .then((res: AxiosResponse) => {
      dispatch(clearApiError());
      return res?.data;
    })
    .catch((error) => {
      console.log("[updateQuestion error]: ", error);
      dispatch(setApiError({ error: true }));
    });
