import React from "react";
import { menuClasses } from "@mui/material/Menu";
import { selectClasses } from "@mui/material/Select";
import { Select, MenuItem } from "@mui/material";

import CheckoutButton from "../../components/checkbox";
import theme from "../../definitions/theme";
import { ExpandMore, TagPlaceholder } from "../../assets";
import { filterTagsOptions } from "../../utils/utils";

export interface Props {
  selectedTags: number[];
  handleTagClick: (value: number) => void;
}

const StyledSelect = ({ selectedTags, handleTagClick }: Props) => {
  return (
    <Select
      disableUnderline
      variant="standard"
      multiple
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        sx: {
          alignItems: "center",
          marginTop: "4px",
          [`& .${menuClasses.paper}`]: {
            borderRadius: "7px",
            border: `2px solid ${theme.customPalette.secondary}`,
          },
          [`& .${menuClasses.list}`]: {
            paddingTop: 0,
            paddingBottom: 0,
            background: "white",
            marginTop: "16px",
            "& li:hover": {
              backgroundColor: "transparent !important",
            },
            "& li.Mui-selected": {
              color: "white",
              backgroundColor: "transparent !important",
            },
            "& li.Mui-selected:hover": {
              backgroundColor: "transparent !important",
            },
          },
        },
      }}
      value={selectedTags}
      renderValue={() => (
        <div
          style={{
            display: "flex",
            gap: 8,
            alignItems: "center",
          }}
        >
          <TagPlaceholder />
          {selectedTags.length > 0 ? `Tag (${selectedTags.length})` : "Tags"}
        </div>
      )}
      displayEmpty
      IconComponent={ExpandMore}
      sx={{
        minWidth: 200,
        [`& .${selectClasses.select}`]: {
          background: "white",
          display: "flex",
          color: theme.customPalette.grey,
          borderRadius: "7px",
          border: `2px solid ${theme.customPalette.textField.default.border}`,
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingTop: "10px",
          paddingBottom: "10px",
          alignItems: "center",
          "&:focus": {
            borderRadius: "7px",
            border: `2px solid ${theme.customPalette.textField.default.border}`,
          },
          "&:hover": {
            borderRadius: "7px",
            border: `2px solid ${theme.customPalette.hover}`,
          },
        },
        [`& .${selectClasses.icon}`]: {
          right: "12px",
          top: "12px",
        },
      }}
    >
      {Object.entries(filterTagsOptions).map(([key, label]) => (
        <MenuItem
          key={key}
          value={parseInt(key)}
          style={{ cursor: "default" }}
          disableRipple
        >
          <CheckoutButton
            labelValue={label}
            focus={selectedTags.includes(parseInt(key))}
            onClick={() => handleTagClick(parseInt(key))}
            tag
          />
        </MenuItem>
      ))}
    </Select>
  );
};

export default StyledSelect;
