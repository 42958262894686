import {
  Bar,
  BarChart,
  YAxis,
  PieChart,
  XAxis,
  Pie,
  Tooltip,
  Legend,
  Cell,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { useState } from "react";

import {
  AVERAGE_SESSION,
  NUMBER_OF_RESPONSES,
  TOP_LOCATION,
  TOTAL_COMPLETED,
} from "utils/constants";
import {
  ButtonsContainer,
  ChartContainer,
  DataContainer,
  HeaderContainer,
  IconButton,
  KpiValue,
  KpiContainer,
  KpiTitle,
  Title,
  LegendItem,
  LegendItemColor,
  LegendItemValue,
  LegendContainer,
} from "./styles";
import { BarChartIcon, PieChartIcon } from "assets";
import { AnalyticsState, KpiState } from "types";
import NoData from "components/noData";
import CustomTooltip from "components/customTooltip";
import theme from "../../definitions/theme";

interface ChartComponentProps {
  analyticsData: AnalyticsState[];
  kpiData: KpiState;
}

const ChartComponent = ({ analyticsData, kpiData }: ChartComponentProps) => {
  const [chartType, setChartType] = useState<"bar" | "pie">("bar");
  const [colors, setColors] = useState<{ [key: string]: string }>({});

  // Function to generate a random bluish color
  const getRandomBluishColor = () => {
    const blue = Math.floor(Math.random() * 156) + 100; // Blue component between 100 and 255
    const green = Math.floor(Math.random() * 100); // Green component between 0 and 99
    const red = Math.floor(Math.random() * 100); // Red component between 0 and 99
    return `rgb(${red}, ${green}, ${blue})`;
  };

  // Function to get color for each label
  const getColor = (label: string) => {
    if (colors[label]) return colors[label];

    const newColor = getRandomBluishColor();
    setColors((prevColors) => ({ ...prevColors, [label]: newColor }));
    return newColor;
  };

  const renderLegend = (props: any) => {
    const { payload } = props;

    return (
      <LegendContainer>
        {payload.map((entry: any, index: number) => (
          <LegendItem key={`item-${index}`}>
            <LegendItemColor color={entry.color} />
            <LegendItemValue>{entry.value}</LegendItemValue>
          </LegendItem>
        ))}
      </LegendContainer>
    );
  };

  return (
    <ChartContainer>
      <HeaderContainer>
        <Title>{NUMBER_OF_RESPONSES}</Title>
        <KpiContainer>
          <KpiTitle>{TOTAL_COMPLETED}:</KpiTitle>
          {kpiData?.totalResponses ? (
            <KpiValue>{kpiData?.totalResponses}</KpiValue>
          ) : (
            <KpiValue>-</KpiValue>
          )}
        </KpiContainer>
        <KpiContainer>
          <KpiTitle>{AVERAGE_SESSION}:</KpiTitle>
          {kpiData?.avgSession ? (
            <KpiValue>{kpiData?.avgSession}</KpiValue>
          ) : (
            <KpiValue>-</KpiValue>
          )}
        </KpiContainer>
        <KpiContainer>
          <KpiTitle>{TOP_LOCATION}:</KpiTitle>
          {kpiData?.topLocation ? (
            <KpiValue>{kpiData?.topLocation}</KpiValue>
          ) : (
            <KpiValue>-</KpiValue>
          )}
        </KpiContainer>
        <ButtonsContainer>
          <IconButton
            active={chartType === "bar"}
            className="fill"
            onClick={() => setChartType("bar")}
          >
            <BarChartIcon />
          </IconButton>
          <IconButton
            active={chartType === "pie"}
            className="fill"
            onClick={() => setChartType("pie")}
          >
            <PieChartIcon />
          </IconButton>
        </ButtonsContainer>
      </HeaderContainer>
      <DataContainer>
        <ResponsiveContainer width="100%" height={400}>
          {analyticsData.length > 0 ? (
            chartType === "bar" ? (
              <BarChart data={analyticsData} maxBarSize={44}>
                <CartesianGrid
                  strokeDasharray="0"
                  vertical={false}
                />
                <XAxis dataKey="label" />
                <YAxis allowDecimals={false}/>
                <Bar dataKey="value" fill={theme.customPalette.hover} />
                <Tooltip
                  content={<CustomTooltip barChart/>}
                  cursor={false}
                />
              </BarChart>
            ) : (
              <PieChart data={analyticsData}>
                <Legend
                  iconSize={10}
                  width={300}
                  height={300}
                  layout="horizontal"
                  verticalAlign="middle"
                  align="left"
                  wrapperStyle={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginLeft: "10%",
                  }}
                  content={renderLegend}
                />
                <Pie
                  data={analyticsData}
                  dataKey="value"
                  nameKey="label"
                  cx="50%"
                  cy="50%"
                  innerRadius={50}
                  outerRadius={150}
                >
                  {analyticsData.map((entry) => (
                    <Cell
                      key={`cell-${entry.label}`}
                      fill={getColor(entry.label)}
                      stroke="none"
                    />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} cursor={false} />
              </PieChart>
            )
          ) : (
            <NoData />
          )}
        </ResponsiveContainer>
      </DataContainer>
    </ChartContainer>
  );
};

export default ChartComponent;
