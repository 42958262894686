import React, { useState } from "react";
import { SelectChangeEvent } from "@mui/material";

import { Input, Item, SelectInput, Container } from "./styles";
import { QuestionOptionState } from "types";
import TextInput from "../textInput";
import { QuestionType } from "../../utils/utils";
import { NONE } from "utils/constants";

interface DropdownResponseProps {
  questionId?: number;
  onChangeQuestionOption: (
    questionId: number,
    questionType: string,
    optionText?: string,
    optionId?: number
  ) => void;

  options: QuestionOptionState[];
  questionType: QuestionType;
  input?: boolean;
  initialResponse?: QuestionOptionState;
  initialValue?: string;
}
const { BIFURCATE, DROPDOWN, DROPDOWN_INPUT } = QuestionType;
const DropdownResponse: React.FC<DropdownResponseProps> = ({
  questionId,
  onChangeQuestionOption,
  options,
  input = false,
  questionType,
  initialResponse,
  initialValue = "",
}) => {
  const [selected, setSelected] = useState<QuestionOptionState | undefined>(
    initialResponse || null
  );
  const [menuOpen, setMenuOpen] = useState(false);
  const [inputValue, setInputValue] = useState(initialValue);

  const handleOnChange = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;
    let newValue = value as QuestionOptionState;
    setSelected(newValue);
    if (questionType == DROPDOWN || questionType == BIFURCATE)
      onChangeQuestionOption(questionId, questionType, "", newValue.id);
    if (questionType == DROPDOWN_INPUT && inputValue)
      onChangeQuestionOption(questionId, questionType, inputValue, newValue.id);
  };

  const handleOnInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setInputValue(String(value));
    if (selected)
      onChangeQuestionOption(questionId, questionType, value, selected.id);
  };

  return (
    <Container>
      <SelectInput
        displayEmpty
        value={selected}
        menuOpen={menuOpen}
        defaultValue={null}
        onOpen={() =>
          setTimeout(() => {
            setMenuOpen(true);
          }, 150)
        }
        onClose={() =>
          setTimeout(() => {
            setMenuOpen(false);
          }, 100)
        }
        onChange={handleOnChange}
        renderValue={(selected: any) => (
          <Input className={!selected ? "placeholder" : ""}>
            {selected?.optionText || "-Select-"}
          </Input>
        )}
        inputProps={{ "aria-label": "Without label" }}
      >
        {selected && (
          <Item value="">
            <em>{NONE}</em>
          </Item>
        )}
        {options.map((option) => (
          <Item key={option.id} value={option as any}>
            {option.optionText}
          </Item>
        ))}
      </SelectInput>
      {input && (
        <TextInput budget value={inputValue} onChange={handleOnInputChange} />
      )}
    </Container>
  );
};
export default DropdownResponse;
