import styled from "styled-components";

import theme from "../../definitions/theme";

export const NodeContainer = styled("div")(() => {
  return {
    borderRadius: "8px",
    width: "224px",
    height: "96px",
    padding: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.customPalette.white,
    boxShadow: "0px 4px 8px 0px rgba(145, 145, 154, 0.4)",
  };
});

export const Text = styled("h1")({
  fontFamily: theme.customFonts.poppins,
  color: theme.customPalette.darkGrey,
  fontSize: theme.customSizes.body.description.semibold.fontSize,
  fontWeight: theme.customSizes.body.description.semibold.fontWeight,
  lineHeight: theme.customSizes.body.description.semibold.lineHeight,
  textAlign: "center",
});
