import { AxiosResponse } from "axios";
import api from "../../../api";
import store from "../../../redux/store";
import { setApiError } from "../../../redux/slices/companySlice";
import { CategoryState } from "types";

const { dispatch } = store;

export const getIndustries = async (): Promise<CategoryState[]> => {
  const industries = await api
    .get("/categories/industries")
    .then((res: AxiosResponse) => {
      return res?.data;
    })
    .catch((error) => {
      console.log("[getIndustries error]: ", error);
      dispatch(setApiError({ error: true }));
    });

  return industries;
};
