import { AxiosResponse } from "axios";

import api from "../../../api";
import store from "../../../redux/store";
import { setApiError } from "../../../redux/slices/companySlice";
import { FilterState } from "types";

const { dispatch } = store;

export const saveFilter = async (
  filter: Partial<FilterState>
): Promise<FilterState> => {
  const savedFilter = await api
    .post("/filter", filter)
    .then((res: AxiosResponse) => {
      return res?.data;
    })
    .catch((error) => {
      console.log("[saveFilter error]: ", error);
      dispatch(setApiError({ error: true }));
    });

  return savedFilter;
};
