import { AxiosResponse } from "axios";
import api from "../../../api";
import store from "../../../redux/store";
import { setApiError } from "../../../redux/slices/companySlice";
import { CategoryState } from "types";

const { dispatch } = store;

export const getAiSummary = async (
  selectedSurveyId: number,
  xAxis: string,
  startDate: Date | null,
  endDate: Date | null,
  selectedTags: {
    selectedFilter: string | null;
    selectedIds: CategoryState[];
  }[]
): Promise<string> => {
  if (!startDate && !endDate) {
    startDate = new Date();
    startDate.setDate(startDate.getDate() - 6);
    startDate.setHours(0, 0, 0, 0);
    endDate = new Date();
    endDate.setHours(23, 59, 59, 999);
  }
  let url = `user-responses/summary?surveyId=${selectedSurveyId}`;
  if (xAxis) url += `&xAxis=${xAxis.toLowerCase()}`;
  for (const tag of selectedTags) {
    if (tag.selectedFilter && tag.selectedIds.length > 0)
      url += `&${tag.selectedFilter.toLowerCase()}=${tag.selectedIds
        .map((id) => id.id)
        .join(",")}`;
  }
  url += `&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;

  const analytics = await api
    .get(url)
    .then((res: AxiosResponse) => {
      return res?.data;
    })
    .catch((error) => {
      console.log("[getAnalytics error]: ", error);
      dispatch(setApiError({ error: true }));
    });

  return analytics;
};
