import { AxiosResponse } from "axios";

import api from "../../../api";
import store from "../../../redux/store";
import {
  setQuestionTypes,
  setQuestionTypesError,
  clearQuestionTypesError,
} from "redux/slices/questionTypesSlice";
import { mapToQuestionType } from "utils/utils";

const { dispatch } = store;

export const getQuestionTypes = async () => {
  await api
    .get(`/question-types`)
    .then((res: AxiosResponse) => {
      const questionTypes = mapToQuestionType(res.data);
      dispatch(setQuestionTypes(questionTypes));
      dispatch(clearQuestionTypesError());
    })
    .catch(() => {
      dispatch(setQuestionTypesError({ error: true }));
    });
};
