import { useState } from "react";

import { updateTagResponses } from "../pages/ResponseDetails/services/updateTagResponses";
import useTagLogic from "./useTagLogic";
import { createEmptyCategory } from "pages/ResponseDetails/services/createEmptyCategory";

interface Props {
  categoryId: number;
  responseId: number;
  maturity?: string;
  temperature?: string;
  techsTags?: string[];
}

const useResponseTagsLogic = ({
  responseId,
  maturity,
  temperature,
  techsTags,
  categoryId,
}: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const {
    handleTagClick: handleMaturityTagClick,
    selectedTag: selectedMaturityTag,
    setSelectedTag: setSelectedMaturityTag,
  } = useTagLogic({ initialTag: maturity });
  const {
    handleTagClick: handleTemperatureTagClick,
    selectedTag: selectedTemperatureTag,
    setSelectedTag: setSelectedTemperatureTag,
  } = useTagLogic({ initialTag: temperature });
  const {
    handleTagClick: handleTechTagClick,
    selectedTag: selectedTechTags,
    setSelectedTag: setSelectedTechTags,
  } = useTagLogic({ initialTag: techsTags });

  const handleCancel = () => {
    setSelectedMaturityTag(maturity?.toLowerCase());
    setSelectedTemperatureTag(temperature?.toLowerCase());
    setSelectedTechTags(techsTags?.map((tag) => tag.toLowerCase()));
    setIsEditing(false);
  };

  const handleSaveChanges = async () => {
    if (categoryId === undefined) {
      categoryId = await createEmptyCategory(responseId);
    }
    await updateTagResponses(categoryId, {
      maturity: selectedMaturityTag as string,
      temperature: selectedTemperatureTag as string,
      techsTags: selectedTechTags as string[],
    });

    setIsEditing(false);
  };

  return {
    isEditing,
    setIsEditing,
    selectedMaturityTag: selectedMaturityTag as string,
    selectedTemperatureTag: selectedTemperatureTag as string,
    selectedTechTags: selectedTechTags as string[],
    handleCancel,
    handleMaturityTagClick,
    handleTechTagClick,
    handleTemperatureTagClick,
    handleSaveChanges,
    noTagsSelected:
      !selectedMaturityTag &&
      !selectedTemperatureTag &&
      !selectedTechTags?.length,
  };
};

export default useResponseTagsLogic;
