import React, { PropsWithChildren, useState } from "react";

import {
  Container,
  InfoContainer,
  Subtitle,
  SubtitleContainer,
  SwitchContainer,
  SwitchStyled,
  TextContainer,
  Title,
} from "./styles";
import { InfoIcon } from "assets";
import InfoModal from "components/infoModal";
import { InfoModalContainer } from "components/infoModal/styles";

interface SettingsDetailsProps extends PropsWithChildren {
  title: string;
  subtitle: string;
  checked?: boolean;
  switchHandler?: () => void;
  infoButton?: boolean;
}

const SettingsDetails = ({
  title,
  subtitle,
  checked,
  switchHandler,
  infoButton,
}: SettingsDetailsProps) => {
  const [showInfo, setShowInfo] = useState(false);

  const handleMouseOver = () => {
    setShowInfo(true);
  };

  const handleMouseLeave = (event) => {
    const container = document.getElementById("container");
    const modal = document.getElementById("info-modal");
    if (
      container &&
      !container.contains(event.relatedTarget) &&
      modal &&
      !modal.contains(event.relatedTarget)
    ) {
      setShowInfo(false);
    }
  };

  return (
    <Container id="container" onMouseLeave={handleMouseLeave}>
      <TextContainer>
        <Title>{title}</Title>
        <SubtitleContainer>
          <Subtitle>{subtitle}</Subtitle>
          {infoButton && (
            <InfoContainer onMouseOver={handleMouseOver}>
              <InfoIcon />
            </InfoContainer>
          )}
        </SubtitleContainer>
        {showInfo && (
          <InfoModalContainer id="info-modal" onMouseLeave={handleMouseLeave}>
            <InfoModal />
          </InfoModalContainer>
        )}
      </TextContainer>
      {switchHandler && (
        <SwitchContainer>
          <SwitchStyled
            focusVisibleClassName=".Mui-focusVisible"
            disableRipple
            checked={checked}
            onChange={switchHandler}
          />
        </SwitchContainer>
      )}
    </Container>
  );
};

export default SettingsDetails;
