import React from "react";

import { ModalContainer, StyledTickIcon, Text } from "./styles";
import { COPIED_TO_CLIPBOARD } from "utils/constants";

const CopiedToClipboardModal: React.FC = () => (
  <ModalContainer>
    <StyledTickIcon />
    <Text>{COPIED_TO_CLIPBOARD}</Text>
  </ModalContainer>
);

export default CopiedToClipboardModal;
