import styled from "styled-components";

export const RowContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  };
});

export const ColumnContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  };
});

export const LeftContainer = styled("div")(() => ({
  display: "flex",
  width: "80%",
  paddingTop: "8px",
}));

export const LabelInput = styled("input")(({ theme }) => {
  const {
    customPalette: { mediumLightGrey },
    customFonts: { poppins },
    customSizes: {
      body: {
        smallDescription: {
          regular: { fontSize, fontWeight, lineHeight },
        },
      },
    },
  } = theme;

  return {
    width: "320px",
    height: "48px",
    fontSize: fontSize,
    fontWeight: fontWeight,
    lineHeight: lineHeight,
    fontFamily: poppins,
    borderColor: mediumLightGrey,
    border: `2px solid ${mediumLightGrey}`,
    borderRadius: "8px",
    paddingLeft: "8px",
  };
});

export const IconContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    marginLeft: "8px",
    position: "relative",
  };
});
