import { useCallback } from "react";

import { saveFilter } from "../pages/Analytics/services/saveFilter";
import { getFiltersById } from "../pages/Analytics/services/getFilterById";
import { deleteFilter } from "../pages/Analytics/services/deleteFilter";
import { FilterState, CategoryState } from "../types";
import { filters } from "../pages/Analytics/common/filters";

const useFilterHandlers = (
  selectedTags: {
    selectedFilter: string | null;
    selectedIds: CategoryState[];
  }[],
  setSelectedTags: React.Dispatch<React.SetStateAction<any>>,
  setFilterRows: React.Dispatch<React.SetStateAction<any>>,
  setInputFilters: React.Dispatch<React.SetStateAction<Partial<FilterState>[]>>,
  inputFilters: Partial<FilterState>[],
  startDate: Date | null,
  endDate: Date | null,
  xAxis: string,
  setXAxis: React.Dispatch<React.SetStateAction<string>>,
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>,
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>,
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const handleSaveFilter = useCallback(
    async (filterName: string) => {
      const industries =
        selectedTags
          .find((tag) => tag.selectedFilter === "Industries")
          ?.selectedIds.map((id) => id.id) || [];
      const locations =
        selectedTags
          .find((tag) => tag.selectedFilter === "Locations")
          ?.selectedIds.map((id) => id.id) || [];
      const temperatures =
        selectedTags
          .find((tag) => tag.selectedFilter === "Temperatures")
          ?.selectedIds.map((id) => id.id) || [];
      const maturities =
        selectedTags
          .find((tag) => tag.selectedFilter === "Maturities")
          ?.selectedIds.map((id) => id.id) || [];
      const techStacks =
        selectedTags
          .find((tag) => tag.selectedFilter === "Technologies")
          ?.selectedIds.map((id) => id.id) || [];

      const filter: Partial<FilterState> = {
        name: filterName,
        startDate,
        endDate,
        xAxis,
        ...(industries.length && { industries }),
        ...(locations.length && { locations }),
        ...(temperatures.length && { temperatures }),
        ...(maturities.length && { maturities }),
        ...(techStacks.length && { techStacks }),
      };

      const savedFilter = await saveFilter(filter);
      setInputFilters([...inputFilters, savedFilter]);
    },
    [selectedTags, startDate, endDate, xAxis, inputFilters, setInputFilters]
  );

  const handleSelectFilter = useCallback(
    async (filter: number) => {
      setExpanded(true);
      const data = await getFiltersById(filter);
      setXAxis(data.xAxis);
      setStartDate(
        data.startDate
          ? new Date(
              new Date(data.startDate).setDate(
                new Date(data.startDate).getDate() + 1
              )
            )
          : null
      );
      setEndDate(
        data.endDate
          ? new Date(
              new Date(data.endDate).setDate(
                new Date(data.endDate).getDate() + 1
              )
            )
          : null
      );
      setSelectedTags(data.filters);
      setFilterRows([
        {
          selectedFilter: null,
          selectedFilterValue: null,
          styledSelectOptions: [],
        },
      ]);
      data.filters.forEach((row) => {
        const newRow = {
          selectedFilter: filters.find(
            (filter) => filter.value === row.selectedFilter
          )?.key,
          selectedFilterValue: row.selectedFilter,
          styledSelectOptions: row.selectedIds,
        };
        setFilterRows((prev) => [...prev, newRow]);
      });
      setFilterRows((prev) =>
        prev.filter((row) => row.selectedFilter !== null)
      );
    },
    [
      setXAxis,
      setStartDate,
      setEndDate,
      setSelectedTags,
      setFilterRows,
      setExpanded,
    ]
  );

  const handleDeleteFilter = useCallback(
    async (filterId: number) => {
      setXAxis("Date");
      setStartDate(null);
      setEndDate(null);
      setFilterRows([
        {
          selectedFilter: null,
          selectedFilterValue: null,
          styledSelectOptions: [],
        },
      ]);
      setSelectedTags([
        {
          selectedFilter: null,
          selectedIds: [],
        },
      ]);
      setInputFilters(inputFilters.filter((filter) => filter.id !== filterId));
      await deleteFilter(filterId);
    },
    [
      setXAxis,
      setStartDate,
      setEndDate,
      setFilterRows,
      setSelectedTags,
      setInputFilters,
      inputFilters,
    ]
  );

  const handleClearFilters = useCallback(() => {
    setExpanded(false);
    setSelectedTags([
      {
        selectedFilter: null,
        selectedIds: [],
      },
    ]);
    setFilterRows([
      {
        selectedFilter: null,
        selectedFilterValue: null,
        styledSelectOptions: [],
      },
    ]);
  }, [setExpanded, setSelectedTags, setFilterRows]);

  return {
    handleSaveFilter,
    handleSelectFilter,
    handleDeleteFilter,
    handleClearFilters,
  };
};

export default useFilterHandlers;
