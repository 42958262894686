import { AxiosResponse } from "axios";

import api from "../../../api";
import store from "../../../redux/store";
import { clearApiError, setApiError } from "../../../redux/slices/surveySlice";

interface BodyData {
  optionText: string;
}
const { dispatch } = store;

export const createQuestionOption = async (id: number, body: BodyData) =>
  await api
    .post(`/questions/${id}/options`, body)
    .then((res: AxiosResponse) => {
      dispatch(clearApiError());
      return res?.data;
    })
    .catch((error) => {
      console.log("[createQuestionOption error]: ", error);
      dispatch(setApiError({ error: true }));
    });
