import styled from "styled-components";
import { Switch } from "@mui/material";

export const DeleteButton = styled("button")(({ theme }) => {
  const {
    customSizes: {
      button: { secondary },
    },
    customPalette: { grey },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize: secondary.fontSize,
    fontWeight: secondary.fontWeight,
    lineHeight: secondary.lineHeight,
    fontFamily: poppins,
    border: "none",
    cursor: "pointer",
    background: "none",
    whiteSpace: "nowrap",
    color: grey,
    paddingRight: "24px",
    ":hover": {
      textDecoration: "underline",
    },
  };
});

export const Container = styled("div")(({ theme }) => {
  const {
    customPalette: {
      card: { boxShadow },
    },
  } = theme;
  return {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    backgroundColor: theme.customPalette.white,
    width: "100%",
    borderRadius: "16px",
    boxShadow: boxShadow,
    marginTop: "16px",
    paddingTop: "16px",
    paddingBottom: "8px",
    marginBottom: "8px",
  };
});

export const Content = styled("div")(({ theme }) => {
  const {
    customSizes: {
      body: {
        description: { semibold },
      },
      home: {
        mobile: { description },
      },
    },
  } = theme;

  return {
    width: "100%",
    "&.title": {
      padding: "0 0 0 48px",
      fontSize: semibold.fontSize,
      fontWeight: semibold.fontWeight,
      lineHeight: semibold.lineHeight,
    },
    "&.subtitle": {
      fontSize: description.fontSize,
      fontWeight: description.fontWeight,
      lineHeight: description.lineHeight,
      paddingRight: "24px",
    },
  };
});

export const RowContainer = styled("div")(() => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&.input": {
      width: "100%",
    },
  };
});

export const SwitchStyled = styled(Switch)(({ theme }) => {
  const {
    customPalette: { secondary, mediumLightGrey, white, lightGrey },
  } = theme;

  return {
    "&.MuiSwitch-root": {
      width: "33px",
      height: "17px",
      padding: 0,
    },
    "& .MuiSwitch-switchBase": {
      position: "absolute",
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: white,
        "& + .MuiSwitch-track": {
          backgroundColor: secondary,
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        border: `6px solid ${white}`,
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: mediumLightGrey,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: "13px",
      height: "13px",
      backgroundColor: white,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: lightGrey,
      opacity: 1,
      width: "33px",
      height: "17px",
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  };
});

export const Text = styled("h1")(({ theme }) => {
  const {
    customPalette: { darkMediumGrey, secondary },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "24px",
    color: darkMediumGrey,
    fontFamily: poppins,
    opacity: "60%",
    marginTop: 0,
    paddingLeft: "48px",

    "&.footer": {
      fontSize: "13px",
      opacity: "100%",
      alignSelf: "center",
      marginBottom: 0,
      paddingLeft: 0,
    },

    "&.link": {
      fontSize: "13px",
      color: secondary,
      padding: 0,
      margin: 0,
      opacity: "100%",
    },
  };
});

export const SwitchContainer = styled("div")(() => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "25px",
  };
});
