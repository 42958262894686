const normalizeString = (str) => str.toLowerCase().replace(/[^a-z0-9]/g, "");

export const isInArray = (array, label) => {
  return array.some((item) => compareTags(item, label));
};

export const compareTags = (str1, str2) => {
  return (
    normalizeString(str1).includes(normalizeString(str2)) ||
    normalizeString(str2).includes(normalizeString(str1))
  );
};
