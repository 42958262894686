import styled from "styled-components";

export const RadioContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "row" as const,
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: "16px",
}));

export const LeftContainer = styled("div")(() => ({
  display: "flex",
  width: "80%",
}));

export const Label = styled("label")(({ theme }) => {
  const {
    customPalette: { darkGrey },
    customFonts: { poppins },
  } = theme;

  return {
    marginLeft: "8px",
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "20px",
    color: darkGrey,
  };
});

export const LabelInput = styled("input")(({ theme }) => {
  const {
    customPalette: {
      textField: { hover },
      darkGrey,
    },
    customFonts: { poppins },
  } = theme;

  return {
    all: "unset",
    width: "100%",
    maxWidth: 640,
    marginLeft: "8px",
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "20px",
    color: darkGrey,
    fontFamily: poppins,
    paddingLeft: "5px",
    border: "2px solid transparent",

    borderRadius: "4px",
    ":hover": {
      border: `2px solid ${hover.border}`,
      borderRadius: "4px",
    },
  };
});

interface RadioProps {
  active: boolean;
}

export const RadioExternal = styled("div")<RadioProps>(({ theme, active }) => {
  const {
    customPalette: {
      radio: { normal, hover, filled },
    },
  } = theme;
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "24px",
    height: "24px",
    borderRadius: "12px",
    border: active
      ? `1px solid ${filled.border}`
      : `1px solid ${normal.border}`,
    backgroundColor: `${normal.background}`,
    ":hover": {
      border: `1px solid ${hover.border}`,
      boxShadow: `0 0 2px ${hover.border}`,
    },

    "&.disabled": {
      ":hover": {
        border: `1px solid ${normal.border}`,
        boxShadow: "none",
      },
    },
  };
});

export const IconContainer = styled("div")(() => {
  return {
    display: "flex",
    marginLeft: "8px",
  };
});

export const RadioInternal = styled("div")<RadioProps>(({ theme, active }) => {
  const {
    customPalette: {
      radio: { normal, filled },
    },
  } = theme;
  return {
    width: "12px",
    height: "12px",
    borderRadius: "6px",
    border: active
      ? `1px solid ${filled.border}`
      : `1px solid ${normal.background}`,
    backgroundColor: active ? `${filled.background}` : `${normal.background}`,
  };
});
