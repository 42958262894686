import styled from "styled-components";

export const Container = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "16px",
  };
});

export const TitleContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
  };
});

export const InfoContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    paddingTop: "8px",
  };
});
