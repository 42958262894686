import React from "react";
import { DraggableProvided } from "react-beautiful-dnd";

import QuestionCard from "../questionCard";
import DatePicker from "../datePicker";
import useCardLogic, { DataType } from "../../hooks/useCardLogic";
import { QuestionState } from "../../types";

interface DatePickerCardProps {
  provided?: DraggableProvided;
  id: number;
  questionGroupId: number;
  data: DataType;
  setQuestions: (value: React.SetStateAction<QuestionState[]>) => void;
  lastIndex: number;
}

const DatePickerCard = ({
  provided,
  id,
  questionGroupId,
  data,
  setQuestions,
  lastIndex,
}: DatePickerCardProps) => {
  const {
    title,
    onChangeTitle,
    subtitle,
    onChangeSubtitle,
    checked,
    switchHandler,
    newData,
  } = useCardLogic({ id, initialData: data, hasOptions: false, setQuestions });

  return (
    <QuestionCard
      provided={provided}
      id={id}
      questionGroupId={questionGroupId}
      titleValue={title}
      onChangeTitle={onChangeTitle}
      subtitleValue={subtitle}
      onChangeSubtitle={onChangeSubtitle}
      addButton={false}
      checkedSwitch={checked}
      switchHandler={switchHandler}
      data={newData}
      lastIndex={lastIndex}
    >
      <DatePicker disabled />
    </QuestionCard>
  );
};

export default DatePickerCard;
