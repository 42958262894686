import React, { useEffect, useState } from "react";

import PageWrapper from "../../components/pageWrapper";
import { DESIGN } from "../../utils/constants";
import ColorPalette from "../../components/colorPalette";
import {
  Container,
  Title,
  Line,
  VerticalLine,
  LeftContainer,
  RightContainer,
} from "./styles";
import { useAppSelector } from "../../redux/store";
import { colorPaletteOptions, typographyOptions } from "../../utils/utils";
import Typography from "../../components/typography";
import BrandLogo from "components/brandLogo";
import Preview from "components/preview";
import IllustrationsPreview from "../../components/illustrationsPreview";
import BrandIllustrations from "../../components/brandIllustrations";

const Design = () => {
  const { branding } = useAppSelector((state) => state.companySlice);

  const [colorsArray, setColorsArray] = useState(colorPaletteOptions(branding));
  const [typographyArray, setTypographyArray] = useState(
    typographyOptions(branding)
  );
  const [selectedSubsection, setSelectedSubSection] = useState("Styling");

  useEffect(() => {
    setColorsArray(colorPaletteOptions(branding));
    setTypographyArray(typographyOptions(branding));
  }, [branding]);

  const [showPrimaryColorBox, setShowPrimaryColorBox] = useState(false);
  const [showSecondaryColorBox, setShowSecondaryColorBox] = useState(false);
  const [showTagsColorBox, setShowTagsColorBox] = useState(false);
  const [showLinksColorBox, setShowLinksColorBox] = useState(false);
  const [showTitleColorBox, setShowTitleColorBox] = useState(false);
  const [showTextColorBox, setShowTextColorBox] = useState(false);
  const [showHomePageTextColorBox, setShowHomePageTextColorBox] =
    useState(false);
  const [showBackgroundColorBox, setShowBackgroundColorBox] = useState(false);

  const colorStates = [
    showPrimaryColorBox,
    showSecondaryColorBox,
    showTagsColorBox,
    showLinksColorBox,
    showBackgroundColorBox,
  ];

  const colorSetters = [
    setShowPrimaryColorBox,
    setShowSecondaryColorBox,
    setShowTagsColorBox,
    setShowLinksColorBox,
    setShowBackgroundColorBox,
  ];

  const typographyStates = [
    showTitleColorBox,
    showTextColorBox,
    showHomePageTextColorBox,
  ];
  const typographySetters = [
    setShowTitleColorBox,
    setShowTextColorBox,
    setShowHomePageTextColorBox,
  ];

  const handleContainerClick = (event: React.MouseEvent) => {
    const target = event.target as HTMLElement;

    colorsArray.forEach((option, index) => {
      const { buttonClassname, className } = option;

      if (
        colorStates[index] &&
        !target.closest(`.${className}`) &&
        !target.closest(`.${buttonClassname}`)
      ) {
        colorSetters[index](false);
      }
    });

    typographyArray.forEach((option, index) => {
      const { buttonClassname, className } = option;

      if (
        typographyStates[index] &&
        !target.closest(`.${className}`) &&
        !target.closest(`.${buttonClassname}`)
      ) {
        typographySetters[index](false);
      }
    });
  };

  return (
    <PageWrapper
      selectedSubsection={selectedSubsection}
      setSelectedSubSection={setSelectedSubSection}
    >
      <Title>{DESIGN}</Title>
      <Container onClick={handleContainerClick}>
        {selectedSubsection === "Styling" ? (
          <>
            <LeftContainer>
              <ColorPalette
                colorSetters={colorSetters}
                colorStates={colorStates}
                colorsArray={colorsArray}
              />
              <Line width="336px" />
              <Typography
                typographySetters={typographySetters}
                typographyStates={typographyStates}
                typographyArray={typographyArray}
              />
            </LeftContainer>
            <VerticalLine orientation="vertical" />
            <RightContainer>
              <Preview
                primaryColor={colorsArray[0].selectedColor}
                tagColor={colorsArray[2].selectedColor}
                linkColor={colorsArray[3].selectedColor}
                titleColor={typographyArray[0].selectedColor}
                subtitleColor={typographyArray[1].selectedColor}
              />
            </RightContainer>
          </>
        ) : (
          <>
            <LeftContainer>
              <BrandLogo />
              <Line width="370px" />
              <BrandIllustrations />
            </LeftContainer>
            <VerticalLine orientation="vertical" />
            <RightContainer>
              <IllustrationsPreview />
            </RightContainer>
          </>
        )}
      </Container>
    </PageWrapper>
  );
};

export default Design;
