import { useEffect, useState } from "react";

import { GroupedResponses } from "types";
import { QuestionType, splitResponse } from "utils/utils";
import DropdownResponse from "components/dropdownResponse";
import Checkbox from "../checkbox";
import RadioButton from "../radioButton";
import NumericInput from "../numericInput";
import TextInput from "../textInput";
import ShortText from "../shortText";
import TagsInput from "../TagInput";
import { Container, LongTextContainer, NumericInputContainer } from "./styles";
import { ENTER_YOUR_TEXT_HERE } from "../../utils/constants";
import DatePicker from "../datePicker";

interface ResponseMatcherComponentProps {
  questionResponses: GroupedResponses[];
  onChangeText: (
    event: React.ChangeEvent<HTMLInputElement> | string,
    questionId: number
  ) => void;
  onChangeQuestionOption: (
    questionId: number,
    questionType: string,
    optionText?: string,
    optionId?: number,
    active?: boolean
  ) => void;
  techStack?: { primary: string[]; secondary: string[] };
}

const QuestionResponseMatcherComponent: React.FC<
  ResponseMatcherComponentProps
> = ({
  questionResponses,
  onChangeText,
  onChangeQuestionOption,
  techStack,
}) => {
  const { type } = questionResponses[0].question;
  const [radioButtons, setRadioButtons] = useState([]);
  const [checkboxes, setCheckboxes] = useState([]);
  const [numericInputValues, setNumericInputValues] = useState([]);

  const {
    BIFURCATE,
    CHECKBOX,
    RADIO,
    NUMERIC_INPUT,
    TAG_TEXT,
    DROPDOWN,
    DROPDOWN_INPUT,
    SHORT_TEXT,
    LONG_TEXT,
    DATE,
  } = QuestionType;

  useEffect(() => {
    const { question, adminQuestionOptionId } = questionResponses[0];
    const { questionOptions = [] } = question;
    if (type === RADIO) {
      const updatedRadioButtons = questionOptions.map(
        ({ id }) => id === adminQuestionOptionId
      );

      setRadioButtons(updatedRadioButtons);
    }
    if (type === CHECKBOX) {
      const selectedOptionIds = questionResponses
        .map((response) => response.adminQuestionOptionId)
        .filter((id) => id !== undefined);

      const updatedCheckboxes = questionOptions.map(({ id }) =>
        selectedOptionIds.includes(id)
      );

      setCheckboxes(updatedCheckboxes);
    }
    if (type === NUMERIC_INPUT) {
      const initialValues = questionResponses[0].question.questionOptions.map(
        ({ id }) => {
          const optionResponse = questionResponses.find(
            (response) => response.adminQuestionOptionId === id
          );
          return {
            id,
            value: Number(optionResponse?.adminText || 0),
          };
        }
      );

      setNumericInputValues(initialValues);
    }
  }, [questionResponses]);

  const handleNumberChange = (value: string, id: number) => {
    const numericValue = Number(value);

    setNumericInputValues((prev) =>
      prev.map((input) =>
        input.id === id ? { ...input, value: numericValue } : input
      )
    );

    onChangeQuestionOption(
      questionResponses[0].questionId,
      NUMERIC_INPUT,
      value,
      id
    );
  };

  return (
    <>
      {(type === BIFURCATE || type === DROPDOWN) && (
        <DropdownResponse
          onChangeQuestionOption={onChangeQuestionOption}
          questionId={questionResponses[0].questionId}
          initialResponse={questionResponses[0].adminQuestionOption}
          options={questionResponses[0].question.questionOptions}
          questionType={type}
        />
      )}
      {type === CHECKBOX && (
        <Container>
          {questionResponses[0].question.questionOptions.map(
            ({ optionText, id }, index) => (
              <Checkbox
                key={id}
                id={id}
                labelValue={optionText}
                focus={checkboxes[index]}
                onClick={(active) => {
                  setCheckboxes((prev) =>
                    prev.map((ch, i) => (index === i ? !ch : ch))
                  );
                  onChangeQuestionOption(
                    questionResponses[0].questionId,
                    CHECKBOX,
                    optionText,
                    id,
                    active
                  );
                }}
              />
            )
          )}
        </Container>
      )}
      {type === RADIO && (
        <Container>
          {questionResponses[0].question.questionOptions.map(
            ({ optionText, id }, i) => (
              <RadioButton
                key={id}
                id={id}
                checked={radioButtons[i]}
                labelValue={optionText}
                onRadioChange={() => {
                  setRadioButtons((prev) =>
                    prev.map((rb, index) =>
                      index === i ? !prev[index] : false
                    )
                  );
                  onChangeQuestionOption(
                    questionResponses[0].questionId,
                    RADIO,
                    optionText,
                    id
                  );
                }}
              />
            )
          )}
        </Container>
      )}
      {type === TAG_TEXT && (
        <TagsInput
          questionOptions={questionResponses[0]?.question?.questionOptions}
          onChangeTags={onChangeText}
          initialResponses={
            splitResponse(questionResponses[0]?.adminText) || []
          }
          questionId={questionResponses[0].questionId}
          techStack={techStack}
        />
      )}
      {type === NUMERIC_INPUT && (
        <NumericInputContainer>
          {questionResponses[0].question.questionOptions.map(
            ({ optionText, id }) => (
              <NumericInput
                key={id}
                id={id}
                labelValue={optionText}
                initialValue={
                  numericInputValues.find((input) => input.id === id)?.value
                }
                onNumberChange={(value) => handleNumberChange(value, id)}
              />
            )
          )}
        </NumericInputContainer>
      )}
      {type === DROPDOWN_INPUT && (
        <DropdownResponse
          questionId={questionResponses[0].questionId}
          onChangeQuestionOption={onChangeQuestionOption}
          options={questionResponses[0].question.questionOptions}
          input
          initialValue={questionResponses[0].adminText}
          initialResponse={questionResponses[0].adminQuestionOption}
          questionType={type}
        />
      )}
      {type === SHORT_TEXT && (
        <ShortText
          placeholder={ENTER_YOUR_TEXT_HERE}
          isResponseField
          initialValue={questionResponses[0].adminText}
          onChange={(e) =>
            onChangeText(e.target.value, questionResponses[0].questionId)
          }
        />
      )}
      {type === LONG_TEXT && (
        <LongTextContainer>
          <TextInput
            placeholder={ENTER_YOUR_TEXT_HERE}
            showErrorLabel={false}
            multiline
            initialValue={questionResponses[0].adminText}
            className="multiline"
            onChange={(e) =>
              onChangeText(e.target.value, questionResponses[0].questionId)
            }
          />
        </LongTextContainer>
      )}
      {type === DATE && (
        <DatePicker
          initialValue={questionResponses[0].adminText}
          onChange={(value) =>
            onChangeText(value, questionResponses[0].questionId)
          }
        />
      )}
    </>
  );
};

export default QuestionResponseMatcherComponent;
