import React, { useState } from "react";

import { DeleteIcon, AddTagIcon, EffectusSmallLogo } from "../../assets";
import { QuestionOptionState } from "../../types";
import { StyledTag, TagText } from "./styles";
import { isInArray } from "utils/methods/isInArray";

export interface TagProps {
  variantType?: "default" | "active";
  label: string;
  removeTag?: (tag: any) => void;
  addTag?: (tags: any) => void;
  hasMargin?: boolean;
  existingTag?: QuestionOptionState;
  disabled?: boolean;
  techStack?: { primary: string[]; secondary: string[] };
}

const Tags: React.FC<TagProps> = ({
  variantType = "default",
  label,
  hasMargin,
  removeTag,
  addTag,
  existingTag,
  disabled = false,
  techStack,
}) => {
  const [active, setActive] = useState(variantType === "active");
  const handleTagSelected = () => {
    if (addTag) if (existingTag) addTag(existingTag || label);

    setActive((prev) => !prev);
  };

  const isOffered =
    isInArray(techStack?.primary, label) ||
    isInArray(techStack?.secondary, label);

  return (
    <StyledTag
      variantType={active ? "active" : "default"}
      disabled={disabled}
      hasMargin={hasMargin}
      onClick={() =>
        !disabled &&
        (!active ? handleTagSelected() : removeTag && removeTag(label))
      }
    >
      {isOffered && <EffectusSmallLogo />}
      <TagText>{label}</TagText>
      {!disabled && (active ? <DeleteIcon /> : <AddTagIcon />)}
    </StyledTag>
  );
};

export default Tags;
