import { IconButton } from "@mui/material";
import styled from "styled-components";

export const Container = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100vh",
    padding: "80px",
    overflow: "hidden",

    [theme.breakpoints.down(760)]: {
      padding: "8px",
      overflow: "scroll",
    },
  };
});

export const IconButtonDiv = styled(IconButton)(({ theme }) => {
  return {
    margin: 0,
    padding: 0,
  };
});

export const ContentContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    backgroundColor: theme.customPalette.white,
    height: "100vh",
    width: "100%",
  };
});

export const HeaderContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    backgroundColor: theme.customPalette.white,
    alignItems: "left",
    [theme.breakpoints.down(768)]: {
      padding: "40px 0 24px 24px",
    },
  };
});

export const HeaderTextContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "73.3px",
    alignItems: "left",
    [theme.breakpoints.down(768)]: {
      marginLeft: "0px",
    },
  };
});

export const LeftContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.customPalette.white,
    marginRight: "40px",
    paddingTop: "16px",
    height: "100vh",
  };
});

export const RightContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.customPalette.white,
    overflowY: "scroll",
    paddingTop: "8px",
    width: "95vh",
    marginBottom: "184px",
    "::-webkit-scrollbar": { display: "none" },
  };
});

export const Panel = styled("div")(({ theme }) => {
  const {
    customPalette: {
      card: { boxShadow },
    },
  } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.customPalette.white,
    width: "266px",
    boxShadow: boxShadow,
  };
});

export const TextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "left",
  paddingBottom: "24px",
  [theme.breakpoints.down(768)]: {
    marginLeft: "0px",
  },
}));

export const Title = styled("h1")(({ theme }) => {
  const {
    customSizes: {
      card: {
        desktop: { title: titleDesktop },
        mobile: { title: titleMobile },
      },
    },
    customPalette: { blackGrey },
    customFonts: { poppinsSemiBold },
  } = theme;

  return {
    [theme.breakpoints.down(768)]: {
      fontSize: titleMobile.fontSize,
      fontWeight: titleMobile.fontWeight,
      lineHeight: titleMobile.lineHeight,
    },
    fontSize: titleDesktop.fontSize,
    fontWeight: titleDesktop.fontWeight,
    lineHeight: titleDesktop.lineHeight,
    color: blackGrey,
    fontFamily: poppinsSemiBold,
  };
});

export const Subtitle = styled("h2")(({ theme }) => {
  const {
    customSizes: {
      body: {
        smallDescription: { semibold },
      },
    },
    customPalette: { primary },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize: semibold.fontSize,
    fontWeight: semibold.fontWeight,
    lineHeight: semibold.lineHeight,
    color: primary,
    fontFamily: poppins,
  };
});

export const Text = styled("h1")(({ theme }) => {
  const {
    customSizes: {
      body: {
        smallDescription: {
          regular: { fontSize, fontWeight, lineHeight },
        },
      },
    },
    customPalette: { darkMediumGrey },
    customFonts: { poppins, poppinsSemiBold },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    color: darkMediumGrey,
    fontFamily: poppins,
    paddingLeft: "16px",
    "&.bold": {
      fontFamily: poppinsSemiBold,
      fontSize,
      fontWeight,
      lineHeight,
    },
  };
});

export const TabsContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
  };
});

export const Tabs = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: theme.customPalette.white,
    height: "50px",
    overflow: "hidden",
    overflowX: "auto",
    whiteSpace: "nowrap",
    width: "auto",
    maxWidth: "95vh",
    position: "relative",
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  };
});

export const InputAdornment = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "45px",
  };
});

export const Description = styled("div")(({ theme }) => {
  const {
    customSizes: {
      textAreaInput: {
        desktop: { placeholder },
      },
    },
    customPalette: { grey },
    customFonts: { poppinsLight },
  } = theme;

  return {
    fontSize: placeholder.fontSize,
    fontWeight: placeholder.fontWeight,
    lineHeight: placeholder.lineHeight,
    color: grey,
    fontFamily: poppinsLight,
  };
});

export const GroupContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    backgroundColor: theme.customPalette.white,
    height: "100vh",
    width: "100%",
    padding: "16px 16px 16px 8px",
  };
});

export const IconContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    paddingLeft: "16px",
    cursor: "pointer",
    ":hover": {
      path: {
        stroke: theme.customPalette.secondary,
      },
    },
  };
});

export const ButtonContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    gap: "16px",
    paddingRight: "48px",
    marginLeft: "8px",
  };
});
