import styled from "styled-components";
import { Select, MenuItem } from "@mui/material";


export const Container = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "16px",
  };
});

export const TitleContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
  };
});

export const InfoContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    paddingTop: "8px",
  };
});

export const SelectInputContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    position: "relative",
    justifyContent: "flex-end",
    width: "320px",
  };
});

interface SelectInputProps {
  menuOpen: boolean;
}

export const SelectInput = styled(Select)<SelectInputProps>(
  ({ theme, menuOpen }) => {
    const {
      customFonts: { poppins },
      customSizes: {
        textAreaInput: {
          desktop: { placeholder: textDesktop },
          mobile: { placeholder: textMobile },
        },
      },
      customPalette: {
        textAreaInput: { border, borderHover, activeText },
      },
    } = theme;

    return {
      fontFamily: `${poppins} !important`,
      fontWeight: textDesktop.fontWeight,
      color: activeText,
      border: `2px solid ${border}`,
      borderBottom: menuOpen
        ? `2px solid ${theme.customPalette.white}`
        : `2px solid ${border}`,
      borderRadius: menuOpen ? "8px 8px 0px 0px" : "0px",
      maxWidth: "556px",
      width: "100%",
      height: "48px",
      margin: "5px 0px 25px 0px",
      ":hover": {
        borderColor: borderHover,
      },
      "&:focus": {
        borderColor: borderHover,
      },
      ":active": {
        borderColor: borderHover,
        borderBottom: `2px solid ${theme.customPalette.white}`,
      },
      ":focus-visible": {
        outline: 0,
      },
      "&.Mui-focused": {
        borderColor: borderHover,
      },
      "&.MuiInputBase-root": {
        borderRadius: "8px"
      },
      fieldset: {
        borderColor: "transparent !important",
      },
      [theme.breakpoints.down(768)]: {
        fontSize: textMobile.fontSize,
        fontWeight: textMobile.fontWeight,
        lineHeight: textMobile.lineHeight,
      },
    };
  }
);

export const Item = styled(MenuItem)(({ theme }) => {
  const {
    customFonts: { poppins },
    customSizes: {
      textAreaInput: {
        desktop: { placeholder: textDesktop },
        mobile: { placeholder: textMobile },
      },
    },
    customPalette: {
      disabled,
      secondary,
      textAreaInput: { activeText, border },
    },
  } = theme;

  return {
    fontFamily: poppins,
    fontWeight: textDesktop.fontWeight,
    color: `${activeText} !important`,
    margin: "4px",
    fontSize: textDesktop.fontSize,
    "&.Mui-selected": {
      backgroundColor: `${disabled} !important`,
      color: secondary,
      fontWeight: 700,
    },
    [theme.breakpoints.down(768)]: {
      fontSize: textMobile.fontSize,
      fontWeight: textMobile.fontWeight,
      lineHeight: textMobile.lineHeight,
    },
  };
});

export const Menu = styled("div")(({ theme }) => {
  const {
    customPalette: {
      textAreaInput: { border },
    },
  } = theme;
  return {
    boxShadow: "none",
    borderRadius: "0px",
    border: `2px solid ${border}`,
    borderTop: `2px solid ${theme.customPalette.white}`,
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
  };
});

export const DropdownContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  gap: "16px",
});

export const Input = styled("div")(({ theme }) => {
  const {
    customFonts: { poppins },
    customSizes: {
      textAreaInput: {
        desktop: { placeholder: textDesktop },
        mobile: { placeholder: textMobile },
      },
    },
    customPalette: {
      textAreaInput: { activeText, placeholder, borderHover },
    },
  } = theme;

  return {
    fontFamily: `${poppins} !important`,
    fontWeight: textDesktop.fontWeight,
    fontSize: textDesktop.fontSize,
    color: activeText,
    "&.placeholder": {
      color: placeholder,
    },

    "&:hover": {
      borderColor: borderHover,
    },
    "&:focus": {
      borderColor: borderHover,
    },

    [theme.breakpoints.down(768)]: {
      fontSize: textMobile.fontSize,
      fontWeight: textMobile.fontWeight,
      lineHeight: textMobile.lineHeight,
    },
  };
});


