import { AxiosResponse } from "axios";

import api from "../../../api";
import { setApiError, clearApiError } from "../../../redux/slices/companySlice";
import store from "../../../redux/store";
import { updateQuestionGroup } from "redux/slices/surveySlice";

const { dispatch } = store;

interface BodyData {
  questionGroupId: number;
  nextGroupId: number;
}

export const postPathRule = async (questionGroup, body: BodyData) => {
  await api
    .post(`/path-rules`, body)
    .then((res: AxiosResponse) => {
      dispatch(clearApiError());
      const updatedGroup = { ...questionGroup, pathRule: res?.data };
      dispatch(updateQuestionGroup(updatedGroup));
    })
    .catch((error) => {
      console.log("[postPathRule error]: ", error);
      dispatch(setApiError({ error: true }));
    });
};
