import styled from "styled-components";

export const Container = styled("div")<{}>({
  gap: "8px",
  justifyContent: "space-between",
  width: "100%",
  zIndex: 1000,
});

interface Props {
  isVisible?: boolean;
}

export const LabelContainer = styled("div")<Props>(({ theme, isVisible }) => {
  return {
    display: "flex",
    width: "100%",
    height: "48px",
    color: theme.customPalette.textField.default.text,
    border: `2px solid ${
      isVisible
        ? theme.customPalette.hover
        : theme.customPalette.textField.default.border
    }`,
    borderRadius: "7px",
    alignItems: "center",
    padding: "16px",
    justifyContent: "space-between",
    cursor: "pointer",
    ":hover": {
      border: `2px solid ${theme.customPalette.hover}`,
    },
  };
});

export const DatePickerContainer = styled("div")(({ theme }) => {
  return {
    position: "absolute",
    boxShadow: theme.customPalette.card.boxShadow,
    cursor: "pointer",
  };
});

export const DateLabel = styled("label")<{ placeholder?: boolean }>(
  ({ theme, placeholder }) => {
    const {
      customSizes: {
        body: {
          smallDescription: {
            regular: { fontSize, fontWeight, lineHeight },
          },
        },
      },
    } = theme;

    return {
      fontFamily: theme.customFonts.poppins,
      fontSize,
      fontWeight,
      lineHeight,
      color: placeholder
        ? theme.customPalette.grey
        : theme.customPalette.primary,
      cursor: "pointer",
    };
  }
);
