import styled from "styled-components";

export const PreviewTitle = styled("h2")(({ theme }) => {
  const {
    customSizes: {
      body: {
        title2: { semibold },
      },
    },
    customPalette: { primary },
    customFonts: { poppinsSemiBold },
  } = theme;

  return {
    fontSize: semibold.fontSize,
    fontWeight: semibold.fontWeight,
    lineHeight: semibold.lineHeight,
    color: primary,
    fontFamily: poppinsSemiBold,
    margin: "0px",
  };
});

export const BottomContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "fit-content",
  gap: "32px",
});

export const PreviewContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "32px",
  width: "584px",
});

export const TopContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const Preview = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: `1px solid ${theme.customPalette.mediumLightGrey}`,
    borderRadius: "16px",
    padding: "16px",
  };
});

export const SuccessImageContainer = styled("div")({
  width: "370px",
  height: "261px",
  display: "flex",
  justifyContent: "center",
});
export const LoadingImageContainer = styled("div")({
  width: "149px",
  height: "64px",
  display: "flex",
  justifyContent: "center",
});

export const Text = styled("label")<{
  marginBottom?: boolean;
  marginTop?: boolean;
}>(({ marginBottom, marginTop, theme }) => {
  const {
    customFonts: { poppinsSemiBold },
    customSizes: {
      preview: { question },
    },
  } = theme;

  return {
    fontFamily: poppinsSemiBold,
    fontSize: question.fontSize,
    fontWeight: question.fontWeight,
    lineHeight: question.lineHeight,
    ...(marginBottom && { marginBottom: "16px" }),
    ...(marginTop && { marginTop: "16px" }),
  };
});

export const Title = styled("label")(({ theme }) => {
  const {
    customFonts: { poppinsSemiBold },
    customSizes: {
      body: {
        description: { semibold },
      },
    },
  } = theme;

  return {
    fontFamily: poppinsSemiBold,
    fontSize: semibold.fontSize,
    fontWeight: semibold.fontWeight,
    lineHeight: semibold.lineHeight,
    margin: 0,
  };
});

export const SuccessImage = styled("img")({
  maxWidth: "370px",
  maxHeight: "261px",
});

export const LoadingImage = styled("img")({
  maxWidth: "149px",
  maxHeight: "64px",
});
