import axios from "axios";

import { logout } from "../pages/Login/services/logout";
import store from "../redux/store";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const CONTENT_TYPE = {
  Accept: "application/json",
};

export const HOST = {
  Host: BASE_URL,
};

const api = axios.create({
  baseURL: HOST.Host,
  headers: CONTENT_TYPE,
});

api.interceptors.request.use((req) => {
  const {
    authSlice: { accessToken },
  } = store.getState();

  if (accessToken) {
    req.headers = {
      ...req.headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  return req;
});

api.interceptors.response.use(
  (res) => res,
  (error) => {
    if (
      error?.response?.status === 401 &&
      error?.config?.url !== "auth/sign-in"
    ) {
      logout();
      return (window.location.href = "/");
    }
    return Promise.reject(error);
  }
);

export const getData = async (endpoint: string, options = {}) => {
  const { data } = await api.get(endpoint, { params: options });
  return data;
};
export default api;
