import { TagContainer, Text } from "../tagsModal/styles";

type TagProps = {
  backgroundColor: string;
  textColor?: string;
  text: string;
};

const Tag = ({ backgroundColor, textColor, text }: TagProps) => {
  return (
    <TagContainer backgroundColor={backgroundColor}>
      <Text color={textColor}>{text}</Text>
    </TagContainer>
  );
};

export default Tag;
