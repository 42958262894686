import { useEffect, useMemo, useState } from "react";
import Modal from "@mui/material/Modal";

import Button from "../button";
import { DarkCross } from "../../assets/index";
import {
  ModalContainer,
  TopContainer,
  ButtonContainer,
  TitleLabel,
  Cross,
  SubtitleLabel,
  TextContainer,
  InputContainer,
  TextInputWrapper,
  StyledCopyPasteIcon,
} from "./styles";
import TextInput from "../textInput";
import { InputState } from "types";
import { EMAIL } from "utils/constants";

interface Params {
  title: string;
  subtitle?: string;
  buttonLabel?: string;
  isOpen: boolean;
  inputs: InputState[];
  hasCopyButton?: boolean;
  disabled?: boolean;
  selectedSurveyId?: number;
  handleClose: () => void;
  handleButton: () => void;
}

function validateEmail(email: string): boolean {
  const emailRegex =
    /^(?!\.)([a-zA-Z0-9._%+-]+)(?<!\.)@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;
  return emailRegex.test(email);
}

const ResponseModal = ({
  isOpen,
  handleClose,
  handleButton,
  title,
  subtitle,
  buttonLabel,
  inputs,
  selectedSurveyId,
  hasCopyButton = false,
  disabled = false,
}: Params) => {
  const [emailError, setEmailError] = useState<string | null>(null);

  useEffect(() => {
    inputs.find((input) => input.isSelect)?.setValue(selectedSurveyId);
  }, [selectedSurveyId]);

  const handleCreateButtonClick = () => {
    const emailInput = inputs.find((input) => input.label === EMAIL);
    if (emailInput && !validateEmail(emailInput.value)) {
      setEmailError("Invalid email format");
      return;
    }
    setEmailError(null);
    handleButton();
  };

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContainer>
        <TopContainer>
          <TextContainer>
            <TitleLabel>{title}</TitleLabel>
            <SubtitleLabel>{subtitle}</SubtitleLabel>
          </TextContainer>
          <Cross onClick={handleClose}>
            <DarkCross />
          </Cross>
        </TopContainer>
        {inputs.map((input) => (
          <InputContainer>
            <TextInputWrapper>
              <TextInput
                placeholder={input.placeholder}
                label={input.label}
                showErrorLabel={false}
                value={input.value}
                onChange={(event) => {
                  input.setValue(event.target.value);
                }}
                error={input.label === EMAIL && !!emailError}
                disabled={disabled || input.isSelect}
              />
              {input.label === EMAIL && emailError && (
                <span style={{ color: "red" }}>{emailError}</span>
              )}
              {hasCopyButton && <StyledCopyPasteIcon onClick={handleButton} />}
            </TextInputWrapper>
          </InputContainer>
        ))}
        {!hasCopyButton && (
          <ButtonContainer>
            <Button
              label={buttonLabel}
              variantType="primary"
              width="100%"
              height={48}
              onClick={handleCreateButtonClick}
              disabled={inputs.some((input) => input.value === "")}
            />
          </ButtonContainer>
        )}
      </ModalContainer>
    </Modal>
  );
};

export default ResponseModal;
