import { useState } from "react";

import {
  Container,
  InfoContainer,
  TitleContainer,
  DropdownContainer,
  SelectInput,
  Item,
  Input
} from "./styles";
import { useAppDispatch, useAppSelector } from "redux/store";
import { setAdminInfo } from "redux/slices/authSlice";
import SettingsDetails from "components/settingsDetails";
import {
  DATE_FORMAT_TEXT,
  DATE_FORMAT_SUBTITLE,
} from "utils/constants";
import { updateAdminInfo } from "pages/Settings/services/updateAdmin";

const DateSettings = () => {
  const dispatch = useAppDispatch();
  const { id, apiError, dateFormat } = useAppSelector(
    (state) => state.authSlice
  );
  const [selected, setSelected] = useState<string>(dateFormat || "MM/DD/YYYY");
  const [menuOpen, setMenuOpen] = useState(false);

  const handleOnChange = async (value: string) => {
    setSelected(value);
    const updateAdmin = await updateAdminInfo(id, {
      dateFormat: value,
    });
    dispatch(setAdminInfo(updateAdmin));
  };

  const handleOnClick = () => {
    setTimeout(() => {
      setMenuOpen(prev => !prev);
    }, 150)
  }

  return (
    <Container>
      <TitleContainer>
        <SettingsDetails
          title={DATE_FORMAT_TEXT}
          subtitle={DATE_FORMAT_SUBTITLE}
        />
      </TitleContainer>

      <InfoContainer>
        <DropdownContainer>
          <SelectInput
            displayEmpty
            value={selected}
            menuOpen={menuOpen}
            defaultValue={null}
            onOpen={handleOnClick}
            onClose={handleOnClick}
            onChange={(e) => handleOnChange(e.target.value as string)}
            renderValue={(selected: string) => (
              <Input className={!selected ? "placeholder" : ""}>
                {selected || "-Select-"}
              </Input>
            )}
            inputProps={{ "aria-label": "Without label" }}
          >
            {["DD/MM/YYYY", "MM/DD/YYYY"].map((option) => (
              <Item key={option} value={option as any}>
                {option}
              </Item>
            ))}
          </SelectInput>
        </DropdownContainer>
      </InfoContainer>
    </Container>
  );
};

export default DateSettings;
