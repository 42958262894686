import React, { useState } from "react";
import { useAppSelector } from "redux/store";

import {
  BOLD,
  REGULAR,
  RESET_STYLES,
  SEMI_BOLD,
  TYPOGRAPHY,
} from "../../utils/constants";
import {
  Subtitle,
  ColorContainer,
  ResetButton,
  TopContainer,
  ColorPaletteContainer,
} from "./styles";
import ColorPicker from "../colorPicker";
import { ColorPaletteOptions } from "../../utils/utils";
import { updateBranding } from "pages/Design/services/updateBranding";
import { BodyData } from "pages/Design/services/updateBranding";
import FileInput from "./fileInput";
import theme from "../../definitions/theme";

export interface TypographyProps {
  typographySetters: React.Dispatch<React.SetStateAction<boolean>>[];
  typographyStates: boolean[];
  typographyArray: ColorPaletteOptions[];
}

const Typography = ({
  typographySetters: colorSetters,
  typographyStates: colorStates,
  typographyArray,
}: TypographyProps) => {
  const [resetDefaultStyles, setResetDefaultStyles] = useState(false);
  const [resetRegularStatus, setResetRegularStatus] = useState(false);
  const [resetSemiBoldStatus, setResetSemiBoldStatus] = useState(false);
  const [resetBoldStatus, setResetBoldStatus] = useState(false);
  const company = useAppSelector((state) => state.companySlice);
  const { branding } = company;

  const updateBrandingColor = async (index: number, newColor: string) => {
    const propertyNames = [
      "primaryTextColor",
      "secondaryTextColor",
      "homePageTextColor",
    ];

    const bodyData: BodyData = {
      [propertyNames[index]]: newColor,
    };

    await updateBranding(bodyData);
  };

  const handleReset = async () => {
    setResetDefaultStyles(true);
    updateBranding({
      primaryTextColor: typographyArray[0].defaultColor,
      secondaryTextColor: typographyArray[1].defaultColor,
      homePageTextColor: typographyArray[2].defaultColor,
      regularFontFile: "",
      semiBoldFontFile: "",
      boldFontFile: "",
    });

    setResetRegularStatus(true);
    setResetBoldStatus(true);
    setResetSemiBoldStatus(true);
  };

  return (
    <ColorContainer>
      <TopContainer>
        <Subtitle>{TYPOGRAPHY}</Subtitle>
        <ResetButton onClick={handleReset}>{RESET_STYLES}</ResetButton>
      </TopContainer>
      <FileInput
        title={REGULAR}
        fontFile={branding.regularFontFile}
        resetFont={resetRegularStatus}
        fontFamily={theme.customFonts.poppins}
      />
      <FileInput
        title={SEMI_BOLD}
        fontFile={branding.semiBoldFontFile}
        resetFont={resetSemiBoldStatus}
        fontFamily={theme.customFonts.poppinsSemiBold}
        semiBold
      />
      <FileInput
        title={BOLD}
        fontFile={branding.boldFontFile}
        resetFont={resetBoldStatus}
        fontFamily={theme.customFonts.poppinsBold}
        bold
      />

      <ColorPaletteContainer>
        {typographyArray.map((option, index) => (
          <ColorPicker
            key={index}
            {...option}
            showColorBox={colorStates[index]}
            setShowColorBox={colorSetters[index]}
            resetDefaultStyles={resetDefaultStyles}
            setResetDefaultStyles={setResetDefaultStyles}
            updateBrandingColor={(newColor: string) =>
              updateBrandingColor(index, newColor)
            }
          />
        ))}
      </ColorPaletteContainer>
    </ColorContainer>
  );
};

export default Typography;
