import React, { useEffect, useState } from "react";

import { Container, InfoContainer, TitleContainer } from "./styles";
import TextInput from "components/textInput";
import { useAppDispatch, useAppSelector } from "redux/store";
import { clearApiError, setAdminInfo } from "redux/slices/authSlice";
import SettingsDetails from "components/settingsDetails";
import {
  AUTHENTICATION_TOKEN,
  CALENDLY,
  ENTER_TOKEN,
  HOST_URL,
  HTTP,
  SHOW_THE_CALENDLY_MODAL_IN_THE_HOME_PAGE,
} from "utils/constants";
import { updateAdminInfo } from "pages/Settings/services/updateAdmin";

const CalendlyData = () => {
  const dispatch = useAppDispatch();
  const [adminCalendlyURL, setCalendlyURL] = useState<string>("");
  const [adminCalendlyToken, setCalendlyToken] = useState<string>("");
  const { id, calendlyURL, calendlyToken } = useAppSelector(
    (state) => state.authSlice
  );
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    if (calendlyURL || calendlyToken) {
      setChecked(true);
      setCalendlyURL(calendlyURL);
      setCalendlyToken(calendlyToken);
    }
  }, [calendlyURL, calendlyToken]);

  const switchHandler = async () => {
    if (checked) {
      const body = {
        calendlyURL: "",
        calendlyToken: "",
      };

      const updateAdmin = await updateAdminInfo(id, body);
      dispatch(setAdminInfo(updateAdmin));
    }
    setChecked(prev => !prev)
  };

  const handleURLChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCalendlyURL(e.target.value);

  const handleTokenChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCalendlyToken(e.target.value);

  const handleBlur = async () => {
    const body = {
      calendlyURL: adminCalendlyURL,
      calendlyToken: adminCalendlyToken,
    };

    const updateAdmin = await updateAdminInfo(id, body);
    dispatch(setAdminInfo(updateAdmin));
  };

  return (
    <Container>
      <TitleContainer>
        <SettingsDetails
          title={CALENDLY}
          subtitle={SHOW_THE_CALENDLY_MODAL_IN_THE_HOME_PAGE}
          checked={checked}
          switchHandler={switchHandler}
          infoButton={true}
        />
      </TitleContainer>
      {checked && (
        <InfoContainer>
          <TextInput
            placeholder={HTTP}
            label={HOST_URL}
            value={adminCalendlyURL}
            width="556px"
            onChange={handleURLChange}
            onBlur={handleBlur}
          />
          <TextInput
            placeholder={ENTER_TOKEN}
            label={AUTHENTICATION_TOKEN}
            value={adminCalendlyToken}
            width="556px"
            onChange={handleTokenChange}
            onBlur={handleBlur}
          />
        </InfoContainer>
      )}
    </Container>
  );
};

export default CalendlyData;
