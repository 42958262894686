import styled from "styled-components";
import { MobileLogo } from "../../assets";

export const Container = styled("div")(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",

    justifyContent: "center",
    width: "100vw",
    height: "100vh",
    backgroundColor: theme.customPalette.secondary,
  };
});

export const SubContainer = styled("div")(() => {
  return {
    display: "flex",
    flexDirection: "column" as const,
    gap: 0,
    alignItems: "center",
    rowGap: "32px",
    justifyContent: "center",
  };
});

export const TextContainer = styled("div")(() => {
  return {
    display: "flex",
    flexDirection: "column" as const,
    gap: 0,
    alignItems: "center",
    justifyContent: "center",
  };
});

export const Logo = styled(MobileLogo as any)(() => {
  return {
    marginLeft: 30,
  };
});

export const Text = styled("label")(({ theme }) => {
  const {
    customSizes: {
      body: {
        smallDescription: {
          semibold: { fontSize, fontWeight, lineHeight },
        },
      },
    },
    customPalette: { white },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    color: white,
    fontFamily: poppins,
  };
});
