import React, { useState } from "react";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Tooltip from "components/tooltip";

import {
  ButtonContainer,
  HeaderContainer,
  HeaderTextContainer,
  HeaderUserContainer,
  IconContainer,
  InputAdornment,
  LeftContainer,
  RightContainer,
  StyledCopyPasteIcon,
  TagsContainer,
  Text,
  TextContainer,
  Title,
  UserInfoContainer,
} from "./styles";
import { ArrowRight, BackArrow, BlankPencilIcon } from "assets";
import {
  ANSWERS,
  CANCEL,
  CLIENT_INFORMATION,
  COMPANY,
  DOWNLOAD,
  LASTNAME,
  LINK_FOR_USER,
  NAME,
  RESPONSES,
  SAVE_EDITION,
  SELECT_TAGS,
  USER_RESPONSES,
  MISSING_CHOOSE_YOUR_FIT_ANSWER,
} from "utils/constants";
import { Button } from "components";
import CopiedToClipboardModal from "components/copiedModal";
import StyledSelect from "./select";
import useResponseTagsLogic from "../../hooks/useResponseTagsLogic";
import CategoryCard from "../../components/categoryCard";
import { useAppSelector } from "../../redux/store";

interface Props {
  responseId: number;
  name: string;
  lastname: string;
  company: string;
  pdfUrl: string;
  linkForUser: string;
  category: {
    maturity?: string;
    temperature?: string;
    techsTags?: string[];
    id?: number;
  };
  bifurcateAnswer?: boolean;
  deleted_at?: string;
}

const Header = ({
  responseId,
  name,
  lastname,
  company,
  pdfUrl,
  linkForUser,
  category,
  bifurcateAnswer,
  deleted_at,
}: Props) => {
  const navigate = useNavigate();
  const { isAIActive } = useAppSelector((state) => state.companySlice);
  const [isCopyLink, setIsCopyLink] = useState(false);
  const {
    isEditing,
    setIsEditing,
    selectedMaturityTag,
    selectedTechTags,
    selectedTemperatureTag,
    handleCancel,
    handleMaturityTagClick,
    handleTechTagClick,
    handleTemperatureTagClick,
    handleSaveChanges,
    noTagsSelected,
  } = useResponseTagsLogic({
    responseId,
    maturity: category?.maturity || "",
    temperature: category?.temperature || "",
    techsTags: category?.techsTags || [],
    categoryId: category?.id,
  });

  const downloadResponsesPDF = () => {
    if (pdfUrl)
      fetch(pdfUrl).then((response) => {
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          let link = document.createElement("a");
          link.href = fileURL;
          link.download = `${company} - ${USER_RESPONSES}`;
          link.click();
        });
      });
  };

  const handleCopyLink = () => {
    if (!bifurcateAnswer) return;
    navigator.clipboard.writeText(linkForUser);
    setIsCopyLink(true);
    setTimeout(() => {
      setIsCopyLink(false);
    }, 2000);
  };

  const [mousePosition, setMousePosition] = useState({ left: 0, top: 0 });
  const [isTooltipModalOpen, setIsTooltipModalOpen] = useState(false);

  const handleMouseOver = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    const mouseX = event.clientX;
    const mouseY = event.clientY;
    setMousePosition({ left: mouseX, top: mouseY });

    setIsTooltipModalOpen(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipModalOpen(false);
  };

  return (
    <HeaderContainer>
      <LeftContainer>
        <HeaderTextContainer>
          <InputAdornment>
            <IconButton onClick={() => navigate("/responses")}>
              <BackArrow />
            </IconButton>
          </InputAdornment>
          <Text>{RESPONSES}</Text>
          <InputAdornment>
            <ArrowRight />
          </InputAdornment>
          <Text className="bold">{ANSWERS}</Text>
        </HeaderTextContainer>
        <HeaderUserContainer>
          <Title>{CLIENT_INFORMATION}</Title>
          <UserInfoContainer>
            <TextContainer>
              <Text className="bold">{NAME}:</Text>
              <Text>{name}</Text>
            </TextContainer>
            <TextContainer>
              <Text className="bold">{LASTNAME}</Text>
              <Text>{lastname}</Text>
            </TextContainer>
            <TextContainer>
              <Text className="bold">{COMPANY}</Text>
              <Text>{company}</Text>
            </TextContainer>
          </UserInfoContainer>
          {isAIActive && (
            <TagsContainer>
              {isEditing ? (
                <StyledSelect
                  selectedTemperatureTag={selectedTemperatureTag}
                  handleTemperatureTagClick={handleTemperatureTagClick}
                  selectedMaturityTag={selectedMaturityTag}
                  handleMaturityTagClick={handleMaturityTagClick}
                  selectedTechTags={selectedTechTags}
                  handleTechTagClick={handleTechTagClick}
                />
              ) : (
                <>
                  {noTagsSelected && <Text>{SELECT_TAGS}</Text>}
                  <CategoryCard text={selectedMaturityTag} />
                  <CategoryCard text={selectedTemperatureTag} />
                  {selectedTechTags?.length > 0 &&
                    selectedTechTags?.map((techTag) => (
                      <CategoryCard key={techTag} text={techTag} />
                    ))}
                </>
              )}
              {isEditing ? (
                <ButtonContainer>
                  <Button
                    label={CANCEL}
                    variantType="secondary"
                    width={140}
                    height={40}
                    onClick={handleCancel}
                  />
                  <Button
                    label={SAVE_EDITION}
                    variantType="primary"
                    width={140}
                    height={40}
                    onClick={handleSaveChanges}
                  />
                </ButtonContainer>
              ) : (
                <IconContainer>
                  <InputAdornment onClick={() => setIsEditing(true)}>
                    <BlankPencilIcon />
                  </InputAdornment>
                </IconContainer>
              )}
            </TagsContainer>
          )}
          <UserInfoContainer>
            <TextContainer>
              {!deleted_at && !!bifurcateAnswer && (
                <>
                  <Text className="bold">{LINK_FOR_USER}</Text>
                  <StyledCopyPasteIcon
                    onClick={handleCopyLink}
                    onMouseOver={handleMouseOver}
                    onMouseLeave={handleMouseLeave}
                  />
                </>
              )}
              {!bifurcateAnswer && isTooltipModalOpen && (
                <Tooltip
                  text={MISSING_CHOOSE_YOUR_FIT_ANSWER}
                  position={mousePosition}
                />
              )}
            </TextContainer>
          </UserInfoContainer>
        </HeaderUserContainer>
      </LeftContainer>
      <RightContainer>
        <Button
          label={DOWNLOAD}
          variantType="primary"
          width={227}
          height={48}
          onClick={downloadResponsesPDF}
          disabled={!pdfUrl}
        />
      </RightContainer>
      {isCopyLink && <CopiedToClipboardModal />}
    </HeaderContainer>
  );
};

export default Header;
