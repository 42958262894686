import React from "react";
import { DraggableProvided } from "react-beautiful-dnd";

import QuestionCard from "../questionCard";
import TextInput from "../textInput";
import { InputContainer } from "./styles";
import useCardLogic, { DataType } from "../../hooks/useCardLogic";
import { QuestionState } from "../../types";
import { ENTER_YOUR_TEXT_HERE } from "../../utils/constants";

interface LongTextCardProps {
  provided: DraggableProvided;
  id: number;
  questionGroupId: number;
  data: DataType;
  setQuestions: (value: React.SetStateAction<QuestionState[]>) => void;
  lastIndex: number;
}

const LongTextCard = ({
  provided,
  id,
  questionGroupId,
  data,
  setQuestions,
  lastIndex,
}: LongTextCardProps) => {
  const {
    title,
    onChangeTitle,
    subtitle,
    onChangeSubtitle,
    checked,
    switchHandler,
    newData,
  } = useCardLogic({
    id,
    initialData: data,
    hasOptions: false,
    setQuestions,
  });

  return (
    <QuestionCard
      provided={provided}
      id={id}
      questionGroupId={questionGroupId}
      addButton={false}
      titleValue={title}
      onChangeTitle={onChangeTitle}
      subtitleValue={subtitle}
      onChangeSubtitle={onChangeSubtitle}
      checkedSwitch={checked}
      switchHandler={switchHandler}
      data={newData}
      lastIndex={lastIndex}
    >
      <InputContainer>
        <TextInput
          placeholder={ENTER_YOUR_TEXT_HERE}
          showErrorLabel={false}
          multiline={true}
          className="multiline"
          disabled
        />
      </InputContainer>
    </QuestionCard>
  );
};

export default LongTextCard;
