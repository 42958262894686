import { useState, ChangeEvent, useEffect } from "react";
import { useDebounce } from "use-debounce";

import { updateQuestionGroup } from "../pages/SurveyDetails/services/updateQuestionGroup";
import { updateQuestionGroup as updateQuestionGroupSlice } from "../redux/slices/surveySlice";
import { QuestionGroupState } from "../types";
import { useAppDispatch } from "../redux/store";

interface CardLogicHookProps {
  id: number;
  initialData: QuestionGroupState;
  currentQuestionGroupChanged: boolean;
  setCurrentQuestionGroupChanged: (
    value: React.SetStateAction<boolean>
  ) => void;
  surveyId: number;
}

const useQuestionGroupCardLogic = ({
  id,
  initialData,
  currentQuestionGroupChanged,
  setCurrentQuestionGroupChanged,
  surveyId,
}: CardLogicHookProps) => {
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState<string>(initialData?.title || "");
  const [subtitle, setSubtitle] = useState<string>(initialData?.subtitle || "");
  const [debouncedTitle] = useDebounce(title, 2000);
  const [debouncedSubtitle] = useDebounce(subtitle, 2000);
  const [firstRender, setFirstRender] = useState<boolean>(false);

  const onChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
    setCurrentQuestionGroupChanged(false);
  };

  const onChangeSubtitle = (event: ChangeEvent<HTMLInputElement>) => {
    setSubtitle(event.target.value);
    setCurrentQuestionGroupChanged(false);
  };

  const updateQuestionGroupCall = async () => {
    const updatedQuestionGroupData = await updateQuestionGroup(id, {
      title,
      subtitle,
    });

    delete updatedQuestionGroupData.id;
    dispatch(
      updateQuestionGroupSlice({
        surveyId,
        questionGroup: {
          ...initialData,
          title: updatedQuestionGroupData.title,
          subtitle: updatedQuestionGroupData.subtitle,
        },
      })
    );
  };

  useEffect(() => {
    const hasChanged =
      debouncedTitle !== initialData?.title ||
      debouncedSubtitle !== initialData?.subtitle;

    if (hasChanged && firstRender && !currentQuestionGroupChanged)
      updateQuestionGroupCall();
  }, [debouncedTitle, debouncedSubtitle]);

  useEffect(() => {
    setFirstRender(true);
  }, []);

  useEffect(() => {
    if (firstRender && initialData) {
      setTitle(initialData.title);
      setSubtitle(initialData.subtitle);
    }
  }, [initialData?.title, initialData?.subtitle]);

  useEffect(() => {
    setTitle(initialData.title);
    setSubtitle(initialData.subtitle);
  }, [initialData]);

  return {
    title,
    subtitle,
    debouncedTitle,
    debouncedSubtitle,
    onChangeTitle,
    onChangeSubtitle,
  };
};

export default useQuestionGroupCardLogic;
