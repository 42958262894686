import React, {
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Collapse, IconButton } from "@mui/material";
import { DraggableProvided } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";

import {
  Container,
  Footer,
  Text,
  SwitchStyled,
  LinkContainer,
  Content,
  IconContainer,
  RowIconContainer,
  RowContainer,
  MenuStyled,
  MobileMenuItem,
  IconButtonStyled,
  ButtonsContainer,
  ExpandMoreButton,
  RowLeftContainer,
} from "./styles";
import {
  AddItem,
  CopyIcon,
  DragIcon,
  ExpandArrow,
  TrashIcon,
  VerticalDotsIcon,
} from "../../assets";
import TextInput from "../textInput";
import {
  ADD_NEW_OPTION,
  DELETE,
  DUPLICATE,
  REQUIRED,
  TYPE_YOUR_QUESTION,
  TYPE_YOUR_QUESTION_SUBTITLE,
} from "../../utils/constants";
import { deleteQuestion } from "../../pages/SurveyDetails/services/deleteQuestion";
import { getQuestionGroup } from "../../pages/SurveyDetails/services/getQuestionGroup";
import { createQuestion } from "../../pages/SurveyDetails/services/createQuestion";
import { getQuestionTypeData } from "../../utils/utils";
import { DataType } from "../../hooks/useCardLogic";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  setQuestionCreated,
  setQuestionDeleted,
} from "../../redux/slices/surveySlice";

interface QuestionCardProps extends PropsWithChildren {
  provided?: DraggableProvided;
  id: number;
  questionGroupId: number;
  titleValue?: string;
  onChangeTitle?: React.ChangeEventHandler<HTMLInputElement>;
  subtitleValue?: string;
  onChangeSubtitle?: React.ChangeEventHandler<HTMLInputElement>;
  addButton?: boolean;
  onClickAddButton?: () => void;
  checkedSwitch?: boolean;
  switchHandler?: (event: any) => void;
  addButtonText?: string;
  data: DataType;
  lastIndex: number;
}

const QuestionCard = ({
  provided,
  id,
  questionGroupId,
  children,
  titleValue,
  subtitleValue,
  onChangeTitle,
  onChangeSubtitle,
  onClickAddButton,
  checkedSwitch,
  switchHandler,
  addButton = true,
  addButtonText,
  data,
  lastIndex,
}: QuestionCardProps) => {
  const { id: surveyParamId } = useParams();
  const surveyId = useMemo(() => Number(surveyParamId), [surveyParamId]);
  const dispatch = useAppDispatch();
  const { surveys } = useAppSelector((state) => state.surveySlice);
  const survey = useMemo(
    () => surveys.find((s) => s.id === surveyId),
    [surveys, surveyId]
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [expanded, setExpanded] = useState<boolean>(true);
  const open = Boolean(anchorEl);
  const lastQuestionRef = useRef<HTMLDivElement>(null);

  const { Icon } = useMemo(() => getQuestionTypeData(data.type), [data.type]);

  const handleExpandClick = () => setExpanded(!expanded);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const onClickDeleteButton = async () => {
    await deleteQuestion(id);
    dispatch(setQuestionDeleted({ surveyId, deleted: true }));
    getQuestionGroup(questionGroupId, surveyId);

    handleClose();
  };

  const onClickDuplicateButton = async () => {
    let optionsToDuplicate = [];
    if (!!data?.questionOptions?.length)
      optionsToDuplicate = data.questionOptions.map(
        ({ id, ...option }) => option
      );

    await createQuestion({
      ...data,
      questionGroupId,
      questionOptions: optionsToDuplicate,
      index: lastIndex + 1,
    });
    getQuestionGroup(questionGroupId, surveyId);
    dispatch(setQuestionCreated({ surveyId, created: true }));

    handleClose();
  };

  useEffect(() => {
    if (survey.questionCreated && lastQuestionRef?.current) {
      lastQuestionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
      dispatch(setQuestionCreated({ surveyId, created: false }));
    }
  }, []);

  return (
    <Container {...provided.draggableProps} ref={provided.innerRef}>
      <RowContainer>
        <RowLeftContainer>
          <IconContainer {...provided.dragHandleProps}>
            <DragIcon />
          </IconContainer>
          <IconContainer className="question-icon">
            <Icon />
          </IconContainer>
          <TextInput
            onChange={onChangeTitle}
            value={titleValue}
            placeholder={TYPE_YOUR_QUESTION}
            className="simple"
            showErrorLabel={false}
          />
        </RowLeftContainer>
        <RowIconContainer>
          <IconButton onClick={handleClick}>
            <VerticalDotsIcon />
          </IconButton>
          <ExpandMoreButton expand={expanded} onClick={handleExpandClick}>
            <ExpandArrow />
          </ExpandMoreButton>
        </RowIconContainer>
      </RowContainer>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Content className="subtitle">
          <TextInput
            onChange={onChangeSubtitle}
            value={subtitleValue}
            placeholder={TYPE_YOUR_QUESTION_SUBTITLE}
            className="subtitle"
            showErrorLabel={false}
            maxWidth={680}
            width="100%"
          />
        </Content>
        <Content>{children}</Content>
        <Footer className={addButton ? "add-button" : ""}>
          {addButton && (
            <LinkContainer onClick={onClickAddButton}>
              <AddItem />
              <Text className="link">{addButtonText || ADD_NEW_OPTION}</Text>
            </LinkContainer>
          )}
          <RowContainer ref={lastQuestionRef}>
            <SwitchStyled
              focusVisibleClassName=".Mui-focusVisible"
              disableRipple
              sx={{ m: 1 }}
              checked={checkedSwitch}
              onChange={switchHandler}
            />
            <Text className="footer">{REQUIRED}</Text>
          </RowContainer>
        </Footer>
      </Collapse>
      <MenuStyled
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        <ButtonsContainer>
          <MobileMenuItem className="stroke" onClick={onClickDuplicateButton}>
            <IconButtonStyled className="stroke">
              <CopyIcon />
            </IconButtonStyled>
            {DUPLICATE}
          </MobileMenuItem>
          <MobileMenuItem className="fill" onClick={onClickDeleteButton}>
            <IconButtonStyled className="fill">
              <TrashIcon />
            </IconButtonStyled>
            {DELETE}
          </MobileMenuItem>
        </ButtonsContainer>
      </MenuStyled>
    </Container>
  );
};

export default QuestionCard;
