import PageWrapper from "components/pageWrapper";
import { Container, Line, Title } from "./styles";
import { SETTINGS } from "utils/constants";
import NdaData from "components/ndaData";
import CalendlyData from "components/calendlyData";
import PdfData from "components/pdfData";
import AiData from "components/aiData";
import DateSettings from "components/dateSettings";

const Settings = () => {
  return (
    <PageWrapper>
      <Container>
        <Title>{SETTINGS}</Title>
        <PdfData />
        <Line />
        <DateSettings />
        <Line />
        <CalendlyData />
        <Line />
        <NdaData />
        <Line />
        <AiData />
      </Container>
    </PageWrapper>
  );
};

export default Settings;
