import styled from "styled-components";

export const Container = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignSelf: "flex-end",
  alignItems: "center",
  gap: "8px",
  paddingTop: "24px",
  paddingBottom: "24px",
});

interface ButtonProps {
  cursor: string;
}
export const ArrowButton = styled("button")<ButtonProps>(({ cursor }) => {
  return {
    border: "none",
    background: "none",
    cursor,
  };
});

interface PageProps {
  currentPage: boolean;
}

export const Page = styled("button")<PageProps>(({ currentPage, theme }) => {
  return {
    width: "32px",
    height: "32px",
    background: "none",
    border: `1px solid 
            ${
              currentPage
                ? theme.customPalette.secondary
                : theme.customPalette.pageBorder
            }`,
    borderRadius: "4px",
    margin: "0 4px",
    fontWeight: currentPage ? "700" : "400",
    fontFamily: theme.customFonts.poppins,
    fontSize: "14px",
    lineHeight: "20px",
    color: currentPage
      ? theme.customPalette.secondary
      : theme.customPalette.pageColor,
    cursor: "pointer",
  };
});
