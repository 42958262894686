import { ChangeEvent, useEffect, useState } from "react";
import { IconButton } from "@mui/material";

import {
  RowContainer,
  Container,
  Icon,
  Input,
  LabelInput,
  IconContainer,
  Label,
} from "./styles";
import { TrashIcon } from "../../assets";
import { OPTION_PLACEHOLDER } from "../../utils/constants";

interface NumericInputProps {
  labelValue?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>, idToChange: number) => void;
  id?: number;
  onClickDeleteIcon?: (idToDelete: number) => void;
  disabled?: boolean;
  editable?: boolean;
  onNumberChange?: (value: string) => void;
  initialValue?: number
}

const NumericInput = ({
  labelValue,
  onChange,
  id,
  onClickDeleteIcon = () => {},
  disabled = false,
  editable = false,
  onNumberChange,
  initialValue = 0,
}: NumericInputProps) => {
  const [number, setNumber] = useState<number>(initialValue);

  useEffect(() => {
    setNumber(initialValue);
  }, [initialValue]);

  const handleChange = (value: number) => {
    if (onNumberChange) onNumberChange(value.toString());
    setNumber(value);
  };

  return (
    <RowContainer>
      {editable ? (
        <LabelInput
          type="text"
          value={labelValue}
          onChange={(e) => onChange(e, id)}
          placeholder={OPTION_PLACEHOLDER}
        />
      ) : (
        <Label>{labelValue}</Label>
      )}
      <Container>
        <Icon
          onClick={() => handleChange(number - 1)}
          disabled={disabled || number === 0}
        >
          -
        </Icon>
        <Input
          value={number}
          onChange={(e) => handleChange(Number(e.target.value))}
          type="text"
          inputMode="numeric"
          className={number === 0 ? "disabled" : ""}
          disabled={disabled}
        />
        <Icon onClick={() => handleChange(number + 1)} disabled={disabled} plus>
          +
        </Icon>
      </Container>
      {editable && (
        <IconContainer>
          <IconButton onClick={() => onClickDeleteIcon(id)}>
            <TrashIcon />
          </IconButton>
        </IconContainer>
      )}
    </RowContainer>
  );
};
export default NumericInput;
