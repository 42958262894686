import React from "react";

import { Container, Text } from "./styles";

type InfoModalProps = {
  text: string;
  position?: { left: number; top: number };
};

const Tooltip = ({ text, position }: InfoModalProps) => (
  <Container text={text} left={position.left - 32} top={position.top + 32}>
    <Text>{text}</Text>
  </Container>
);

export default Tooltip;
