import React, { useRef, useEffect, useState } from "react";

import { postNda } from "pages/Settings/services/postNda";
import { NdaState } from "types";
import { deleteNda } from "pages/Settings/services/deleteNda";
import { setCompany, setNda, clearApiError } from "redux/slices/companySlice";
import {
  Container,
  InputAdornment,
  LabelContainer,
  FileUploadContainer,
  FileUploadInput,
  FileUploadLabel,
  UploadLinkText,
  TextContainer,
  AclarationText,
  UploadedFileContainer,
  InfoContainer,
  MessageContainer,
  LeftContainer,
} from "./styles";
import TextInput from "components/textInput";
import { updateAdminInfo } from "pages/Settings/services/updateAdmin";
import { useAppDispatch, useAppSelector } from "redux/store";
import {
  setAdminInfo,
  clearApiError as clearAuthApiError,
} from "redux/slices/authSlice";
import { DragFile, SuccessfulIcon } from "assets";
import {
  ONLY_PNG_JPG_FILES,
  DRAG_AND_DROP,
  UPLOAD_YOUR_SIGNATURE,
  ENTER_YOUR_NAME,
  NAME,
  ENTER_YOUR_LAST_NAME,
  LAST_NAME,
  COMPANY_NAME,
  ENTER_YOUR_COMPANY_NAME,
  SIGNATURE_SVG,
  NDA,
  SHOW_AND_SEND_STANDARD_NDA,
} from "utils/constants";
import useUploadStatus from "hooks/useUploadStatus";
import {
  Loader,
  UploadFileMessage,
  Text,
  ContentContainer,
  ErrorMessage,
} from "components/brandLogo/styles";
import { CrossIcon, PDFLogo, GreyCrossIcon } from "assets";
import SettingsDetails from "components/settingsDetails";
import { updateCompanyInfo } from "pages/Settings/services/updateCompany";
import ErrorModal from "../errorModal";

const NdaData = () => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [adminName, setAdminName] = useState("");
  const [adminSurname, setAdminSurname] = useState("");
  const [adminCompanyName, setAdminCompanyName] = useState("");
  const [checked, setChecked] = useState(false);
  const {
    id: adminId,
    name,
    surname,
    signature,
    apiError: authApiError,
  } = useAppSelector((state) => state.authSlice);
  const { id: companyId, apiError } = useAppSelector(
    (state) => state.companySlice
  );
  const fileInputRef = useRef<HTMLInputElement>(null);
  const {
    uploadStatus,
    isSuccessful,
    errorMessage,
    uploadedFile,
    resetUploadStatus,
    setUploadedFile,
    handleRemoveSignature,
    handleSignatureUpload,
  } = useUploadStatus();
  const dispatch = useAppDispatch();
  const { nda, name: companyName } = useAppSelector(
    (state) => state.companySlice
  );

  useEffect(() => {
    setChecked(!!nda?.content);
  }, [nda]);

  const handleLinkClick = () => {
    if (fileInputRef.current) fileInputRef.current?.click();
  };

  const switchHandler = () => {
    setChecked(!checked);
    if (!nda?.content && !checked) {
      postNda({ content: "NDA" }).then((res: NdaState) => {
        dispatch(setNda(res));
      });
    } else if (!!nda?.content && checked) {
      deleteNda().then(() => {
        dispatch(setNda(null));
      });
    }
  };

  useEffect(() => {
    setAdminName(name);
    setAdminSurname(surname);
    setAdminCompanyName(companyName);
    if (signature) {
      fetch(signature)
        .then((response) => response.blob())
        .then((blob) => {
          const fileName = SIGNATURE_SVG;
          const file = new File([blob], fileName, { type: blob.type });
          setUploadedFile(file);
        })
        .catch((error) => console.error("Error loading file:", error));
    }
  }, [name, surname, companyName, signature, setUploadedFile]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAdminName(e.target.value);
  };

  const handleSurnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAdminSurname(e.target.value);
  };

  const handleCompanyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAdminCompanyName(e.target.value);
  };

  const handleBlur = async () => {
    const body = {
      name: adminName,
      surname: adminSurname,
    };

    const updateAdmin = await updateAdminInfo(adminId, body);
    dispatch(setAdminInfo(updateAdmin));
  };

  const handleBlurCompanyName = async () => {
    const body = {
      name: adminCompanyName,
    };

    const updateCompanyName = await updateCompanyInfo(companyId, body);
    dispatch(setCompany(updateCompanyName));
  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const handleDragEnter = (event: React.DragEvent) => {
    event.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (event: React.DragEvent) => {
    event.preventDefault();
    setIsDraggingOver(false);
  };

  const handleFileDrop = async (event: React.DragEvent) => {
    event.preventDefault();
    const file = event.dataTransfer.files ? event.dataTransfer.files[0] : null;
    handleSignatureUpload(file);
  };

  const handleFileInput = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files ? event.target.files[0] : null;
    handleSignatureUpload(file);
  };

  useEffect(() => {
    if (isSuccessful) setTimeout(resetUploadStatus, 2000);
  }, [isSuccessful]);

  return (
    <Container>
      <SettingsDetails
        title={NDA}
        subtitle={SHOW_AND_SEND_STANDARD_NDA}
        checked={checked}
        switchHandler={switchHandler}
      />
      {checked && (
        <InfoContainer>
          <TextInput
            placeholder={ENTER_YOUR_NAME}
            label={NAME}
            value={adminName}
            width="556px"
            onChange={handleNameChange}
            onBlur={handleBlur}
          />
          <TextInput
            placeholder={ENTER_YOUR_LAST_NAME}
            label={LAST_NAME}
            value={adminSurname}
            width="556px"
            onChange={handleSurnameChange}
            onBlur={handleBlur}
          />
          <TextInput
            placeholder={ENTER_YOUR_COMPANY_NAME}
            label={COMPANY_NAME}
            value={adminCompanyName}
            width="556px"
            onChange={handleCompanyNameChange}
            onBlur={handleBlurCompanyName}
          />
          <FileUploadContainer
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleFileDrop}
            className={isDraggingOver ? "dragging" : ""}
          >
            {uploadedFile ? (
              <UploadedFileContainer>
                <ContentContainer>
                  <PDFLogo />
                  <TextContainer>
                    <Text>{uploadedFile.name}</Text>
                    <AclarationText>
                      {uploadedFile.type.split("/")[1]} - {uploadedFile.size}
                    </AclarationText>
                  </TextContainer>
                </ContentContainer>
                <InputAdornment
                  onClick={handleRemoveSignature}
                  className="pointer"
                >
                  <GreyCrossIcon />
                </InputAdornment>
              </UploadedFileContainer>
            ) : (
              <>
                <InputAdornment>
                  <DragFile />
                </InputAdornment>
                <LabelContainer>
                  <FileUploadLabel htmlFor="fileUpload">
                    {DRAG_AND_DROP}
                    <UploadLinkText onClick={handleLinkClick}>
                      {UPLOAD_YOUR_SIGNATURE}
                    </UploadLinkText>
                    <FileUploadInput
                      id="file-upload"
                      type="file"
                      onChange={handleFileInput}
                      ref={fileInputRef}
                    />
                  </FileUploadLabel>
                </LabelContainer>
                <TextContainer>
                  <AclarationText>{ONLY_PNG_JPG_FILES}</AclarationText>
                </TextContainer>
              </>
            )}
          </FileUploadContainer>
          {uploadStatus && (
            <MessageContainer>
              <LeftContainer>
                {!isSuccessful && <Loader />}
                {isSuccessful && (
                  <InputAdornment>
                    <SuccessfulIcon />
                  </InputAdornment>
                )}
                <UploadFileMessage>{uploadStatus}</UploadFileMessage>
              </LeftContainer>
              {isSuccessful && (
                <InputAdornment onClick={resetUploadStatus} className="pointer">
                  <CrossIcon />
                </InputAdornment>
              )}
            </MessageContainer>
          )}
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </InfoContainer>
      )}
      {(apiError || authApiError) && (
        <ErrorModal
          isOpen
          handleClose={() => {
            if (apiError) dispatch(clearApiError());
            if (authApiError) dispatch(clearAuthApiError());
          }}
          handleTryAgain={() => {
            if (apiError) switchHandler();
            if (authApiError) handleBlur();
          }}
        />
      )}
    </Container>
  );
};

export default NdaData;
