import styled from "styled-components";

export const TagsContainer = styled("div")(({}) => {
  return {
    display: "flex",
    width: "100%",
    marginTop: "8px",
    flexWrap: "wrap",
  };
});

export const InputContainer = styled("div")(() => {
  return {
    display: "flex",
    width: "100%",
  };
});
