import React from "react";
import { DraggableProvided } from "react-beautiful-dnd";

import QuestionCard from "components/questionCard";
import Checkbox from "components/checkbox";
import { QuestionState } from "../../types";
import useCardLogic, { DataType } from "../../hooks/useCardLogic";

interface CheckboxProps {
  provided: DraggableProvided;
  id?: number;
  questionGroupId: number;
  data: DataType;
  setQuestions: (value: React.SetStateAction<QuestionState[]>) => void;
  lastIndex: number;
}

const CheckoutButton = ({
  provided,
  id,
  questionGroupId,
  data,
  setQuestions,
  lastIndex,
}: CheckboxProps) => {
  const {
    title,
    onChangeTitle,
    subtitle,
    onChangeSubtitle,
    onClickAddButton,
    checked,
    switchHandler,
    onChangeLabel,
    onClickDeleteButton,
    options,
    newData,
  } = useCardLogic({ id, initialData: data, setQuestions });

  return (
    <QuestionCard
      provided={provided}
      id={id}
      questionGroupId={questionGroupId}
      titleValue={title}
      onChangeTitle={onChangeTitle}
      subtitleValue={subtitle}
      onChangeSubtitle={onChangeSubtitle}
      onClickAddButton={onClickAddButton}
      checkedSwitch={checked}
      switchHandler={switchHandler}
      data={newData}
      lastIndex={lastIndex}
    >
      {options?.map(({ optionText, id }) => (
        <Checkbox
          key={id}
          id={id}
          labelValue={optionText}
          onChange={onChangeLabel}
          onClickDeleteIcon={onClickDeleteButton}
          className="disabled"
          disable
          editable
        />
      ))}
    </QuestionCard>
  );
};
export default CheckoutButton;
