import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "redux/store";

import {
  BRAND_LOGO,
  DRAG_AND_DROP_LOGO,
  LOGO_PNG,
  ONLY_PNG_JPG_FILES,
  UPLOAD_LINK,
  LOGO_CAVEAT,
} from "../../utils/constants";
import {
  Subtitle,
  ColorContainer,
  TopContainer,
  FileUploadContainer,
  UploadFileMessage,
  FileUploadLabel,
  LabelContainer,
  UploadLinkText,
  TextContainer,
  AclarationText,
  InputAdornment,
  FileUploadInput,
  MessageContainer,
  Loader,
  UploadedFileContainer,
  Text,
  ContentContainer,
  ErrorMessage,
  LeftContainer,
} from "./styles";
import {
  CrossIcon,
  DragFile,
  GreyCrossIcon,
  PNGLogo,
  SuccessfulIcon,
} from "assets";
import useUploadStatus from "hooks/useUploadStatus";

const BrandLogo = () => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { logo } = useAppSelector((state) => state.companySlice.branding);
  const {
    uploadStatus,
    isSuccessful,
    errorMessage,
    uploadedFile,
    resetUploadStatus,
    setUploadedFile,
    handleRemoveFile,
    handleFileUpload,
  } = useUploadStatus();

  useEffect(() => {
    if (logo) {
      fetch(logo)
        .then((response) => response.blob())
        .then((blob) => {
          const fileName = LOGO_PNG;
          const file = new File([blob], fileName, { type: blob.type });
          setUploadedFile(file);
        })
        .catch((error) => console.error("Error loading file:", error));
    }
  }, [logo, setUploadedFile]);

  const handleFileDrop = async (event: React.DragEvent) => {
    event.preventDefault();
    const file = event.dataTransfer.files ? event.dataTransfer.files[0] : null;
    handleFileUpload(file, "logo");
  };

  const handleFileInput = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files ? event.target.files[0] : null;
    handleFileUpload(file, "logo");
  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const handleDragEnter = (event: React.DragEvent) => {
    event.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (event: React.DragEvent) => {
    event.preventDefault();
    setIsDraggingOver(false);
  };

  const handleCloseModal = () => {
    resetUploadStatus();
  };

  const handleLinkClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  useEffect(() => {
    if (isSuccessful) setTimeout(resetUploadStatus, 2000);
  }, [isSuccessful]);

  return (
    <ColorContainer>
      <TopContainer>
        <Subtitle>{BRAND_LOGO}</Subtitle>
        <Text>{LOGO_CAVEAT}</Text>
      </TopContainer>
      <FileUploadContainer
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleFileDrop}
        className={isDraggingOver ? "dragging" : ""}
      >
        {uploadedFile ? (
          <UploadedFileContainer>
            <ContentContainer>
              <PNGLogo />
              <TextContainer>
                <Text>{uploadedFile.name}</Text>
                <AclarationText>
                  {uploadedFile.type.split("/")[1]} - {uploadedFile.size}
                </AclarationText>
              </TextContainer>
            </ContentContainer>
            <InputAdornment
              onClick={async () => await handleRemoveFile("logo")}
              className="pointer"
            >
              <GreyCrossIcon />
            </InputAdornment>
          </UploadedFileContainer>
        ) : (
          <>
            <InputAdornment>
              <DragFile />
            </InputAdornment>
            <LabelContainer>
              <FileUploadLabel htmlFor="fileUpload">
                {DRAG_AND_DROP_LOGO}
                <UploadLinkText onClick={handleLinkClick}>
                  {UPLOAD_LINK}
                </UploadLinkText>
                <FileUploadInput
                  id="file-upload"
                  type="file"
                  onChange={handleFileInput}
                  ref={fileInputRef}
                />
              </FileUploadLabel>
            </LabelContainer>
            <TextContainer>
              <AclarationText>{ONLY_PNG_JPG_FILES}</AclarationText>
            </TextContainer>
          </>
        )}
      </FileUploadContainer>
      {uploadStatus && (
        <MessageContainer>
          <LeftContainer>
            {!isSuccessful && <Loader />}
            {isSuccessful && (
              <InputAdornment>
                <SuccessfulIcon />
              </InputAdornment>
            )}

            <UploadFileMessage>{uploadStatus}</UploadFileMessage>
          </LeftContainer>
          {isSuccessful && (
            <InputAdornment onClick={handleCloseModal} className="pointer">
              <CrossIcon />
            </InputAdornment>
          )}
        </MessageContainer>
      )}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </ColorContainer>
  );
};

export default BrandLogo;
