import { Color } from "material-ui-color";
import React, { useEffect, useState } from "react";

import {
  ColorButton,
  ColorBoxContainer,
  ColorPickerBox,
  LabelContainer,
  ColorLabel,
  ColorPickerContainer,
  ButtonLogo,
} from "./styles";

export interface ColorPickerProps {
  defaultColor: string;
  selectedColor: string;
  label: string;
  className: string;
  showColorBox: boolean;
  setShowColorBox: React.Dispatch<React.SetStateAction<boolean>>;
  buttonClassname: string;
  resetDefaultStyles: boolean;
  setResetDefaultStyles: React.Dispatch<React.SetStateAction<boolean>>;
  updateBrandingColor: (newColor: string) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  defaultColor,
  label,
  className,
  showColorBox,
  setShowColorBox,
  buttonClassname,
  selectedColor,
  resetDefaultStyles,
  setResetDefaultStyles,
  updateBrandingColor,
}) => {
  const [color, setColor] = useState(selectedColor || defaultColor);

  const handleColorChange = (newValue: Color) => {
    setColor(`#${newValue.hex}`);
    updateBrandingColor(`#${newValue.hex}`);
  };

  useEffect(() => {
    if (resetDefaultStyles) {
      setColor(defaultColor);
      setResetDefaultStyles(false);
    }
  }, [resetDefaultStyles]);

  return (
    <ColorPickerContainer>
      <LabelContainer>
        <ColorLabel>{label}</ColorLabel>
      </LabelContainer>
      <ColorButton
        className={buttonClassname}
        color={color}
        onClick={() => setShowColorBox(!showColorBox)}
      >
        <ButtonLogo />
      </ColorButton>
      {showColorBox && (
        <ColorBoxContainer className={className}>
          <ColorPickerBox
            defaultValue={label}
            onChange={handleColorChange}
            value={color}
          />
        </ColorBoxContainer>
      )}
    </ColorPickerContainer>
  );
};
export default ColorPicker;
