import { AxiosResponse } from "axios";

import api from "../../../api";
import { clearApiError, setApiError } from "../../../redux/slices/surveySlice";
import store from "../../../redux/store";

const { dispatch } = store;

interface BodyData {
  title: string;
  subtitle: string;
}

export const updateQuestionGroup = async (id: number, body: BodyData) =>
  await api
    .put(`/question-groups/${id}`, body)
    .then((res: AxiosResponse) => {
      dispatch(clearApiError());
      return res?.data;
    })
    .catch((error) => {
      console.log("[updateQuestionGroup error]: ", error);
      dispatch(setApiError({ error: true }));
    });
