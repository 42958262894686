import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

import {
  HeaderContainer,
  InputAdornment,
  HeaderTextContainer,
  Text,
  IconButtonDiv,
} from "./styles";
import { SURVEY_DETAILS } from "../../utils/constants";
import { ArrowRight, BackArrow } from "../../assets";
import { TextInput } from "../../components";
import { editSurvey } from "../Survey/services/editSurvey";
import { setApiError } from "redux/slices/surveySlice";
import { useAppDispatch } from "redux/store";

interface Props {
  name: string;
  surveyId: number;
}

const Header = ({ name, surveyId }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [nameToEdit, setNameToEdit] = useState(name);
  const [debouncedName] = useDebounce(nameToEdit, 1000);
  const [nameError, setNameError] = useState<string | null>(null);

  const editSurveyCall = async () => {
    try {
      await editSurvey(surveyId, { name: nameToEdit.trim() });
      setNameError(null);
    } catch (error) {
      if (
        error?.response?.data?.message ===
        'duplicate key value violates unique constraint "UQ_SURVEY_NAME"'
      ) {
        setNameError(
          "The survey name must be unique. Please choose a different name."
        );
      } else dispatch(setApiError({ error: true }));
    }
  };

  useEffect(() => {
    if (debouncedName !== name) editSurveyCall();
  }, [debouncedName]);

  return (
    <HeaderContainer>
      <HeaderTextContainer>
        <InputAdornment>
          <IconButtonDiv onClick={() => navigate("/survey")}>
            <BackArrow />
          </IconButtonDiv>
        </InputAdornment>
        <Text>{SURVEY_DETAILS}</Text>
        <InputAdornment>
          <ArrowRight />
        </InputAdornment>
        <TextInput
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setNameToEdit(event.target.value)
          }
          value={nameToEdit}
          error={!!nameError}
          errorLabel={nameError}
        />
      </HeaderTextContainer>
    </HeaderContainer>
  );
};

export default Header;
