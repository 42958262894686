import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface AuthState {
  id: number;
  name: string;
  surname: string;
  companyName: string;
  calendlyURL: string;
  calendlyToken: string;
  email: string;
  emailSender: string;
  emailReceiver: string;
  sendBMCPDF: boolean;
  signature: string;
  accessToken: string;
  errorMessage: string;
  apiError: boolean;
  clientURL: string;
  dateFormat: string;
}

const initialState: AuthState = {
  id: -1,
  name: "",
  surname: "",
  companyName: "",
  calendlyURL: "",
  calendlyToken: "",
  clientURL: "",
  dateFormat: "MM/DD/YYYY",
  email: "",
  emailSender: "",
  emailReceiver: "",
  sendBMCPDF: false,
  signature: "",
  accessToken: "",
  errorMessage: "",
  apiError: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<AuthState>) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.surname = action.payload.surname;
      state.companyName = action.payload.companyName;
      state.signature = action.payload.signature || "";
      state.calendlyURL = action.payload.calendlyURL;
      state.calendlyToken = action.payload.calendlyToken;
      state.clientURL = action.payload.clientURL;
      state.dateFormat = action.payload.dateFormat;
      state.emailSender = action.payload.emailSender;
      state.emailReceiver = action.payload.emailReceiver;
      state.sendBMCPDF = action.payload.sendBMCPDF;
      state.email = action.payload.email;
      state.accessToken = action.payload.accessToken;
      state.errorMessage = initialState.errorMessage;
      state.apiError = initialState.apiError;
    },
    logoutSuccess: (state) => {
      state.id = initialState.id;
      state.name = initialState.name;
      state.surname = initialState.surname;
      state.companyName = initialState.companyName;
      state.signature = initialState.signature;
      state.calendlyURL = initialState.calendlyURL;
      state.calendlyToken = initialState.calendlyToken;
      state.clientURL = initialState.clientURL;
      state.dateFormat = initialState.dateFormat;
      state.emailSender = initialState.emailSender;
      state.emailReceiver = initialState.emailReceiver;
      state.sendBMCPDF = initialState.sendBMCPDF;
      state.email = initialState.email;
      state.emailSender = initialState.emailSender;
      state.accessToken = initialState.accessToken;
      state.errorMessage = initialState.errorMessage;
      state.apiError = initialState.apiError;
    },
    setAdminInfo: (state, action: PayloadAction<AuthState>) => {
      state.name = action.payload.name;
      state.surname = action.payload.surname;
      state.companyName = action.payload.companyName;
      state.emailSender = action.payload.emailSender;
      state.emailReceiver = action.payload.emailReceiver;
      state.sendBMCPDF = action.payload.sendBMCPDF;
      state.signature = action.payload.signature || "";
      state.errorMessage = initialState.errorMessage;
      state.calendlyURL = action.payload.calendlyURL;
      state.calendlyToken = action.payload.calendlyToken;
      state.clientURL = action.payload.clientURL;
      state.dateFormat = action.payload.dateFormat;
    },
    setAuthError: (state, action: PayloadAction<{ error: string }>) => {
      state.errorMessage = action.payload.error;
    },
    setApiError: (state, action: PayloadAction<{ error: boolean }>) => {
      state.apiError = action.payload.error;
    },
    clearApiError: (state) => {
      state.apiError = initialState.apiError;
    },
    clearAuthError: (state) => {
      state.errorMessage = initialState.errorMessage;
    },
  },
});

export const {
  loginSuccess,
  logoutSuccess,
  setAdminInfo,
  setAuthError,
  clearAuthError,
  clearApiError,
  setApiError,
} = authSlice.actions;

export default authSlice.reducer;
