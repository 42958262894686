import { AxiosResponse } from "axios";

import api from "../../../api";
import store from "../../../redux/store";
import {
  clearApiError,
  clearAuthError,
  loginSuccess,
  setApiError,
  setAuthError,
} from "../../../redux/slices/authSlice";
import { setCompany } from "../../../redux/slices/companySlice";
import { getCompany } from "../../Survey/services/getCompany";

interface LoginBody {
  email: string;
  password: string;
}

const { dispatch } = store;

export const login = async (body: LoginBody) => {
  await api
    .post("auth/sign-in", body)
    .then((res: AxiosResponse) => {
      const {
        accessToken,
        id,
        name,
        surname,
        company,
        emailSender,
        emailReceiver,
        signature,
        calendlyURL = "",
        calendlyToken = "",
        clientURL = "",
        dateFormat,
      } = res?.data;
      dispatch(
        loginSuccess({
          id,
          name,
          surname,
          companyName: company?.name,
          calendlyURL,
          clientURL,
          dateFormat,
          calendlyToken,
          email: body.email,
          emailSender,
          emailReceiver,
          sendBMCPDF: company?.sendBMCPDF,
          signature,
          accessToken,
          errorMessage: "",
          apiError: false,
        })
      );
      dispatch(setCompany({ ...company }));

      getCompany(company.id);
    })
    .catch((error) => {
      console.log("[login error]: ", error);
      if (error?.response?.status === 401) {
        dispatch(setAuthError({ error: error?.response?.data?.message }));
        dispatch(clearApiError());
      } else {
        dispatch(clearAuthError());
        dispatch(setApiError({ error: true }));
      }
    });
};
