import { styled } from "@mui/material/styles";
import { Panel } from "reactflow";

import theme from "../../definitions/theme";

export const InputAdornment = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "45px",
    gap: "16px",
  };
});

export const Container = styled("div")(() => {
  return {
    height: "100vh",
    width: "100%",
  };
});

interface Props {
  surveyName?: boolean;
}

export const Text = styled("h1")<Props>(({ surveyName = false }) => {
  const {
    customSizes: {
      body: {
        smallDescription: {
          regular: { fontSize, fontWeight, lineHeight },
        },
      },
    },
    customPalette: { secondary, darkMediumGrey },
    customFonts: { poppins, poppinsSemiBold },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    color: surveyName ? secondary : darkMediumGrey,
    fontFamily: poppins,
    borderBottom: surveyName ? `2px solid ${secondary}` : "none",

    "&.bold": {
      fontFamily: poppinsSemiBold,
      fontSize,
      fontWeight,
      lineHeight,
    },
  };
});

export const StyledPanel = styled(Panel)(() => {
  return {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "80px",
    paddingTop: "70px",
    margin: "0px",
  };
});

export const DetailsContainer = styled("div")(() => {
  return {
    display: "flex",
    gap: "16px",
    alignItems: "center",
  };
});
