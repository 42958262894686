import { CopyPasteIcon } from "assets";
import styled from "styled-components";

export const ModalContainer = styled("div")(({ theme }) => {
  const {
    customPalette: { white },
  } = theme;

  return {
    display: "flex",
    flexDirection: "column",
    borderRadius: 16,
    top: "50%",
    left: "50%",
    width: "720px",
    outline: "none",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    backgroundColor: white,
    padding: "48px",
    gap: "16px",
  };
});

export const TopContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0px",
});

export const ButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  paddingTop: "24px",
});

export const TextContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
});

export const TitleLabel = styled("label")(({ theme }) => {
  const {
    customSizes: {
      body: {
        title2: {
          semibold: { fontSize, fontWeight, lineHeight },
        },
      },
    },
    customFonts: { poppins },
    customPalette: { darkGrey },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    color: darkGrey,
    fontFamily: poppins,
  };
});

export const SubtitleLabel = styled("label")(({ theme }) => {
  const {
    customSizes: {
      body: {
        description: {
          regular: { fontSize, fontWeight, lineHeight },
        },
      },
    },
    customFonts: { poppins },
    customPalette: { darkGrey },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    color: darkGrey,
    fontFamily: poppins,
  };
});

export const Cross = styled("button")({
  border: "none",
  background: "none",
  width: "14px",
  height: "14px",
  cursor: "pointer",
});

export const InputContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  width: "100%",
});

export const TextInputWrapper = styled("div")({
  alignItems: "center",
  width: "100%",
  position: "relative",
});

export const StyledCopyPasteIcon = styled(CopyPasteIcon)(({ theme }) => {
  const {
    customPalette: { secondary },
  } = theme;

  return {
    cursor: "pointer",
    position: "absolute",
    right: "8px",
    top: "30%",
    marginTop: "16px",
    ":hover": {
      path: {
        stroke: secondary,
      },
    },
  };
});
