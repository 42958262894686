import { AxiosResponse } from "axios";

import api from "../../../api";
import { clearApiError, setApiError } from "../../../redux/slices/authSlice";
import store from "../../../redux/store";

interface BodyData {
  name?: string;
  surname?: string;
  companyName?: string;
  calendlyURL?: string;
  calendlyToken?: string;
  clientURL?: string;
  dateFormat?: string;
  emailReceiver?: string;
  emailSender?: string;
  signature?: File | null;
}

const { dispatch } = store;

export const updateAdminInfo = async (id: number, body: BodyData) => {
  let formData = new FormData();

  if (body) {
    Object.entries(body).forEach(([key, value]) => {
      if (value instanceof File) {
        formData.append(key, value, value.name);
      } else {
        formData.append(key, value);
      }
    });
  }

  return await api
    .put(`/admin/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res: AxiosResponse) => {
      dispatch(clearApiError());
      return res?.data;
    })
    .catch((error) => {
      console.log("[updateAdminInfo error]: ", error);
      dispatch(setApiError({ error: true }));
    });
};
