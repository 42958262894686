import styled from "styled-components";

export const Container = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "16px",
  };
});

export const TitleContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
  };
});

export const InfoContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    paddingTop: "8px",
  };
});

export const WarningText = styled("label")(({ theme }) => {
  const {
    customSizes: {
      body: {
        smallDescription: {
          regular: { fontSize, fontWeight, lineHeight },
        },
      },
    },
    customPalette: { darkGrey },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    color: darkGrey,
    fontFamily: poppins,
  };
});
export const WarningContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
  };
});
