import { AxiosResponse } from "axios";

import api from "../../../api";
import store from "../../../redux/store";
import { setApiError } from "../../../redux/slices/companySlice";
import { getSurveys } from "./getSurveys";

const { dispatch } = store;

export const deleteSurvey = async (id: number) => {
  await api
    .delete(`/survey/${id}`)
    .then((res: AxiosResponse) => {
      getSurveys();
    })

    .catch((error) => {
      console.log("[deleteSurvey error]: ", error);
      dispatch(setApiError({ error: true }));
    });
};
