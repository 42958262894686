import api from "../../../api";
import store from "../../../redux/store";
import {
  logoutSuccess,
  setApiError,
  setAuthError,
} from "../../../redux/slices/authSlice";

const { dispatch } = store;

export const logout = async () => {
  await api
    .get("auth/log-out")
    .then(() => {
      dispatch(logoutSuccess());
      localStorage.clear();
      return (window.location.href = "/");
    })
    .catch((error) => {
      console.log("[logout error]: ", error);
      dispatch(setAuthError({ error: error?.response?.data?.message }));
      dispatch(setApiError({ error: true }));
    });
};
