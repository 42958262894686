import { Menu, MenuItem } from "@mui/material";
import styled from "styled-components";

export const Container = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100vh",
    padding: "80px 84px 40px 84px",

    [theme.breakpoints.down(760)]: {
      padding: "8px",
      overflow: "scroll",
    },
  };
});

export const TextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",

  [theme.breakpoints.down(760)]: {
    marginBottom: "8px",
  },
}));

export const Title = styled("h1")(({ theme }) => {
  const {
    customSizes: {
      heading: {
        h6: { fontSize, fontWeight, lineHeight },
      },
      body: {
        title1: { regular },
      },
    },
    customPalette: { blackGrey },
    customFonts: { poppinsSemiBold },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    margin: 0,
    marginBottom: 24,
    color: blackGrey,
    fontFamily: poppinsSemiBold,

    [theme.breakpoints.down(760)]: {
      lineHeight: "16px",
      fontSize: regular.fontSize,
    },
  };
});

export const TableHead = styled("div")(({ theme }) => {
  const {
    customPalette: {
      card: { boxShadow },
    },
  } = theme;

  return {
    borderRadius: "10px",
    height: "40px",
    boxShadow,
    marginBottom: "8px",
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "36px",
    [theme.breakpoints.down(760)]: {
      display: "none",
    },
  };
});

export const TableRow = styled("div")(({ theme }) => {
  const {
    customPalette: {
      card: { boxShadow },
    },
  } = theme;
  return {
    display: "flex",
    borderRadius: "10px",
    height: "72px",
    boxShadow,
    paddingRight: "24px",
    cursor: "pointer",
    marginBottom: "8px",
    "-webkit-tap-highlight-color": "transparent",
    [theme.breakpoints.down(760)]: {
      flexDirection: "column",
      boxShadow: "none",
      padding: "0 16px 0 16px",
      height: "81px",
    },
  };
});

export const DefaultContainer = styled("div")<{ isDefault }>(
  ({ theme, isDefault }) => {
    const {
      customPalette: { secondary, lightGrey },
    } = theme;

    return {
      width: "12px",
      backgroundColor: isDefault ? secondary : lightGrey,
      marginRight: "24px",
      borderRadius: "10px 0 0 10px",
      [theme.breakpoints.down(760)]: {
        display: "none",
      },
    };
  }
);

export const TableCell = styled("div")(({ theme }) => {
  const {
    customSizes: {
      body: {
        smallDescription: {
          regular: { fontSize, fontWeight, lineHeight },
        },
      },
    },
    customPalette: { grey },
    customFonts: { poppinsSemiBold, poppins },
  } = theme;

  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "25%",
    fontFamily: poppins,
    fontSize,
    fontWeight,
    lineHeight,

    "&.bold": {
      fontFamily: poppinsSemiBold,
      fontSize,
      fontWeight,
      lineHeight,
    },

    "&.start-position": {
      justifyContent: "flex-start",
    },

    [theme.breakpoints.down(760)]: {
      width: "100%",

      "&.pl": {
        paddingLeft: "35px",
        justifyContent: "flex-start",
      },
      "&.grey": {
        color: grey,
        fontSize: "13px",
      },
    },
  };
});

export const Text = styled("div")(({ theme }) => {
  const {
    customSizes: {
      body: {
        smallDescription: {
          regular: { fontSize, fontWeight, lineHeight },
        },
      },
    },
    customFonts: { poppinsSemiBold },
  } = theme;

  return {
    fontFamily: poppinsSemiBold,
    fontSize,
    fontWeight,
    lineHeight,
  };
});

export const EmptyDiv = styled("div")(() => {
  return {
    display: "flex",
    flexGrow: 2,
  };
});

export const IconButton = styled("div")(({ theme }) => {
  const {
    customPalette: { secondary },
  } = theme;

  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 16px 0 16px",
    cursor: "pointer",
    height: "35px",
    width: "35px",
    borderRadius: "5px",
    ":hover": {
      "&.fill": {
        path: {
          fill: secondary,
        },
      },
      "&.stroke": {
        path: {
          stroke: secondary,
        },
      },
    },

    "&.ml": {
      margin: "0 0px 0 16px",
    },
    "&.mobile": {
      margin: "0",
    },
  };
});

export const ButtonsCell = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexGrow: 2,
    justifyContent: "flex-end",
    alignItems: "center",

    [theme.breakpoints.down(760)]: {
      display: "none",
    },
  };
});

export const MobileMenuIcon = styled("div")(() => {
  return {
    cursor: "pointer",
    width: "30px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    zIndex: 10,
  };
});

export const MobileMenuContainer = styled("div")(({ theme }) => {
  return {
    flexGrow: 2,
    justifyContent: "flex-end",
    display: "none",

    [theme.breakpoints.down(760)]: {
      display: "flex",
    },
  };
});

export const MobileMenu = styled(Menu)(({ theme }) => {
  const {
    customPalette: { mediumLightGrey },
  } = theme;

  return {
    "& .MuiPaper-root": {
      boxShadow: "none",
      border: `1px solid ${mediumLightGrey}`,
    },
  };
});

export const MobileMenuItem = styled(MenuItem)(({ theme }) => {
  const {
    customPalette: { secondary },
  } = theme;

  return {
    ":hover": {
      color: secondary,
      "&.fill": {
        path: {
          fill: secondary,
        },
      },
      "&.stroke": {
        path: {
          stroke: secondary,
        },
      },
    },
  };
});
