import { forwardRef, useEffect, useMemo, useRef } from "react";
import { Container, Text } from "./styles";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { setQuestionCreated } from "../../redux/slices/surveySlice";

interface TabProps {
  title: string;
  activeTab: number;
  setActiveTab: (index: number) => void;
  index: number;
  surveyId: number;
}

export const Tab = forwardRef<HTMLDivElement, TabProps>(
  ({ title, activeTab, setActiveTab, index, surveyId }) => {
    const isActive = activeTab === index;
    const dispatch = useAppDispatch();
    const { surveys } = useAppSelector((state) => state.surveySlice);
    const survey = useMemo(
      () => surveys.find((survey) => survey.id === surveyId),
      [surveys, surveyId]
    );
    const lastQuestionGroupRef = useRef<HTMLDivElement>(null);

    const handleTabClick = () => {
      setActiveTab(index);
    };

    useEffect(() => {
      if (survey.questionGroupCreated && lastQuestionGroupRef?.current) {
        lastQuestionGroupRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
        dispatch(setQuestionCreated({ surveyId, created: false }));
      }
    }, []);

    return (
      <Container
        ref={lastQuestionGroupRef}
        isActive={isActive}
        onClick={handleTabClick}
      >
        <Text isActive={isActive}>{title}</Text>
      </Container>
    );
  }
);
