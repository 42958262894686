import React, { useEffect, useState } from "react";
import {
  Container,
  Text,
  IconContainer,
  ContentContainer,
  HeaderContainer,
  SummaryContainer,
  CustomButton,
  ButtonContainer,
  StyledCopyPasteIcon,
  HorizontalBorder,
  Loader,
} from "./styles";
import { AiSummaryIcon, RegenerateIcon, WandIcon } from "assets";
import Button from "../../components/button";

const AiSummary = ({
  getSummary,
  summary,
  copied,
  setCopied,
}: {
  getSummary: () => Promise<void>;
  summary: string;
  copied: boolean;
  setCopied: (copied: boolean) => void;
}) => {
  const [loading, setLoading] = useState(false);

  const handleGetSummary = async () => {
    setLoading(true);
    await getSummary();
    setLoading(false);
  };

  const handleCopySummary = () => {
    if (!summary) return;
    navigator.clipboard.writeText(summary);
    setCopied(true);
  };

  return (
    <Container>
      {summary ? (
        <SummaryContainer>
          <HeaderContainer>
            <IconContainer width="25%" className="title">
              <AiSummaryIcon style={{ width: "24px", height: "24px" }} />
              <Text className="title">Analytics Assistance</Text>
            </IconContainer>
            <ButtonContainer>
              <CustomButton onClick={handleCopySummary}>
                <StyledCopyPasteIcon />
                Copy text
              </CustomButton>
              <CustomButton onClick={handleGetSummary}>
                {loading ? <Loader className="blue" /> : <RegenerateIcon />}
                {loading ? "Regenerating..." : "Regenerate"}
              </CustomButton>
            </ButtonContainer>
          </HeaderContainer>
          {summary.split('\n').map((paragraph) => (
            <Text key={paragraph}>{paragraph}</Text>
          ))}
          <HorizontalBorder />
        </SummaryContainer>
      ) : (
        <>
          <IconContainer width="10%">
            <AiSummaryIcon />
          </IconContainer>
          <ContentContainer>
            <Text>
              Get an analysis with AI Assistance that will provide you with an
              output containing the graph's analysis.
            </Text>
            <Button
              label={loading ? <Loader /> : "Ask AI"}
              variantType="primary"
              width={220}
              height={40}
              icon={!loading && <WandIcon style={{ marginRight: "8px" }} />}
              onClick={handleGetSummary}
            />
          </ContentContainer>
        </>
      )}
    </Container>
  );
};

export default AiSummary;
