import styled from "styled-components";
import { Switch } from "@mui/material";

export const Container = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    justifyContent: "space-between",
    width: "1080px",
    height: "74px",
    paddingTop: "40px",
    paddingBottom: "80px",
  };
});

export const Title = styled("text")(({ theme }) => {
  const {
    customSizes: {
      body: {
        description: {
          semibold: { fontSize, fontWeight, lineHeight },
        },
      },
    },
    customPalette: { primary },
    customFonts: { poppinsSemiBold },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    color: primary,
    fontFamily: poppinsSemiBold,
  };
});

export const SubtitleContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px",
  };
});

export const InfoContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ":hover": {
      cursor: "pointer",
    },
  };
});

export const Subtitle = styled("text")(({ theme }) => {
  const {
    customSizes: {
      body: {
        description: {
          regular: { fontSize, fontWeight, lineHeight },
        },
      },
    },
    customPalette: { darkGrey },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    color: darkGrey,
    fontFamily: poppins,
  };
});

export const TextContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
  };
});

export const SwitchContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    alignItems: "end",
    paddingTop: "54px",
  };
});

export const SwitchStyled = styled(Switch)(({ theme }) => {
  const {
    customPalette: { secondary, mediumLightGrey, white, lightGrey },
  } = theme;

  return {
    "&.MuiSwitch-root": {
      width: "33px",
      height: "17px",
      padding: 0,
    },
    "& .MuiSwitch-switchBase": {
      position: "absolute",
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: white,
        "& + .MuiSwitch-track": {
          backgroundColor: secondary,
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        border: `6px solid ${white}`,
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: mediumLightGrey,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: "13px",
      height: "13px",
      backgroundColor: white,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: lightGrey,
      opacity: 1,
      width: "33px",
      height: "17px",
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  };
});
