import { AxiosResponse } from "axios";

import api from "../../../api";
import store from "../../../redux/store";
import { setApiError } from "../../../redux/slices/companySlice";

const { dispatch } = store;

export const getFiltersById = async (filterId: number): Promise<any> => {
  const filters = await api
    .get(`/filter/${filterId}`)
    .then((res: AxiosResponse) => {
      return res?.data;
    })
    .catch((error) => {
      console.log("[getFilters error]: ", error);
      dispatch(setApiError({ error: true }));
    });

  return filters;
};
