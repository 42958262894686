import styled from "styled-components";

export const Container = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: "80px 84px 40px 84px",

    [theme.breakpoints.down(760)]: {
      padding: "8px",
      overflow: "scroll",
    },
  };
});

export const TextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",

  [theme.breakpoints.down(760)]: {
    marginBottom: "8px",
  },
}));

export const Title = styled("h1")(({ theme }) => {
  const {
    customSizes: {
      heading: {
        h6: { fontSize, fontWeight, lineHeight },
      },
      body: {
        title1: { regular },
      },
    },
    customPalette: { blackGrey },
    customFonts: { poppinsSemiBold },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    margin: 0,
    marginBottom: 24,
    color: blackGrey,
    fontFamily: poppinsSemiBold,

    [theme.breakpoints.down(760)]: {
      lineHeight: "16px",
      fontSize: regular.fontSize,
    },
  };
});

export const TagContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  alignItems: "center",
}));

export const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  justifyContent: "flex-end",
}));
