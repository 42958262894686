import styled from "styled-components";

import { IconButton } from "@mui/material";

interface TagProps {
  variantType: string;
  hasMargin?: boolean;
}

export const StyledTag = styled("div")<TagProps>(
  ({ theme, variantType, hasMargin }) => {
    const {
      customPalette: {
        tag: { active, hover, normal },
      },
    } = theme;

    return {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      width: "fit-content",
      height: "32px",
      padding: "0px 12px 0px 12px",
      gap: "5px",
      cursor: "pointer",
      borderRadius: "7px",
      margin: hasMargin ? "8px 8px 8px 0px" : "0px",
      backgroundColor:
        variantType === "active" ? active.background : normal.background,
      ":hover": {
        boxShadow: hover.boxShadow,
      },
      "-webkit-tap-highlight-color": "transparent",
    };
  }
);

export const IconButtonStyled = styled(IconButton)(() => {
  return {
    "&.MuiIconButton-root": {
      width: "20px",
      height: "20px",
      justifyContent: "center",
      padding: 0,
      margin: 0,
    },
  };
});

export const LabelInput = styled("input")(({ theme }) => {
  const {
    customPalette: {
      textField: { hover },
      darkMediumGrey,
      mediumGrey,
    },
    customFonts: { poppins },
  } = theme;

  return {
    all: "unset",
    minWidth: "60px",
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "20px",
    color: darkMediumGrey,
    fontFamily: poppins,
    border: "2px solid transparent",
    borderRadius: "3px",
    paddingLeft: "5px",
    paddingRight: "5px",
    height: "30px",
    ":hover": {
      border: `2px solid ${hover.border}`,
      borderRadius: "3px",
    },
    "::placeholder": {
      color: mediumGrey,
    },
  };
});
