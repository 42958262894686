import React, { useEffect, useState } from "react";

import { StyledTextField, Container } from "./styles";

export interface ShortTextProps {
  id?: string;
  placeholder: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  isAddYourEmail?: boolean;
  isResponseField?: boolean;
  multiline?: boolean;
  initialValue?: string;
}

const ShortText: React.FC<ShortTextProps> = ({
  id,
  placeholder,
  initialValue = "",
  onChange,
  isResponseField = false,
  multiline = false,
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (e: any) => {
    setValue(e.target.value);
    onChange?.(e);
  };

  return (
    <Container isResponseField={isResponseField}>
      <StyledTextField
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        isResponseField
        multiline={multiline}
      />
    </Container>
  );
};

export default ShortText;
