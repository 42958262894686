import { useState } from "react";
import { useEdgesState, useNodesState } from "reactflow";

import { deletePathRule } from "../pages/PathRules/services/deletePathRule";
import { postPathRule } from "../pages/PathRules/services/postPathRule";
import { addEdge } from "reactflow";
import theme from "../definitions/theme";
import { isTarget } from "../utils/utils";

function useNodeLogic(questionGroups, surveyId: number) {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [showMessage, setShowMessage] = useState(false);

  const createNode = () => {
    questionGroups?.forEach((questionGroup) => {
      const parentNode = {
        id: `QG-${questionGroup?.id}`,
        type: "questionGroupNode",
        data: {
          label: questionGroup?.title,
          source: questionGroup?.pathRule?.length > 0,
          target: isTarget(questionGroups, questionGroup?.id),
        },
        position: { x: 0, y: 0 },
      };

      setNodes((nodes) => [...nodes, parentNode]);
    });
  };

  const createEdge = () => {
    const newEdges = questionGroups?.flatMap((questionGroup) => {
      return questionGroup?.pathRule?.map((pathRule) => {
        const newEdge = {
          id: pathRule.id + "",
          style: {
            stroke: theme.customPalette.hover,
            strokeDasharray: "8, 8",
          },
          source: `QG-${questionGroup.id}`,
          target: `QG-${pathRule.nextGroup.id}`,
          data: {
            text: pathRule.responseText,
          },
        };

        return newEdge;
      });
    });
    setEdges(newEdges);
  };

  const handleConnect = async (params) => {
    const outgoingEdges = edges.filter((edge) => edge.source === params.source);
    const incomingEdges = edges.filter((edge) => edge.target === params.target);

    if (outgoingEdges?.length < 1 && incomingEdges?.length < 1) {
      const questionGroupId = parseInt(params.source.split("-")[1]);
      const nextGroupId = parseInt(params.target.split("-")[1]);

      const questionGroup = questionGroups?.find(
        (group) => group.id === questionGroupId
      );

      try {
        await postPathRule(questionGroup, {
          questionGroupId,
          nextGroupId,
        });

        setEdges((eds) =>
          addEdge(
            {
              ...params,
              style: {
                stroke: theme.customPalette.hover,
                strokeDasharray: "8, 8",
              },
            },
            eds
          )
        );
        setNodes((nodes) => {
          return nodes.map((node) => {
            const isSourceNode = node.id === `QG-${questionGroupId}`;
            const isTargetNode = node.id === `QG-${nextGroupId}`;
            if (isSourceNode || isTargetNode)
              return {
                ...node,
                data: {
                  ...node.data,
                  source: isSourceNode || node.data.source,
                  target: isTargetNode || node.data.target,
                },
              };

            return node;
          });
        });
      } catch (error) {
        console.error("Error creating path rule:", error);
      }
    } else {
      console.log("Cannot add edge, it would violate the 1 out and 1 in rule.");
    }
  };

  const handleDelete = async (params) => {
    const edgeToDelete = edges.find((edge) => edge.id === params[0].id);
    const questionGroupId = parseInt(edgeToDelete.source.split("-")[1]);

    const questionGroup = questionGroups.find(
      (group) => group.id === questionGroupId
    );

    try {
      const isBifurcateType =
        questionGroupId === 1 &&
        questionGroup.questions[0].type === "bifurcate";

      if (!isBifurcateType) {
        await deletePathRule(
          questionGroup,
          parseInt(edgeToDelete.id),
          surveyId
        );
        setEdges((eds) => eds.filter((edge) => edge !== edgeToDelete));
        setNodes((nodes) => {
          return nodes.map((node) => {
            const isSourceNode = node.id === `QG-${questionGroupId}`;
            const isTargetNode =
              node.id === `QG-${edgeToDelete.target.split("-")[1]}`;
            if (isSourceNode || isTargetNode)
              return {
                ...node,
                data: {
                  ...node.data,
                  source: isSourceNode ? false : node.data.source,
                  target: isTargetNode ? false : node.data.target,
                },
              };

            return node;
          });
        });
        setShowMessage(false);
      } else setShowMessage(true);
    } catch (error) {
      console.error("Error deleting path rule:", error);
    }
  };

  return {
    nodes,
    edges,
    handleConnect,
    handleDelete,
    createNode,
    createEdge,
    onNodesChange,
    onEdgesChange,
    showMessage,
    setShowMessage,
  };
}

export default useNodeLogic;
