import { AxiosResponse } from "axios";

import api from "../../../api";
import store from "../../../redux/store";
import { setApiError } from "../../../redux/slices/companySlice";
import { addSurvey } from "../../../redux/slices/surveySlice";

const { dispatch } = store;

export const createSurvey = async (body: { name: string }) => {
  await api
    .post("/survey", body)
    .then((res: AxiosResponse) => {
      const { id, name, isDefault, created_at, updated_at, questionGroups } =
        res?.data;
      dispatch(
        addSurvey({
          id,
          name,
          isDefault,
          created_at,
          updated_at,
          questionGroups,
        })
      );
    })

    .catch((error) => {
      console.log("[createSurvey error]: ", error);
      dispatch(setApiError({ error: true }));
    });
};
