import { AxiosResponse } from "axios";

import api from "../../../api";
import store from "../../../redux/store";
import { clearApiError, setApiError } from "redux/slices/userResponseSlice";

const { dispatch } = store;

export const getTechStack = async () => {
  const url = `company/tech-stack`;
  let response;
  await api
    .get(url)
    .then((res: AxiosResponse) => {
      dispatch(clearApiError());
      response = res?.data;
    })
    .catch((error) => {
      console.log("[getTechStack error]: ", error);
      dispatch(setApiError({ error: true }));
    });

  return response;
};
