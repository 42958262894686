import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { BrandingState, CompanyState, NdaState } from "../../types";
import { getFileNameFromUrl } from "../../utils/utils";

const initialState: CompanyState = {
  id: -1,
  name: "",
  address: "",
  webPage: "",
  phoneNumber: "",
  email: "",
  branding: {
    id: -1,
    primaryColor: "",
    secondaryColor: "",
    primaryTextColor: "",
    tagColor: "",
    linkColor: "",
    homePageTextColor: "",
    secondaryTextColor: "",
    backgroundColor: "",
    logo: null,
    regularFontFile: null,
    semiBoldFontFile: null,
    boldFontFile: null,
    emailIcon: null,
    successIcon: null,
  },
  nda: null,
  openAIKey: "",
  isAIActive: false,
  apiError: false,
  sendBMCPDF: false,
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany: (state, action: PayloadAction<CompanyState>) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.address = action.payload.address;
      state.webPage = action.payload.webPage;
      state.phoneNumber = action.payload.phoneNumber;
      state.email = action.payload.email;
      state.sendBMCPDF = action.payload.sendBMCPDF;
      state.openAIKey = action.payload.openAIKey;
      state.isAIActive = action.payload.isAIActive;
    },
    setBranding: (state, action: PayloadAction<BrandingState>) => {
      const { regularFontFile, semiBoldFontFile, boldFontFile, logo, ...rest } =
        action.payload;

      state.branding = {
        ...rest,
        logo: logo || "",
        regularFontFile: getFileNameFromUrl(regularFontFile),
        semiBoldFontFile: getFileNameFromUrl(semiBoldFontFile),
        boldFontFile: getFileNameFromUrl(boldFontFile),
      };
    },
    setNda: (state, action: PayloadAction<NdaState>) => {
      state.nda = action.payload;
    },
    setApiError: (state, action: PayloadAction<{ error: boolean }>) => {
      state.apiError = action.payload.error;
    },
    clearApiError: (state) => {
      state.apiError = initialState.apiError;
    },
  },
});

export const { setCompany, setBranding, setNda, setApiError, clearApiError } =
  companySlice.actions;

export default companySlice.reducer;
