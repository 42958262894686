import { AxiosResponse } from "axios";

import api from "../../../api";
import {
  setApiError,
  clearApiError,
  setCompany,
} from "../../../redux/slices/companySlice";
import store from "../../../redux/store";

interface BodyData {
  name?: string;
  sendBMCPDF?: boolean;
  openAIKey?: string;
  isAIActive?: boolean;
}
const { dispatch } = store;

export const updateCompanyInfo = async (id: number, body: BodyData) => {
  return await api
    .put(`/company/${id}`, body)
    .then((res: AxiosResponse) => {
      dispatch(clearApiError());
      dispatch(setCompany({ ...res.data }));
      return res.data;
    })
    .catch((error) => {
      console.log("[updateCompanyInfo error]: ", error);
      dispatch(setApiError({ error: true }));
    });
};
