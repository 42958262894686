import styled from "styled-components";

export const Tag = styled("div")<{ backgroundColor: string }>(
  ({ backgroundColor }) => {
    return {
      backgroundColor,
      borderRadius: "8px",
      padding: "4px 8px 4px 8px",
    };
  }
);

export const Text = styled("div")<{ color?: string }>(({ theme, color }) => {
  const {
    customSizes: {
      body: {
        smallDescription: {
          regular: { fontSize, fontWeight, lineHeight },
        },
      },
    },
    customFonts: { poppins, poppinsSemiBold },
  } = theme;

  return {
    fontFamily: poppins,
    fontSize,
    fontWeight,
    lineHeight,
    color,

    "&.bold": {
      fontFamily: poppinsSemiBold,
    },
  };
});

export const RemainingTagsButton = styled("button")(({ theme }) => {
  const {
    customSizes: {
      heading: {
        h4: { fontWeight },
      },
      uploadStatus: { lineHeight },

      body: {
        smallDescription: {
          semibold: { fontSize },
        },
      },
    },
    customFonts: { poppins },
    customPalette: {
      button: {
        text: { secondary },
      },
    },
  } = theme;

  return {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    color: secondary,
    fontFamily: poppins,
    fontSize,
    fontWeight,
    lineHeight,
  };
});
