import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import authSlice, { logoutSuccess } from "./slices/authSlice";
import companySlice from "./slices/companySlice";
import surveySlice from "./slices/surveySlice";
import questionTypesSlice from "./slices/questionTypesSlice";
import userResponseSlice from "./slices/userResponseSlice";
import { AnyAction } from "@reduxjs/toolkit";

const persistConfig = {
  key: "root-admin", // The key to use for storing in localStorage
  storage,
};

const combinedReducer = combineReducers({
  authSlice,
  companySlice,
  surveySlice,
  questionTypesSlice,
  userResponseSlice,
});

const persistedReducer = persistReducer(persistConfig, combinedReducer);

export type RootState = ReturnType<typeof persistedReducer>;

const rootReducer = (state: RootState, action: AnyAction) => {
  if (action.type === logoutSuccess.type) state = {} as RootState;

  return persistedReducer(state, action);
};

export default rootReducer;
