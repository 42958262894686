import styled from "styled-components";
import theme from "../../definitions/theme";

export const RowContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "16px",
  };
});

export const LeftContainer = styled("div")(() => ({
  display: "flex",
  width: "80%",
  alignItems: "center",
}));
interface CheckboxProps {
  focused: boolean | undefined;
}

export const Checkbox = styled("div")<CheckboxProps>(({ theme, focused }) => {
  const {
    customPalette: {
      mediumLightGrey: defaultBorder,
      secondary: checkedColor,
      white: background,
    },
  } = theme;

  return {
    width: "24px",
    height: "24px",
    padding: "4.5px 0 0 2px",
    backgroundColor: background,
    border: `1px solid ${focused ? checkedColor : defaultBorder}`,
    borderRadius: "4px",
    boxShadow: focused ? `0px 0px 2px ${checkedColor}` : "",
    ":hover": {
      border: `1px solid ${checkedColor}`,
    },

    "&.disabled": {
      border: `1px solid ${defaultBorder}`,
      boxShadow: "none",
      ":hover": {
        border: `1px solid ${defaultBorder}`,
        boxShadow: "none",
      },
    },
  };
});

export const LabelInput = styled("input")(({ theme }) => {
  const {
    customPalette: {
      textField: { hover },
      darkGrey,
    },
    customFonts: { poppins },
  } = theme;

  return {
    all: "unset",
    width: "100%",
    maxWidth: 640,
    marginLeft: "8px",
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "20px",
    color: darkGrey,
    fontFamily: poppins,
    paddingLeft: "5px",
    border: "2px solid transparent",

    borderRadius: "4px",
    ":hover": {
      border: `2px solid ${hover.border}`,
      borderRadius: "4px",
    },
  };
});

export const Label = styled("label")<{ marginLeft?: boolean }>(
  ({ theme, marginLeft }) => {
    const {
      customPalette: { darkGrey },
      customFonts: { poppins },
    } = theme;

    return {
      marginLeft: marginLeft ? "8px" : "none",
      fontSize: "13px",
      fontWeight: 400,
      lineHeight: "20px",
      color: darkGrey,
    };
  }
);

export const IconContainer = styled("div")(() => {
  return {
    display: "flex",
    marginLeft: "8px",
  };
});

export const Tag = styled("div")(() => {
  return {
    borderRadius: "8px",
    backgroundColor: theme.customPalette.tagsDefault,
    marginLeft: "8px",
    alignItems: "center",
    paddingTop: "4px",
    paddingBottom: "4px",
    paddingLeft: "8px",
    paddingRight: "8px",
  };
});
