import styled from "styled-components";
import { Divider } from "@mui/material";

export const Container = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    paddingLeft: "80px",
    paddingRight: "80px",
  };
});

export const LeftContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
  };
});

export const RightContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
  };
});

export const Line = styled(Divider)<{ width: string }>(({ theme, width }) => {
  return {
    "&.MuiDivider-root": {
      width,
      borderColor: theme.customPalette.mediumLightGrey,
      marginTop: "56px",
    },
  };
});

export const VerticalLine = styled(Divider)(({ theme }) => {
  return {
    "&.MuiDivider-root": {
      height: "95%",
      borderColor: theme.customPalette.mediumLightGrey,
      marginLeft: "64px",
      marginRight: "64px",
    },
  };
});

export const Title = styled("h1")(({ theme }) => {
  const {
    customSizes: {
      heading: {
        h6: { fontSize, fontWeight, lineHeight },
      },
    },
    customPalette: { blackGrey },
    customFonts: { poppinsSemiBold },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    color: blackGrey,
    fontFamily: poppinsSemiBold,
    padding: "40px 84px 40px 84px",
  };
});
