import { InnerCircle, StyledHandle } from "./styles";

const NodeHandle = ({ isConnectable, type, position, active }) => (
  <StyledHandle
    type={type}
    position={position}
    active={active}
    isConnectable={isConnectable}
  >
    <InnerCircle />
  </StyledHandle>
);

export default NodeHandle;
