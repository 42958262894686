import { AxiosResponse } from "axios";

import api from "../../../api";
import store from "../../../redux/store";
import {
  setDefaultFalse,
  updateSurvey,
} from "../../../redux/slices/surveySlice";

const { dispatch } = store;

export const editSurvey = async (
  id: number,
  body: { name?: string; isDefault?: boolean },
  currentDefaultId?: number
) => {
  await api
    .put(`/survey/${id}`, body)
    .then((res: AxiosResponse) => {
      const { id, name, isDefault } = res?.data;
      dispatch(setDefaultFalse({ surveyId: currentDefaultId }));
      dispatch(
        updateSurvey({
          surveyId: id,
          name,
          isDefault: isDefault,
        })
      );
    })
    .catch((error) => {
      console.log("[editSurvey error]: ", error);
      throw error;
    });
};
