import { Box, Drawer } from "@mui/material";
import styled from "styled-components";

import { LogoMobile } from "../../assets";

const drawerWidth = 200;

export const StyledDrawer = styled(Drawer)(({}) => {
  return {
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      width: drawerWidth,
      border: 0,
    },
  };
});

export const MobileHeader = styled("div")(({ theme }) => {
  const {
    customPalette: { secondary },
  } = theme;

  return {
    width: `calc(100% - ${drawerWidth}px)`,
    marginRight: `${drawerWidth}px`,
    display: "none",
    height: "65px",
    backgroundColor: secondary,
    position: "fixed",

    [theme.breakpoints.down(600)]: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
  };
});

export const HeaderLogo = styled(LogoMobile as any)(() => {
  return {
    alignSelf: "center",
    width: "140px",
    height: "30px",
    marginLeft: "24px",
  };
});

export const Container = styled(Box)(({ theme }) => {
  return {
    "&.MuiBox-root": {
      [theme.breakpoints.down(768)]: {
        marginTop: "65px",
      },
    },
  };
});
