import React, { useEffect, useState, useCallback } from "react";

import FiltersSection from "../../components/FiltersSection";
import Button from "components/button";
import PageWrapper from "components/pageWrapper";
import { Container, TextContainer, Title, ButtonContainer } from "./styles";
import FilterDropdown from "components/filterDropdown/index";
import TopFiltersComponent from "components/topFiltersComponent";
import ChartComponent from "components/chartComponent";
import { useAppSelector } from "redux/store";
import { CategoryState, FilterState, SurveyState } from "types";
import { getAiSummary } from "./services/getAiSummary";
import AiSummary from "components/aiSummary";
import CopiedToClipboardModal from "components/copiedModal";
import { ClearFiltersIcon, DownloadPdfIcon } from "assets";
import useDownloadPdf from "hooks/useDownloadPdf";
import useAnalyticsData from "../../hooks/useAnalyticsData";
import useFilterHandlers from "../../hooks/useFilterHandlers";
import { getFilters } from "./services/getFilters";

const Analytics = () => {
  const [expanded, setExpanded] = useState(false);
  const [xAxis, setXAxis] = useState<string>("Date");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { surveys } = useAppSelector((state) => state.surveySlice);
  const [selectedSurvey, setSelectedSurvey] = useState<SurveyState>(
    surveys.find((survey) => survey.isDefault)
  );
  const [selectedTags, setSelectedTags] = useState<
    {
      selectedFilter: string | null;
      selectedIds: CategoryState[];
    }[]
  >([{ selectedFilter: null, selectedIds: [] }]);
  const [filterRows, setFilterRows] = useState<
    {
      selectedFilter: number | null;
      selectedFilterValue: string | null;
      styledSelectOptions: CategoryState[];
    }[]
  >([
    {
      selectedFilter: null,
      selectedFilterValue: null,
      styledSelectOptions: [],
    },
  ]);
  const [summary, setSummary] = useState<string>("");
  const [copied, setCopied] = useState(false);
  const [inputFilters, setInputFilters] = useState<Partial<FilterState>[]>([]);

  const { analyticsData, fetchAnalyticsData, kpiData, fetchKpiData } =
    useAnalyticsData(selectedSurvey, xAxis, startDate, endDate, selectedTags);

  useEffect(() => {
    fetchAnalyticsData();
    fetchKpiData();
  }, [fetchAnalyticsData, fetchKpiData]);

  useEffect(() => {
    const getFiltersCall = async () => {
      const filters = await getFilters();
      setInputFilters(filters);
    };

    getFiltersCall();
  }, []);

  const getSummary = useCallback(async () => {
    const data = await getAiSummary(
      selectedSurvey.id,
      xAxis,
      startDate,
      endDate,
      selectedTags
    );

    setSummary(data);
  }, [selectedSurvey, startDate, endDate, selectedTags, xAxis]);

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  const downloadPdf = useDownloadPdf();

  const {
    handleSaveFilter,
    handleSelectFilter,
    handleDeleteFilter,
    handleClearFilters,
  } = useFilterHandlers(
    selectedTags,
    setSelectedTags,
    setFilterRows,
    setInputFilters,
    inputFilters,
    startDate,
    endDate,
    xAxis,
    setXAxis,
    setStartDate,
    setEndDate,
    setExpanded
  );

  return (
    <>
      <PageWrapper>
        <Container id="analytics-container">
          <TextContainer>
            <Title>Analytics Dashboard</Title>
            <ButtonContainer>
              <Button
                label="Download PDF"
                variantType="secondary"
                icon={<DownloadPdfIcon style={{ marginRight: "8px" }} />}
                width={165}
                height={48}
                onClick={downloadPdf}
              />
              <Button
                label="Clear Filters"
                variantType="secondary"
                icon={<ClearFiltersIcon style={{ marginRight: "8px" }} />}
                width={149}
                height={48}
                onClick={handleClearFilters}
              />
              <FilterDropdown
                inputFilters={inputFilters}
                handleSaveFilter={handleSaveFilter}
                handleSelectFilter={handleSelectFilter}
                handleDeleteFilter={handleDeleteFilter}
              />
            </ButtonContainer>
          </TextContainer>
          <TopFiltersComponent
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            selectedSurvey={selectedSurvey}
            setSelectedSurvey={setSelectedSurvey}
            surveys={surveys}
          />
          <FiltersSection
            expanded={expanded}
            setExpanded={setExpanded}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            filterRows={filterRows}
            setFilterRows={setFilterRows}
            xAxis={xAxis}
            setXAxis={setXAxis}
          />
          <ChartComponent analyticsData={analyticsData} kpiData={kpiData} />
          <AiSummary
            getSummary={getSummary}
            summary={summary}
            copied={copied}
            setCopied={setCopied}
          />
        </Container>
      </PageWrapper>
      {copied && <CopiedToClipboardModal />}
    </>
  );
};

export default Analytics;
