import React from "react";

import { Container } from "./styles";
import { getTagColor, getTagTextColor } from "utils/utils";
import Tag from "../responseTag/tag";

type TagsModalProps = {
  position: {
    left: number;
    top: number;
  };
  tags: string[];
};

const TagsModal = ({ tags, position }: TagsModalProps) => {
  return (
    <Container position={position}>
      {tags.map((tag) => (
        <Tag
          backgroundColor={getTagColor(tag)}
          textColor={getTagTextColor(tag)}
          text={tag}
        />
      ))}
    </Container>
  );
};

export default TagsModal;
