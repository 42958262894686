import styled from "styled-components";

export const ModalContainer = styled("div")(({ theme }) => {
  const {
    customPalette: { white },
  } = theme;

  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: 16,
    top: "50%",
    left: "50%",
    width: "640px",
    height: "304px",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    backgroundColor: white,
    padding: "40px",
    gap: "40px",
  };
});

export const TopContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "24px",
  padding: "0px",
});

export const ButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  gap: "16px",
});

export const Label = styled("p")({
  textAlign: "center",
  margin: "0px",
});
