import { useAppSelector } from "../../redux/store";

import {
  PREVIEW,
  RECEIVE_EMAIL,
  THANK_YOU,
  ILLUSTRATIONS,
  LOADING,
  SUCCESS,
  SEND_EMAIL,
} from "../../utils/constants";
import { SendEmail, Success } from "../../assets";
import {
  PreviewContainer,
  Text,
  TopContainer,
  Title,
  PreviewTitle,
  BottomContainer,
  SuccessImageContainer,
  LoadingImageContainer,
  Preview,
  SuccessImage,
  LoadingImage,
} from "./styles";

const IllustrationsPreview = () => {
  const { emailIcon, successIcon } = useAppSelector(
    (state) => state.companySlice.branding
  );

  return (
    <PreviewContainer>
      <TopContainer>
        <PreviewTitle>
          {ILLUSTRATIONS} {PREVIEW}
        </PreviewTitle>
      </TopContainer>
      <BottomContainer>
        <Title>{LOADING}: </Title>
        <Preview>
          <LoadingImageContainer>
            {emailIcon ? <LoadingImage src={emailIcon} /> : <SendEmail />}
          </LoadingImageContainer>
          <Text marginTop>{SEND_EMAIL}</Text>
        </Preview>
        <Title>{SUCCESS}: </Title>
        <Preview>
          <SuccessImageContainer>
            {successIcon ? <SuccessImage src={successIcon} /> : <Success />}
          </SuccessImageContainer>
          <Text marginBottom>{RECEIVE_EMAIL} </Text>
          <Text>{THANK_YOU}</Text>
        </Preview>
      </BottomContainer>
    </PreviewContainer>
  );
};

export default IllustrationsPreview;
