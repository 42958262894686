import React, { useState } from "react";
import Modal from "@mui/material/Modal";

import {
  ModalContainer,
  TopContainer,
  ButtonContainer,
  TitleLabel,
  Cross,
  SubtitleLabel,
  TextContainer,
  InputContainer,
  TextInputWrapper,
} from "./styles";
import { DarkCross } from "assets";
import Button from "components/button";
import TextInput from "components/textInput";
import { FILTER_NAME } from "utils/constants";

const SaveFilterModal = ({ isOpen, onClose, handleSaveFilter }) => {
  const [filterName, setFilterName] = useState("");

  const handleFilterNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterName(e.target.value);
  };

  const handleSave = () => {
    handleSaveFilter(filterName);
    handleClose();
  };

  const handleClose = () => {
    setFilterName("");
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContainer>
        <TopContainer>
          <TextContainer>
            <TitleLabel>Save Filter</TitleLabel>
          </TextContainer>
          <Cross onClick={handleClose}>
            <DarkCross />
          </Cross>
        </TopContainer>
        <InputContainer>
          <SubtitleLabel>{FILTER_NAME}</SubtitleLabel>
          <TextInputWrapper>
            <TextInput
              placeholder="Click to write"
              value={filterName}
              onChange={handleFilterNameChange}
            />
          </TextInputWrapper>
        </InputContainer>
        <ButtonContainer>
          <Button
            label="Save"
            variantType="primary"
            width="100%"
            height={48}
            disabled={filterName === ""}
            onClick={handleSave}
          />
        </ButtonContainer>
      </ModalContainer>
    </Modal>
  );
};

export default SaveFilterModal;
