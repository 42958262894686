import styled from "styled-components";

export const PanelItemContainer = styled("div")(({ theme }) => {
  const {
    customPalette: {
      tag: { hover: hoverTag },
    },
  } = theme;

  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: theme.customPalette.white,
    height: "62px",
    cursor: "pointer",
    borderBottom: `1px solid ${theme.customPalette.mediumGrey}`,
    "&:last-child": {
      borderBottom: "none",
    },
    ":hover": {
      backgroundColor: hoverTag.background,
    },
  };
});

export const InputAdornment = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "45px",
    paddingLeft: "8px",
  };
});

export const Text = styled("h1")(({ theme }) => {
  const {
    customSizes: {
      body: {
        smallDescription: {
          regular: { fontSize, fontWeight, lineHeight },
        },
      },
    },
    customPalette: { darkMediumGrey },
    customFonts: { poppins, poppinsSemiBold },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    color: darkMediumGrey,
    fontFamily: poppins,
    paddingLeft: "16px",
    "&.bold": {
      fontFamily: poppinsSemiBold,
      fontSize,
      fontWeight,
      lineHeight,
    },
  };
});
