import { Outlet, Navigate } from "react-router-dom";

import store from "../redux/store";

const PrivateRoutes = () => {
  const {
    authSlice: { accessToken },
  } = store.getState();

  return accessToken ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
