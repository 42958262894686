import { AxiosResponse } from "axios";

import api from "../../../api";
import store from "../../../redux/store";
import {
  addQuestionGroup,
  setApiError,
  clearApiError,
} from "../../../redux/slices/surveySlice";

const { dispatch } = store;

interface QuestionOptionBody {
  id: number;
  optionText: string;
  description: string;
  buttonText: string;
}

interface QuestionBody {
  id: number;
  required: boolean;
  type: string;
  questionText: string;
  index: number;
  label: string;
  questionSubtitleText: string;
  currency: string;
  questionOptions: QuestionOptionBody[];
}

interface QuestionGroupBody {
  title: string;
  subtitle: string;
  isFirst: boolean;
  questions: QuestionBody[];
  surveyId: number;
}

export const postQuestionGroup = async (
  questionGroup: QuestionGroupBody,
  surveyId: number
) => {
  await api
    .post("/question-groups", [questionGroup])
    .then((res: AxiosResponse) => {
      const {
        id,
        title,
        subtitle,
        question,
        isFirst,
        questions,
        pathRule,
        created_at,
        updated_at,
      } = res?.data[0];

      dispatch(
        addQuestionGroup({
          surveyId,
          questionGroup: {
            id,
            title,
            subtitle,
            question,
            isFirst,
            questions,
            pathRule,
            created_at,
            updated_at,
          },
        })
      );

      dispatch(clearApiError());
    })
    .catch((error) => {
      console.log("[postQuestionGroup error]: ", error);
      dispatch(setApiError({ error: true }));
    });
};
