import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { QuestionType } from "utils/utils";

interface QuestionTypesState {
  questionTypes: QuestionType[];
  apiError: boolean;
}

const initialState: QuestionTypesState = {
  questionTypes: [],
  apiError: false,
};

const questionTypesSlice = createSlice({
  name: "questionTypes",
  initialState,
  reducers: {
    setQuestionTypes: (state, action: PayloadAction<QuestionType[]>) => {
      state.questionTypes = action.payload;
    },
    setQuestionTypesError: (
      state,
      action: PayloadAction<{ error: boolean }>
    ) => {
      state.apiError = action.payload.error;
    },
    clearQuestionTypesError: (state) => {
      state.apiError = initialState.apiError;
    },
  },
});

export const {
  setQuestionTypes,
  setQuestionTypesError,
  clearQuestionTypesError,
} = questionTypesSlice.actions;

export default questionTypesSlice.reducer;
