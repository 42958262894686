import { AxiosResponse } from "axios";
import api from "../../../api";
import store from "../../../redux/store";
import { setApiError } from "../../../redux/slices/companySlice";
import { CategoryState } from "types";

const { dispatch } = store;

export const getLocations = async (): Promise<CategoryState[]> => {
  const locations = await api
    .get("/categories/locations")
    .then((res: AxiosResponse) => {
      return res?.data.map((location) => ({
        id: location.id,
        name: `${location.city}, ${location.country}`,
      }));
    })
    .catch((error) => {
      console.log("[getLocations error]: ", error);
      dispatch(setApiError({ error: true }));
    });

  return locations;
};
