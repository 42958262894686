import React, { useRef } from "react";

import { getTagColor, getTagTextColor } from "utils/utils";
import { RemainingTagsButton } from "./styles";
import Tag from "../responseTag/tag";

type ResponseTagProps = {
  tag: string;
  remainingTags: number;
  setIsTagsOpen: (value: boolean) => void;
  setMousePosition: (value: { left: number; top: number }) => void;
};

const ResponseTag = ({
  tag,
  remainingTags,
  setIsTagsOpen,
  setMousePosition,
}: ResponseTagProps) => {
  const timeRef = useRef<NodeJS.Timeout>();

  const onMouseEnter = (event) => {
    timeRef.current = setTimeout(() => {
      setIsTagsOpen(true);
      const mouseX = event.clientX;
      const mouseY = event.clientY;
      setMousePosition({ left: mouseX, top: mouseY });
    }, 500);
  };

  const onMouseleave = () => {
    clearTimeout(timeRef.current as NodeJS.Timeout);
    setIsTagsOpen(false);
  };

  return (
    <>
      <Tag
        backgroundColor={getTagColor(tag)}
        textColor={getTagTextColor(tag)}
        text={tag}
      />
      <RemainingTagsButton
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseleave}
      >
        {remainingTags > 0 ? `+${remainingTags}` : ""}
      </RemainingTagsButton>
    </>
  );
};

export default ResponseTag;
