import {
  BRAND_ILLUSTRATIONS,
  LOADING,
  SUCCESS,
  SUCCESS_ICON_DESCRIPTION,
  LOADING_ICON_DESCRIPTION,
} from "../../utils/constants";
import { Subtitle, Container, TopContainer } from "./styles";
import useUploadStatus from "hooks/useUploadStatus";
import UploadContainer from "./uploadContainer";

const BrandIllustrations = () => {
  const loadingIconUpload = useUploadStatus();
  const successIconUpload = useUploadStatus();

  return (
    <Container>
      <TopContainer>
        <Subtitle>{BRAND_ILLUSTRATIONS}</Subtitle>
      </TopContainer>
      <UploadContainer
        title={LOADING}
        description={LOADING_ICON_DESCRIPTION}
        useUploadStatus={loadingIconUpload}
        illustration="emailIcon"
      />
      <UploadContainer
        title={SUCCESS}
        description={SUCCESS_ICON_DESCRIPTION}
        useUploadStatus={successIconUpload}
        illustration="successIcon"
      />
    </Container>
  );
};

export default BrandIllustrations;
