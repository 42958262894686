import React, { useState } from "react";

import { COLOR_PALETTE, RESET_STYLES } from "../../utils/constants";
import {
  Subtitle,
  ColorContainer,
  ResetButton,
  TopContainer,
  ColorPaletteContainer,
} from "./styles";
import { ColorPicker } from "..";
import { ColorPaletteOptions } from "../../utils/utils";
import { useAppDispatch } from "../../redux/store";
import {
  BodyData,
  updateBranding,
} from "../../pages/Design/services/updateBranding";

export interface ColorPaletteProps {
  colorSetters: React.Dispatch<React.SetStateAction<boolean>>[];
  colorStates: boolean[];
  colorsArray: ColorPaletteOptions[];
}

const ColorPalette = ({
  colorSetters,
  colorStates,
  colorsArray,
}: ColorPaletteProps) => {
  const dispatch = useAppDispatch();
  const [resetDefaultStyles, setResetDefaultStyles] = useState(false);

  const updateBrandingColor = async (index: number, newColor: string) => {
    const propertyNames = [
      "primaryColor",
      "secondaryColor",
      "tagColor",
      "linkColor",
      "backgroundColor",
    ];

    const bodyData: BodyData = {
      [propertyNames[index]]: newColor,
    };

    await updateBranding(bodyData);
  };

  const handleReset = async () => {
    setResetDefaultStyles(true);

    await updateBranding({
      primaryColor: colorsArray[0].defaultColor,
      secondaryColor: colorsArray[1].defaultColor,
      tagColor: colorsArray[2].defaultColor,
      linkColor: colorsArray[3].defaultColor,
      backgroundColor: colorsArray[4].defaultColor,
    });
  };

  return (
    <ColorContainer>
      <TopContainer>
        <Subtitle>{COLOR_PALETTE}</Subtitle>
        <ResetButton onClick={handleReset}>{RESET_STYLES}</ResetButton>
      </TopContainer>
      <ColorPaletteContainer>
        {colorsArray.map((option, index) => (
          <ColorPicker
            key={index}
            {...option}
            showColorBox={colorStates[index]}
            setShowColorBox={colorSetters[index]}
            resetDefaultStyles={resetDefaultStyles}
            setResetDefaultStyles={setResetDefaultStyles}
            updateBrandingColor={(newColor: string) =>
              updateBrandingColor(index, newColor)
            }
          />
        ))}
      </ColorPaletteContainer>
    </ColorContainer>
  );
};

export default ColorPalette;
