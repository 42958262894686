import React from "react";

import { Text, TagsContainer } from "./styles";
import { getResponse, QuestionType, splitResponse } from "../../utils/utils";
import { GroupedResponses } from "../../types";
import Tags from "../Tags";

interface ResponseTextProps {
  responses: GroupedResponses[];
  techStack?: { primary: string[]; secondary: string[] };
}

const { TAG_TEXT } = QuestionType;

const ResponseText: React.FC<ResponseTextProps> = ({
  responses,
  techStack,
}) => {
  return (
    <>
      {responses.map((response) => {
        let res = getResponse(response);
        return response.question.type === TAG_TEXT && res !== "-" ? (
          <TagsContainer key={response.id}>
            {splitResponse(res).map((resp, idx) => (
              <Tags
                disabled
                key={idx}
                hasMargin
                label={resp.trim()}
                techStack={techStack}
              />
            ))}
          </TagsContainer>
        ) : (
          <Text key={response.id}>{res}</Text>
        );
      })}
    </>
  );
};

export default ResponseText;
