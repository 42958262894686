import styled from "styled-components";
import { Handle } from "reactflow";

import theme from "../../definitions/theme";

interface Props {
  active?: boolean;
}

export const StyledHandle = styled(Handle)<Props>(({ active = false }) => {
  return {
    width: "12px",
    height: "12px",
    backgroundColor: active
      ? theme.customPalette.secondary
      : theme.customPalette.mediumLightGrey,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    border: "none",
  };
});

export const InnerCircle = styled("div")(() => {
  return {
    width: "4px",
    height: "4px",
    backgroundColor: theme.customPalette.white,
    borderRadius: "100%",
    border: "none",
  };
});
