import styled from "styled-components/macro";

interface TextProps {
  isActive?: boolean;
}

export const Container = styled("div")<TextProps>(({ theme, isActive }) => {
  const {
    customPalette: {
      tag: { hover: hoverTag },
    },
  } = theme;

  return {
    display: "inline-block",
    flexDirection: "row",
    backgroundColor: theme.customPalette.white,
    padding: "8px",
    borderBottom: isActive
      ? `2px solid ${theme.customPalette.secondaryDark}`
      : "none",
    cursor: "pointer",
    ":hover": {
      backgroundColor: hoverTag.background,
    },
  };
});

export const Text = styled("text")<TextProps>(({ theme, isActive }) => {
  const {
    customSizes: {
      textField: {
        text: { fontSize },
      },
    },
    customPalette: { grey },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize,
    color: isActive ? theme.customPalette.secondaryDark : grey,
    fontFamily: poppins,
  };
});
