import React, { useState, useEffect, useRef } from "react";
import {
  DropdownArrow,
  SaveFilterButton,
  DropdownContainer,
  DropdownContent,
  FilterItem,
  DownArrowButton,
  ButtonsContainer,
  Text,
  StyledTrashIcon,
} from "./styles";
import { FilterState } from "types";
import { SAVE_FILTER } from "utils/constants";
import SaveFilterModal from "components/saveFilterModal";
import { SearchIcon } from "assets";
import TextInput from "components/textInput";

interface FilterDropdownProps {
  inputFilters: Partial<FilterState>[];
  handleSaveFilter: (filterName: string) => void;
  handleSelectFilter: (filterId: number) => void;
  handleDeleteFilter: (filterId: number) => void;
}

const FilterDropdown = ({
  inputFilters,
  handleSaveFilter,
  handleSelectFilter,
  handleDeleteFilter,
}: FilterDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    if (inputFilters && inputFilters.length > 0) setIsOpen(!isOpen);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen && (!inputFilters || inputFilters.length === 0))
      setIsOpen(false);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputFilters, isOpen]);

  const filteredFilters = inputFilters.filter((filter) =>
    filter.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <DropdownContainer ref={dropdownRef}>
      <ButtonsContainer>
        <SaveFilterButton onClick={handleOpenModal}>
          {SAVE_FILTER}
        </SaveFilterButton>
        <DownArrowButton onClick={toggleDropdown} isOpen={isOpen}>
          <DropdownArrow isOpen={isOpen} />
        </DownArrowButton>
      </ButtonsContainer>
      {isOpen && (
        <DropdownContent>
          <TextInput
            type="text"
            placeholder="Search filter..."
            value={searchTerm}
            onChange={handleSearchChange}
            icon={<SearchIcon />}
          />
          {filteredFilters.map((filter) => (
            <FilterItem
              key={filter.id}
              onClick={(e) => {
                e.stopPropagation();
                handleSelectFilter(filter.id);
              }}
            >
              <Text>{filter.name}</Text>
              <StyledTrashIcon
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteFilter(filter.id);
                }}
              />
            </FilterItem>
          ))}
        </DropdownContent>
      )}
      <SaveFilterModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        handleSaveFilter={handleSaveFilter}
      />
    </DropdownContainer>
  );
};

export default FilterDropdown;
