import { AxiosResponse } from "axios";

import api from "../../../api";
import store from "../../../redux/store";
import { setApiError } from "../../../redux/slices/companySlice";
import { addSurvey } from "../../../redux/slices/surveySlice";

const { dispatch } = store;

export const cloneSurvey = async (id: number) => {
  await api
    .post(`/survey/${id}/clone`)
    .then((res: AxiosResponse) => {
      const { id, name, created_at, updated_at, questionGroups, isDefault } =
        res?.data;
      dispatch(
        addSurvey({
          id,
          name,
          created_at,
          updated_at,
          questionGroups,
          isDefault,
        })
      );
    })

    .catch((error) => {
      console.log("[cloneSurvey error]: ", error);
      dispatch(setApiError({ error: true }));
    });
};
