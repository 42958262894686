import { useCallback } from "react";
import html2canvas from "html2canvas";
import { pdf } from "@react-pdf/renderer";
import AnalyticsPdfComponent from "components/analyticsPdfComponent";

const useDownloadPdf = () => {
  const downloadPdf = useCallback(async () => {
    try {
      const pageElement = document.getElementById("analytics-container");
      let pageImage = null;

      if (pageElement) {
        const canvas = await html2canvas(pageElement);
        pageImage = canvas.toDataURL("image/png");
      }

      const blob = await pdf(
        <AnalyticsPdfComponent pageImage={pageImage} />
      ).toBlob();

      if (blob) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "analytics.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } else {
        throw new Error("Failed to create PDF blob.");
      }
    } catch (error) {
      alert("An error occurred while downloading the PDF. Please try again.");
    }
  }, []);

  return downloadPdf;
};

export default useDownloadPdf;
