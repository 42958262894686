import { useEffect, useState, memo, useMemo } from "react";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";
import { IconButton } from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";

import { useAppSelector } from "../../redux/store";
import QuestionGroupNode from "../../components/questionGroupNode";
import PageWrapper from "components/pageWrapper";
import { useLayoutedElements } from "utils/utils";
import {
  Container,
  InputAdornment,
  Text,
  StyledPanel,
  DetailsContainer,
} from "./styles";
import { ArrowRight, BackArrow } from "assets";
import { Button, Modal } from "components";
import { CHOOSE_YOUR_FIT_EDGES, LAYOUT, SURVEY_DETAILS } from "utils/constants";
import useNodeLogic from "hooks/useNodeLogic";
import theme from "../../definitions/theme";

const MemoizedQuestionGroupNode = memo(QuestionGroupNode);

const nodeTypes = {
  questionGroupNode: MemoizedQuestionGroupNode,
};

const PathRules = () => {
  const { id } = useParams();
  const { surveys } = useAppSelector((state) => state.surveySlice);
  const survey = useMemo(
    () => surveys.find((survey) => survey.id === Number(id)),
    [surveys, id]
  );
  const [hasFittedView, setHasFittedView] = useState(false);
  const {
    nodes,
    onNodesChange,
    edges,
    onEdgesChange,
    handleConnect,
    handleDelete,
    createEdge,
    createNode,
    showMessage,
    setShowMessage,
  } = useNodeLogic(survey?.questionGroups, survey?.id);

  const { getLayoutedElements } = useLayoutedElements();
  const navigate = useNavigate();
  const options = { includeHiddenNodes: true };

  const onConnect = (params) => handleConnect(params);

  const onDelete = (params) => handleDelete(params);

  const createNodes = () => createNode();

  const createEdges = () => createEdge();

  const { fitView } = useReactFlow();

  const loadComponents = () => {
    createNodes();
    createEdges();

    const layoutNodes = async () => {
      await new Promise((resolve) => setTimeout(resolve, 50));
      getLayoutedElements({
        "elk.algorithm": "layered",
        "elk.direction": "RIGHT",
      });
    };

    layoutNodes();
  };

  useEffect(() => {
    loadComponents();
  }, []);

  useEffect(() => {
    if (!hasFittedView && nodes) {
      fitView(options);
      setTimeout(() => setHasFittedView(true), 1000);
    }
  }, [hasFittedView, nodes]);

  return (
    <PageWrapper>
      <Container>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onEdgesDelete={onDelete}
          defaultViewport={{ x: 100, y: 300, zoom: 1 }}
          fitView
          fitViewOptions={options}
          connectionLineStyle={{
            stroke: theme.customPalette.hover,
            strokeDasharray: "8, 8",
          }}
        >
          <Controls />
          <MiniMap />
          <Background />
          <StyledPanel position="top-left">
            <InputAdornment>
              <IconButton
                style={{ padding: "0px" }}
                onClick={() => navigate("/survey")}
              >
                <BackArrow />
              </IconButton>
              <DetailsContainer>
                <Text>{SURVEY_DETAILS}</Text>
                <ArrowRight />
                <Text className="bold" surveyName>
                  {survey?.name}
                </Text>
              </DetailsContainer>
            </InputAdornment>

            <Button
              label={LAYOUT}
              width={180}
              height={40}
              variantType="primary"
              onClick={() =>
                getLayoutedElements({
                  "elk.algorithm": "layered",
                  "elk.direction": "RIGHT",
                })
              }
            />
          </StyledPanel>
        </ReactFlow>
        <Modal
          isOpen={showMessage}
          information
          handleClose={() => {
            setShowMessage(false);
            loadComponents();
          }}
          message={CHOOSE_YOUR_FIT_EDGES}
        />
      </Container>
    </PageWrapper>
  );
};

export default PathRules;
