import styled from "styled-components";
import { Divider, MenuItem } from "@mui/material";

import { CopyPasteIcon } from "assets";

export const Container = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100vh",
    padding: "40px",
    paddingTop: "24px",
    paddingLeft: "48px",
    overflowY: "hidden",
  };
});

export const HeaderContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    backgroundColor: theme.customPalette.white,
    alignItems: "left",
    height: "fit-content",
    marginBottom: "24px",
  };
});

export const HeaderTextContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    width: "100%",
    height: "73.3px",
    alignItems: "left",
  };
});

export const InputAdornment = styled("div")(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    height: "45px",
    marginRight: "15px",
  };
});

export const Text = styled("h1")<{ color?: string }>(({ theme, color }) => {
  const {
    customSizes: {
      body: {
        smallDescription: {
          regular: { fontSize, fontWeight, lineHeight },
        },
      },
      tagLabel,
    },
    customPalette: { darkMediumGrey },
    customFonts: { poppins, poppinsSemiBold },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    color: color || darkMediumGrey,
    fontFamily: poppins,
    paddingRight: "8px",

    "&.bold": {
      fontFamily: poppinsSemiBold,
    },

    "&.tag": {
      fontSize: tagLabel.fontSize,
      fontWeight: tagLabel.fontWeight,
      lineHeight: "4px",
      padding: "0px",
      textTransform: "capitalize",
    },
  };
});

export const HeaderUserContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.customPalette.white,
    width: "max-content",
    height: "fit-content",
  };
});

export const Title = styled("h1")(({ theme }) => {
  const {
    customSizes: {
      card: {
        desktop: {
          title: { fontSize, fontWeight, lineHeight },
        },
      },
    },
    customPalette: { primary },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    color: primary,
    fontFamily: poppins,
    paddingLeft: "15px",
  };
});

export const UserInfoContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    gap: "32px",
    marginLeft: "15px",
    width: "100%",
    flex: 1,
  };
});

export const TextContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
  };
});

export const LeftContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    height: "fit-content",
  };
});

export const RightContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    width: "30%",
    height: "fit-content",
    padding: "40px",
    paddingTop: "32px",
  };
});

export const Line = styled(Divider)(({ theme }) => {
  return {
    "&.MuiDivider-root": {
      borderColor: theme.customPalette.mediumLightGrey,
      orientation: "horizontal",
      marginRight: "40px",
      marginLeft: "14px",
    },
  };
});

export const ResponseContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "fit-content",
    paddingBottom: "48px",
    overflowY: "auto",
  };
});

export const ResponseTitleContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    paddingBottom: "16px",
    position: "sticky",
    top: "0",
    backgroundColor: theme.palette.background.paper,
    zIndex: 10,
    paddingTop: 24,
  };
});

export const ResponsesContainer = styled("div")(({ theme }) => {
  return {
    paddingBottom: "48px",
  };
});

export const ResponseTitle = styled("h1")(({ theme }) => {
  const {
    customSizes: {
      body: {
        description: {
          semibold: { fontSize, fontWeight, lineHeight },
        },
      },
    },
    customPalette: { primary },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    color: primary,
    fontFamily: poppins,
    paddingLeft: "15px",
  };
});

export const IconContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    alignItems: "flex-end",
    paddingRight: "32px",
    cursor: "pointer",
    ":hover": {
      path: {
        stroke: theme.customPalette.secondary,
      },
    },
  };
});

export const ButtonContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    gap: "16px",
    paddingRight: "48px",
  };
});

export const StyledCopyPasteIcon = styled(CopyPasteIcon)<{
  disabled?: boolean;
}>(({ theme, disabled }) => {
  return {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginTop: "10px",
    ":hover": {
      path: {
        stroke: disabled ? undefined : theme.customPalette.secondary,
      },
    },
  };
});

export const TagsContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: "8px",
    marginLeft: "15px",
    width: "100%",
    flex: 1,
    paddingTop: "10px",
    paddingBottom: "10px",
  };
});

export const MenuItemStyled = styled(MenuItem)(({ theme }) => {
  return {
    cursor: "default",
    textTransform: "capitalize",
  };
});

export const SelectInput = styled("div")(({ theme }) => {
  return {
    display: "flex",
    gap: 8,
    alignItems: "center",
  };
});

export const TagsDivider = styled("div")(({ theme }) => {
  return {
    border: `0.5px solid ${theme.customPalette.mediumLightGrey}`,
    marginBottom: "10px",
  };
});

export const Description = styled("div")(({ theme }) => {
  const {
    customSizes: {
      tagLabel: { fontSize, fontWeight, lineHeight },
    },
    customPalette: { grey },
  } = theme;

  return {
    fontSize,
    paddingLeft: "15px",
    color: grey,
    fontWeight,
    lineHeight,
  };
});
