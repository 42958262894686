import { AxiosResponse } from "axios";

import api from "../../../api";
import { setApiError, clearApiError } from "../../../redux/slices/companySlice";
import store from "../../../redux/store";

const { dispatch } = store;

export const deleteNda = async () =>
  await api
    .delete(`/nda`)
    .then((res: AxiosResponse) => {
      dispatch(clearApiError());
      return res?.data;
    })
    .catch((error) => {
      console.log("[deleteNda error]: ", error);
      dispatch(setApiError({ error: true }));
    });
