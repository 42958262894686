import React, { useEffect, useState } from "react";

import {
  Container,
  IconContainer,
  InfoContainer,
  StyledCrossIcon,
  StyledPencilIcon,
  StyledTickIcon,
} from "./styles";
import TextInput from "components/textInput";
import { useAppSelector } from "redux/store";
import {
  AI_ASSISTANT,
  SHOW_THE_AI_ASSISTANT,
  OPEN_AI_KEY,
  ENTER_YOUR_OPENAI_KEY,
  AI_APIKEY_ERROR,
} from "utils/constants";
import SettingsDetails from "components/settingsDetails";
import { updateCompanyInfo } from "pages/Settings/services/updateCompany";
import ErrorModal from "components/errorModal";
import { useAppDispatch } from "redux/store";
import { clearApiError, setApiError } from "redux/slices/companySlice";

const AiData = () => {
  const dispatch = useAppDispatch();
  const [aiKey, setAiKey] = useState("");
  const [checked, setChecked] = useState(false);
  const [showKey, setShowKey] = useState(false);
  const { id: companyId } = useAppSelector((state) => state.companySlice);
  const { openAIKey, isAIActive, apiError } = useAppSelector(
    (state) => state.companySlice
  );

  useEffect(() => {
    setChecked(isAIActive);
    setAiKey(openAIKey);
  }, [openAIKey]);

  const resetAiValues = async () => {
    await updateCompanyInfo(companyId, { openAIKey: "", isAIActive: false });
    setAiKey("");
    setChecked(false);
  };

  const switchHandler = async () => {
    if (!checked && !openAIKey) {
      setChecked((prev) => !prev);
      return;
    }

    await updateCompanyInfo(companyId, { isAIActive: !checked });
    setChecked((prev) => !prev);
  };

  const handleKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAiKey(e.target.value);
  };

  const onClick = () => {
    setAiKey("");
    setTimeout(() => {
      setShowKey(!showKey);
    }, 100);
  };

  const handleUpdateKey = async () => {
    if (!aiKey) {
      dispatch(setApiError({ error: true }));
      return;
    }

    await updateCompanyInfo(companyId, { openAIKey: aiKey });
    setChecked(true); 
    setShowKey(false);
  };

  const handleCancel = () => {
    setAiKey(openAIKey);
    setShowKey(false);
  };

  return (
    <Container>
      <SettingsDetails
        title={AI_ASSISTANT}
        subtitle={SHOW_THE_AI_ASSISTANT}
        checked={checked}
        switchHandler={switchHandler}
      />
      {checked && (
        <InfoContainer>
          <TextInput
            placeholder={ENTER_YOUR_OPENAI_KEY}
            label={OPEN_AI_KEY}
            initialValue={""}
            value={aiKey}
            width="556px"
            onChange={handleKeyChange}
            showPassword={showKey}
            type={showKey ? "text" : "password"}
            disabled={!showKey}
            className="noWidth"
          />
          {showKey ? (
            <IconContainer>
              <StyledTickIcon onClick={handleUpdateKey} />
              <StyledCrossIcon onClick={handleCancel} />
            </IconContainer>
          ) : (
            <StyledPencilIcon onClick={onClick} />
          )}
        </InfoContainer>
      )}
      {apiError && (
        <ErrorModal
          isOpen={!!apiError}
          handleClose={() => {
            dispatch(clearApiError());
            resetAiValues();
          }}
          errorMessage={AI_APIKEY_ERROR}
        />
      )}
    </Container>
  );
};

export default AiData;
