import { Position } from "reactflow";

import NodeHandle from "../nodeHandle";
import { NodeContainer, Text } from "./styles";

const QuestionGroupNode = ({ data, isConnectable }) => {
  return (
    <div>
      <NodeHandle
        isConnectable={isConnectable}
        type="target"
        position={Position.Left}
        active={data.target || false}
      />

      <NodeHandle
        type="source"
        position={Position.Right}
        isConnectable
        active={data.source || false}
      />

      <NodeContainer>
        <Text>{data.label}</Text>
      </NodeContainer>
    </div>
  );
};

export default QuestionGroupNode;
