import dayjs, { Dayjs } from "dayjs";
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { Picker, Container, pickerPopupStyle } from "./styles";
import "./pickerStyles.css";
import { useAppSelector } from "redux/store";

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

interface DatePickerProps {
  disabled?: boolean;
  onChange?: (value: string) => void;
  initialValue?: string;
}

const convertDateFormat = (dateString, desiredFormat) => {
  const formats = ['MM/DD/YYYY', 'DD/MM/YYYY'];

  for (let format of formats) {
    const parsedDate = dayjs(dateString, format, true);
    if (parsedDate.isValid()) return parsedDate.format(desiredFormat);
  }

  return 'Invalid Date';
};


const Datepicker = ({
  disabled = false,
  onChange,
  initialValue,
}: DatePickerProps) => {
  const { dateFormat } = useAppSelector((state) => state.authSlice);
  const [selectedDate, setSelectedDate] = useState(initialValue ? dayjs(initialValue, dateFormat) : null);

  useEffect(() => {
    const formattedDate = initialValue ? convertDateFormat(initialValue, dateFormat) : null;
    setSelectedDate(formattedDate ? dayjs(formattedDate, dateFormat) : null);
  }, [initialValue, dateFormat]);

  const handleDateChange = (value) => {
    const formattedDate = value ? dayjs(value).format(dateFormat) : "";
    setSelectedDate(value);
    onChange(formattedDate);
  };

  const parsedDate = selectedDate ? dayjs(selectedDate, dateFormat) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container disabled={disabled}>
        <Picker
          onChange={handleDateChange}
          format={dateFormat}
          value={parsedDate}
          minDate={dayjs()}
          popupStyle={pickerPopupStyle()}
          showToday={false}
        />
      </Container>
    </LocalizationProvider>
  );
};

export default Datepicker;

