import { AxiosResponse } from "axios";

import api from "../../../api";
import store from "../../../redux/store";
import { clearApiError, setApiError } from "../../../redux/slices/surveySlice";

const { dispatch } = store;

export const deleteQuestion = async (id: number) =>
  await api
    .delete(`/questions/${id}`)
    .then((res: AxiosResponse) => {
      dispatch(clearApiError());
      return res?.data;
    })
    .catch((error) => {
      console.log("[deleteQuestion error]: ", error);
      dispatch(setApiError({ error: true }));
    });
