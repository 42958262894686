import { AxiosResponse } from "axios";

import api from "../../../api";
import store from "../../../redux/store";
import { clearEmailError, setEmailError } from "redux/slices/userResponseSlice";

const { dispatch } = store;

interface Body {
  name: string;
  emailReceiver: string;
  emailSender: string;
  adminCompanyName: string;
  sendBMCPDF: string;
  pdfFileOrUrl: string;
  company: string;
  companyLogo: string;
  primaryColor: string;
  isEffectus: boolean;
}

export const sendEmail = async (body: Body) => {
  await api
    .post("/email", body)
    .then((res: AxiosResponse) => {
      dispatch(clearEmailError());
    })
    .catch((error) => {
      dispatch(setEmailError({ error: true }));
    });
};
