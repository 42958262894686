import { useState } from "react";
import { useAppSelector } from "redux/store";

import {
  INVALID_FILE_FORMAT_OTF_TTF,
  INVALID_FILE_FORMAT_SVG,
  THE_SIGNATURE_MUST_BE_150_500,
  UPLOADING_FILE,
} from "utils/constants";
import {
  INVALID_FILE_FORMAT_PNG_JPG,
  FILE_UPLOADED_SUCCESSFULLY,
  INVALID_FILE_SIZE,
} from "utils/constants";
import { updateBranding } from "pages/Design/services/updateBranding";
import { BodyData } from "pages/Design/services/updateBranding";
import { useDispatch } from "react-redux";
import { validateFile } from "utils/utils";
import { updateAdminInfo } from "pages/Settings/services/updateAdmin";
import { setAdminInfo } from "redux/slices/authSlice";

const useUploadStatus = () => {
  const [uploadStatus, setUploadStatus] = useState("");
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [uploadedFont, setUploadedFont] = useState("");
  const [uploadFontStatus, setUploadFontStatus] = useState("");
  const company = useAppSelector((state) => state.companySlice);
  const dispatch = useDispatch();

  const isValidFileSize = async (file: File, key: string) =>
    await validateFile(file, key);

  const handleSignatureUpload = async (file: File | null) => {
    if (file?.type === "image/svg+xml") {
      if (await isValidFileSize(file, "signature")) {
        setLoading();

        const bodyData = {
          signature: file,
        };

        const updatedAdmin = await updateAdminInfo(company.id, bodyData);
        dispatch(setAdminInfo(updatedAdmin));

        setUploadedFile(file);
        setSuccess(FILE_UPLOADED_SUCCESSFULLY);
      } else {
        setUploadedFile(null);
        setError(THE_SIGNATURE_MUST_BE_150_500);
      }
    } else {
      setUploadedFile(null);
      setError(INVALID_FILE_FORMAT_SVG);
    }
  };

  const handleFileUpload = async (file: File | null, key: string) => {
    if (
      file?.type === "image/jpg" ||
      file?.type === "image/png" ||
      file?.type === "image/jpeg" ||
      (file?.type === "image/svg+xml" &&
        (key === "emailIcon" || key === "successIcon"))
    ) {
      if (await isValidFileSize(file, key)) {
        setLoading();

        const bodyData: BodyData = {
          [key]: file,
        };

        await updateBranding(bodyData);

        setUploadedFile(file);
        setSuccess(FILE_UPLOADED_SUCCESSFULLY);
      } else {
        setUploadedFile(null);
        setError(INVALID_FILE_SIZE);
      }
    } else {
      setUploadedFile(null);
      setError(INVALID_FILE_FORMAT_PNG_JPG);
    }
  };

  const handleFontUpload = async (file: File | null, fontFamily: string) => {
    if (file?.type === "font/otf" || file?.type === "font/ttf") {
      setLoadingFont();

      const bodyData: BodyData = {
        [fontFamily]: file,
      };

      const url = window.URL.createObjectURL(file);
      const filenameWithoutExtension = file.name.slice(
        0,
        file.name.lastIndexOf(".")
      );

      document.fonts.add(
        await new FontFace(filenameWithoutExtension, `url(${url})`, {}).load()
      );

      await updateBranding(bodyData);

      setUploadedFont(filenameWithoutExtension);
      setFontSuccess(FILE_UPLOADED_SUCCESSFULLY);
    } else {
      setUploadedFont("");
      setError(INVALID_FILE_FORMAT_OTF_TTF);
    }
  };

  const handleRemoveFile = async (key: string) => {
    const bodyData: BodyData = {
      [key]: null,
    };
    await updateBranding(bodyData);

    resetStatus();
    setUploadedFile(null);
  };

  const handleRemoveFont = async (fontFamily: string) => {
    const bodyData: BodyData = {
      [fontFamily]: "",
    };
    await updateBranding(bodyData);

    resetFontStatus();
    setUploadedFont("");
  };

  const handleRemoveSignature = async () => {
    const bodyData = {
      signature: null,
    };

    const updatedAdmin = await updateAdminInfo(company.id, bodyData);
    dispatch(setAdminInfo(updatedAdmin));

    resetStatus();
    setUploadedFile(null);
  };

  const setLoading = () => {
    setUploadStatus(UPLOADING_FILE);
    setIsSuccessful(false);
    setErrorMessage("");
  };

  const setLoadingFont = () => {
    setUploadFontStatus(UPLOADING_FILE);
    setIsSuccessful(false);
    setErrorMessage("");
  };

  const setSuccess = (message) => {
    setUploadStatus(message);
    setIsSuccessful(true);
    setErrorMessage("");
  };

  const setFontSuccess = (message) => {
    setUploadFontStatus(message);
    setIsSuccessful(true);
    setErrorMessage("");
  };

  const setError = (message) => {
    setUploadStatus("");
    setIsSuccessful(false);
    setErrorMessage(message);
  };

  const resetStatus = () => {
    setUploadStatus("");
    setIsSuccessful(false);
    setErrorMessage("");
    setUploadedFile(null);
  };

  const resetFontStatus = () => {
    setUploadFontStatus("");
    setIsSuccessful(false);
    setErrorMessage("");
    setUploadedFont("");
  };

  const resetUploadStatus = () => {
    setUploadStatus("");
  };

  const resetFontUploadStatus = () => {
    setUploadFontStatus("");
  };

  return {
    uploadStatus,
    uploadFontStatus,
    isSuccessful,
    errorMessage,
    uploadedFile,
    uploadedFont,
    setError,
    resetFontStatus,
    resetStatus,
    resetUploadStatus,
    handleFileUpload,
    setUploadedFile,
    setUploadedFont,
    handleRemoveFile,
    isValidFileSize,
    handleSignatureUpload,
    handleRemoveFont,
    handleRemoveSignature,
    resetFontUploadStatus,
    handleFontUpload,
  };
};

export default useUploadStatus;
