import { useEffect, useState } from "react";

import CustomSelect, { Option } from "components/customSelect";
import { Container, ColumnContainer, Text, Placeholder } from "./styles";
import RangeDatePicker from "components/rangeDatePicker";
import { SurveyState } from "types";
import { DATE_RANGE } from "utils/constants";
import { SELECT_SURVEY } from "utils/constants";
import { presetFilters } from "pages/Analytics/common/filters";

interface TopFiltersComponentProps {
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (startDate: Date | null) => void;
  setEndDate: (endDate: Date | null) => void;
  selectedSurvey: SurveyState;
  setSelectedSurvey: (selectedSurvey: SurveyState) => void;
  surveys: SurveyState[];
}

const TopFiltersComponent = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  selectedSurvey,
  setSelectedSurvey,
  surveys,
}: TopFiltersComponentProps) => {
  const [selectedPresetFilter, setSelectedPresetFilter] = useState<Option>({
    key: 0,
    value: "7 Days",
  });
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (isVisible && !target.closest(".rangeDatePicker")) {
        setIsVisible(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [isVisible, setIsVisible]);

  useEffect(() => {
    if (startDate === null && endDate === null) {
      setSelectedPresetFilter({ key: 0, value: "7 Days" });
    }
  }, [startDate, endDate, setSelectedPresetFilter]);

  const handlePresetFilterClick = (value: number) => {
    const preset = presetFilters[value];
    if (preset) {
      setSelectedPresetFilter({ key: value, value: preset.value });

      const now = new Date();
      let start: Date | null = null;
      let end: Date | null = now;
      switch (value) {
        case 0: // 7 Days
          start = new Date(now);
          start.setDate(now.getDate() - 6);
          break;
        case 1: // 1 Month
          start = new Date(now);
          start.setMonth(now.getMonth() - 1);
          break;
        case 2: // 3 Months
          start = new Date(now);
          start.setMonth(now.getMonth() - 3);
          break;
        case 3: // 6 Months
          start = new Date(now);
          start.setMonth(now.getMonth() - 6);
          break;
        case 4: // 1 Year
          start = new Date(now);
          start.setFullYear(now.getFullYear() - 1);
          break;
        case 5: // All Time
          start = null;
          end = null;
          break;
        default:
          break;
      }

      setStartDate(start);
      setEndDate(end);
    }
  };

  return (
    <Container>
      <ColumnContainer flexGrow={8}>
        <Text>{SELECT_SURVEY}</Text>
        <CustomSelect
          selectedValues={[
            { key: selectedSurvey.id, value: selectedSurvey.name },
          ]}
          handleValueClick={(surveyId: number) => {
            const selected = surveys.find((survey) => survey.id === surveyId);
            if (selected) setSelectedSurvey(selected);
          }}
          options={surveys.reduce(
            (acc, survey) => ({ ...acc, [survey.id]: survey.name }),
            {}
          )}
        />
      </ColumnContainer>
      <ColumnContainer flexGrow={0} style={{ width: "300px" }}>
        <Text>{DATE_RANGE}</Text>
        <RangeDatePicker
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </ColumnContainer>
      <ColumnContainer flexGrow={1}>
        <Placeholder />
        <CustomSelect
          selectedValues={[selectedPresetFilter]}
          handleValueClick={handlePresetFilterClick}
          options={presetFilters.reduce(
            (acc, preset) => ({ ...acc, [preset.key]: preset.value }),
            {}
          )}
        />
      </ColumnContainer>
    </Container>
  );
};

export default TopFiltersComponent;
