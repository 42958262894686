import React, { PropsWithChildren } from "react";

import { Container, Content, DeleteButton, RowContainer } from "./styles";
import TextInput from "../textInput";
import {
  DELETE_QUESTION_GROUP,
  TYPE_YOUR_QUESTION_GROUP_SUBTITLE,
  TYPE_YOUR_QUESTION_GROUP_TITLE,
} from "utils/constants";
import useQuestionGroupCardLogic from "../../hooks/useQuestionGroupCardLogic";
import { QuestionGroupState } from "../../types";

interface QuestionGroupDetailsCardProps extends PropsWithChildren {
  id: number;
  initialData: QuestionGroupState;
  currentQuestionGroupChanged: boolean;
  setCurrentQuestionGroupChanged: (
    value: React.SetStateAction<boolean>
  ) => void;
  onClickDeleteButton: () => void;
  surveyId: number;
}

const QuestionGroupDetailsCard = ({
  id,
  initialData,
  currentQuestionGroupChanged,
  setCurrentQuestionGroupChanged,
  onClickDeleteButton,
  surveyId,
}: QuestionGroupDetailsCardProps) => {
  const { title, onChangeTitle, subtitle, onChangeSubtitle } =
    useQuestionGroupCardLogic({
      id,
      initialData,
      currentQuestionGroupChanged,
      setCurrentQuestionGroupChanged,
      surveyId,
    });

  return (
    <Container>
      <RowContainer>
        <Content className="title">
          <TextInput
            onChange={onChangeTitle}
            value={title}
            placeholder={TYPE_YOUR_QUESTION_GROUP_TITLE}
            className="simple"
            showErrorLabel={false}
          />
        </Content>
        <DeleteButton onClick={onClickDeleteButton}>
          {DELETE_QUESTION_GROUP}
        </DeleteButton>
      </RowContainer>
      <RowContainer>
        <Content className="subtitle">
          <TextInput
            onChange={onChangeSubtitle}
            value={subtitle}
            placeholder={TYPE_YOUR_QUESTION_GROUP_SUBTITLE}
            className="subtitle"
            showErrorLabel={false}
          />
        </Content>
      </RowContainer>
    </Container>
  );
};

export default QuestionGroupDetailsCard;
