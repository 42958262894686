import ResponseTag from "components/responseTag";
import TagsModal from "components/tagsModal";
import { useState } from "react";

const TagsList: React.FC<{
  maturity: string;
  temperature: string;
  techsTags: string[];
}> = ({ maturity, temperature, techsTags }) => {
  const tags = [];
  if (maturity) tags.push(maturity);
  if (temperature) tags.push(temperature);
  if (techsTags?.length > 0) tags.push(...techsTags);
  const maxTags = Math.floor(180 / 128);
  const remainingTags = tags.length - maxTags;
  if (tags.length > maxTags) tags.splice(maxTags, tags.length);

  const [mousePosition, setMousePosition] = useState({ left: 0, top: 0 });
  const [isTagsOpen, setIsTagsOpen] = useState(false);

  return (
    <>
      {tags.map((tag) => {
        return (
          <ResponseTag
            tag={tag}
            remainingTags={remainingTags}
            setIsTagsOpen={setIsTagsOpen}
            setMousePosition={setMousePosition}
          />
        );
      })}
      {isTagsOpen && (
        <TagsModal
          position={mousePosition}
          tags={[maturity, temperature, ...(techsTags || [])]}
        />
      )}
    </>
  );
};

export default TagsList;
