import styled from "styled-components";

import { Logo } from "../../assets";

interface TextProps {
  isError?: boolean;
}

export const HeaderContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    paddingTop: "56px",
    paddingBottom: "64px",
    backgroundColor: theme.customPalette.primary,
    alignItems: "center",
    [theme.breakpoints.down(768)]: {
      padding: "40px 0 24px 24px",
    },
  };
});

export const HomeLogo = styled(Logo as any)(() => {
  return {
    alignSelf: "center",
    width: "125px",
    height: "60px",
  };
});

export const Container = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.customPalette.primary,
    height: "100vh",
  };
});

export const Card = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  borderRadius: "8px",
  width: "600px",
  backgroundColor: theme.customPalette.white,
  padding: "80px",
  alignSelf: "center",
  [theme.breakpoints.down(768)]: {
    rowGap: "16px",
    width: "92%",
    height: "600px",
    padding: "24px 16px",
    marginTop: "10%",
    marginBottom: "10%",
  },
}));

export const TextFieldContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
  };
});

export const InputContainer = styled("div")(({ theme }) => {
  return {
    width: "100%",
    marginBottom: 24,
    [theme.breakpoints.down(768)]: {
      width: "100%",
    },
  };
});

export const TextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "left",
  [theme.breakpoints.down(768)]: {
    marginLeft: "0px",
  },
}));

export const Title = styled("h1")(({ theme }) => {
  const {
    customSizes: {
      card: {
        desktop: { title: titleDesktop },
        mobile: { title: titleMobile },
      },
    },
    customPalette: { blackGrey },
    customFonts: { poppinsSemiBold },
  } = theme;

  return {
    [theme.breakpoints.down(768)]: {
      fontSize: titleMobile.fontSize,
      fontWeight: titleMobile.fontWeight,
      lineHeight: titleMobile.lineHeight,
    },
    fontSize: titleDesktop.fontSize,
    fontWeight: titleDesktop.fontWeight,
    lineHeight: titleDesktop.lineHeight,
    color: blackGrey,
    margin: 0,
    marginBottom: 32,
    fontFamily: poppinsSemiBold,
  };
});

export const Text = styled("h1")<TextProps>(({ theme, isError }) => {
  const {
    customSizes: {
      card: {
        desktop: { text: textDesktop },
        mobile: { text: textMobile },
      },
    },
    customPalette: { darkMediumGrey, error },
    customFonts: { poppinsLight },
  } = theme;

  return {
    [theme.breakpoints.down(768)]: {
      fontSize: textMobile.fontSize,
      fontWeight: textMobile.fontWeight,
      lineHeight: textMobile.lineHeight,
    },
    fontSize: textDesktop.fontSize,
    fontWeight: textDesktop.fontWeight,
    lineHeight: textDesktop.lineHeight,
    color: isError ? error : darkMediumGrey,
    margin: 0,
    marginBottom: 8,
    fontFamily: poppinsLight,
  };
});

export const Label = styled("p")(({ theme }) => {
  const {
    customSizes: {
      textField: {
        label: { fontSize, fontWeigh, lineHeight },
      },
    },
    customPalette: { error },
    typography: { fontFamily },
  } = theme;

  return {
    fontFamily,
    fontSize: fontSize,
    fontWeight: fontWeigh,
    lineHeight: lineHeight,
    color: error,
    height: "22px",
    margin: "0 0 8px 0",
    alignSelf: "flex-start",
  };
});
