import styled from "styled-components";

export const ChartContainer = styled("div")(({ theme }) => {
  const {
    customPalette: {
      card: { boxShadow },
    },
  } = theme;

  return {
    flexDirection: "column",
    width: "100%",
    padding: "8px 32px 16px 0px",
    backgroundColor: theme.customPalette.white,
    borderRadius: "10px",
    boxShadow: boxShadow,
    marginTop: "16px",
  };
});

export const HeaderContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  paddingLeft: "32px",
  justifyContent: "space-between",
}));

export const KpiContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  gap: "5px",
}));

export const DataContainer = styled("div")(() => ({
  alignSelf: "center",
  height: "100%",
}));

export const ButtonsContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: "5px",
}));

export const Title = styled("p")(({ theme }) => {
  const {
    customSizes: {
      body: {
        title1: {
          semibold: { fontSize, lineHeight, fontWeight },
        },
      },
    },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    fontFamily: poppins,
  };
});

export const KpiTitle = styled("p")(({ theme }) => {
  const {
    customSizes: {
      body: {
        title1: {
          semibold: { lineHeight, fontWeight },
        },
      },
    },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize: "13px",
    fontWeight,
    lineHeight,
    fontFamily: poppins,
  };
});

export const KpiValue = styled("p")(({ theme }) => {
  const {
    customSizes: {
      preview: {
        label: { fontSize, lineHeight, fontWeight },
      },
    },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    fontFamily: poppins,
  };
});

interface IconButtonProps {
  active: boolean;
}

export const IconButton = styled("div")<IconButtonProps>(
  ({ theme, active }) => {
    const {
      customPalette: { grey, secondary },
    } = theme;

    return {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      borderRadius: "8px",
      border: active ? `1px solid ${secondary}` : `1px solid ${grey}`,
      width: "52px",
      height: "48px",
      path: {
        fill: active ? secondary : grey,
      },
      ":hover": {
        "&.fill": {
          path: {
            fill: secondary,
          },
        },
        "&.stroke": {
          path: {
            stroke: secondary,
          },
        },
        border: `1px solid ${secondary}`,
      },
    };
  }
);

export const LegendContainer = styled.ul({
  listStyleType: "none",
  padding: 0,
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(110px, 1fr))",
  width: "100%",
  height: "100%",
  overflow: "hidden",
});

export const LegendItem = styled("li")(() => ({
  display: "flex",
  alignItems: "center",
  margin: "5px 0",
}));

interface LegendItemColorProps {
  color: string;
}

export const LegendItemColor = styled("div")<LegendItemColorProps>(
  ({ color }) => ({
    width: "14px",
    height: "14px",
    borderRadius: "3px",
    backgroundColor: color,
    marginRight: "5px",
  })
);

export const LegendItemValue = styled("span")(({ theme }) => {
  const {
    customSizes: {
      tagLabel: { fontSize, lineHeight, fontWeight },
    },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    fontFamily: poppins,
    color: theme.customPalette.primary,
  };
});
