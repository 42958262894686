import styled from "styled-components";

export const Container = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  };
});

export const SelectInputContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    position: "relative",
    justifyContent: "flex-end",
    width: "320px",
  };
});

export const SelectInput = styled("input")(({ theme }) => {
  const {
    typography: { fontFamily },
    customSizes: {
      textAreaInput: {
        desktop: { placeholder: textDesktop },
      },
    },
    customPalette: {
      textAreaInput: { border, activeText },
    },
  } = theme;
  return {
    fontFamily: `${fontFamily} !important`,
    fontWeight: textDesktop.fontWeight,
    color: activeText,
    border: `2px solid ${border}`,
    width: "320px",
    height: "48px",
    backgroundColor: theme.customPalette.darkTransparent,
    borderColor: theme.customPalette.mediumLightGrey,
    borderRadius: "8px",
    pointerEvents: "none",
  };
});

export const IconContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    position: "absolute",
    marginRight: "16px",
    color: theme.customPalette.darkGrey,
  };
});
