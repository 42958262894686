import React from "react";

import { CustomTooltipContainer, TextTooltip } from "./styles";

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  barChart?: boolean;
}

const CustomTooltip = ({ active, payload, barChart }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    const data = barChart ? payload[0].payload : payload[0];
    const label = !barChart ? data.name : data.label;

    return (
      <CustomTooltipContainer backgroundColor="white">
        <TextTooltip type="label">
          {label}: {data.value}
        </TextTooltip>
      </CustomTooltipContainer>
    );
  }

  return null;
};

export default CustomTooltip;
