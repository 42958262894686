import React from "react";

import { Tag, Text } from "./styles";
import { getTagColor, getTagTextColor } from "../../utils/utils";

interface Props {
  text?: string;
}

const CategoryCard = ({ text }: Props) =>
  text && (
    <Tag backgroundColor={getTagColor(text)}>
      <Text color={getTagTextColor(text)}>{text}</Text>
    </Tag>
  );

export default CategoryCard;
