import styled from "styled-components";

export const ColorPaletteContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    [theme.breakpoints.down(760)]: {
      alignItems: "center",
    },
  };
});

export const TopContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "32px",
}));

export const ResetButton = styled("button")(({ theme }) => {
  const {
    customSizes: {
      body: {
        description: { regular },
      },
    },
    customPalette: { grey },
    customFonts: { poppins },
  } = theme;
  return {
    fontSize: regular.fontSize,
    fontWeight: regular.fontWeight,
    lineHeight: regular.lineHeight,
    fontFamily: poppins,
    border: "none",
    cursor: "pointer",
    background: "none",
    color: grey,
    opacity: "50%",
    ":hover": {
      textDecoration: "underline",
    },
  };
});

export const ColorContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "424px",
}));

export const Subtitle = styled("h2")(({ theme }) => {
  const {
    customSizes: {
      body: {
        title2: { semibold },
      },
      textField: { text },
    },
    customPalette: { primary },
    customFonts: { poppinsSemiBold },
  } = theme;

  return {
    fontSize: semibold.fontSize,
    fontWeight: semibold.fontWeight,
    lineHeight: semibold.lineHeight,
    color: primary,
    fontFamily: poppinsSemiBold,
    margin: "0px",

    [theme.breakpoints.down(760)]: {
      fontSize: text.fontSize,
    },
  };
});
