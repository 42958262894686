import React, { useEffect, useState } from "react";

import {
  Container,
  InfoContainer,
  TitleContainer,
  WarningText,
  WarningContainer,
} from "./styles";
import TextInput from "components/textInput";
import { useAppDispatch, useAppSelector } from "redux/store";
import { clearApiError, setAdminInfo } from "redux/slices/authSlice";
import SettingsDetails from "components/settingsDetails";
import {
  EXAMPLE_EMAIL,
  FINAL_PDF,
  RECEIVER_EMAIL,
  SENDER_EMAIL,
  SEND_THE_USER_A_PDF_WITH_THE_ANSWERS,
  EMAIL_WARNING,
} from "utils/constants";
import { updateAdminInfo } from "pages/Settings/services/updateAdmin";
import ErrorModal from "../errorModal";
import { updateCompanyInfo } from "pages/Settings/services/updateCompany";
import { setCompany } from "redux/slices/companySlice";
import { WarningIcon } from "../../assets";

const PdfData = () => {
  const dispatch = useAppDispatch();
  const [adminSenderEmail, setAdminSenderEmail] = useState<string>("");
  const [resetFunction, setResetFunction] = useState(false);
  const [isReceiver] = useState(false);
  const [adminReceiverEmail, setAdminReceiverEmail] = useState<string>("");
  const { id, emailSender, emailReceiver, apiError } = useAppSelector(
    (state) => state.authSlice
  );
  const { id: companyId, sendBMCPDF } = useAppSelector(
    (state) => state.companySlice
  );
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    if (sendBMCPDF) {
      setChecked(true);
      setAdminSenderEmail(emailSender);
      setAdminReceiverEmail(emailReceiver);
    } else setChecked(false);
  }, [sendBMCPDF]);

  const switchHandler = async () => {
    if (checked) {
      resetPDFInfo();
      setChecked(false);
    } else {
      activatePDF();
      setChecked(true);
      setAdminSenderEmail(emailSender);
      setAdminReceiverEmail(emailReceiver);
    }
  };

  const activatePDF = async () => {
    setResetFunction(false);
    await updateCompanyInfo(companyId, {
      sendBMCPDF: true,
    });
  };

  const resetPDFInfo = async () => {
    setResetFunction(true);
    const updatedCompany = await updateCompanyInfo(companyId, {
      sendBMCPDF: false,
    });
    dispatch(setCompany(updatedCompany));
  };

  const handleSenderEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setAdminSenderEmail(e.target.value);

  const handleReceiverEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setAdminReceiverEmail(e.target.value);

  const isValidEmail = (email?: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  const handleBlur = async (action: string) => {
    setResetFunction(false);
    let body = null;

    if (action === "sender" && adminSenderEmail)
      body = {
        emailSender: adminSenderEmail,
      };
    else if (action === "receiver" && adminReceiverEmail)
      body = {
        emailReceiver: adminReceiverEmail,
      };

    const isValid = isValidEmail(body?.emailReceiver || body?.emailSender);

    if (body && isValid) {
      const updateAdmin = await updateAdminInfo(id, body);
      dispatch(setAdminInfo(updateAdmin));
    }
  };

  return (
    <Container>
      <TitleContainer>
        <SettingsDetails
          title={FINAL_PDF}
          subtitle={SEND_THE_USER_A_PDF_WITH_THE_ANSWERS}
          checked={checked}
          switchHandler={switchHandler}
        />
      </TitleContainer>
      {checked && (
        <InfoContainer>
          <TextInput
            placeholder={EXAMPLE_EMAIL}
            label={SENDER_EMAIL}
            value={adminSenderEmail}
            width="556px"
            onChange={handleSenderEmailChange}
            onBlur={() => handleBlur("sender")}
          />
          <TextInput
            placeholder={EXAMPLE_EMAIL}
            label={RECEIVER_EMAIL}
            value={adminReceiverEmail}
            width="556px"
            onChange={handleReceiverEmailChange}
            onBlur={() => handleBlur("receiver")}
          />
          <WarningContainer>
            <WarningIcon />
            <WarningText>{EMAIL_WARNING}</WarningText>
          </WarningContainer>
        </InfoContainer>
      )}
      {apiError && (
        <ErrorModal
          isOpen
          handleClose={() => dispatch(clearApiError())}
          handleTryAgain={() => {
            if (resetFunction) resetPDFInfo();
            else handleBlur(isReceiver ? "receiver" : "sender");
          }}
        />
      )}
    </Container>
  );
};

export default PdfData;
