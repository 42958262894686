import { AxiosResponse } from "axios";

import api from "../../../api";
import { clearApiError, setApiError } from "../../../redux/slices/surveySlice";
import store from "../../../redux/store";

interface BodyData {
  questionGroupId: number;
  nextGroupId: number;
  questionId?: number;
  questionOptionId?: number;
}

const { dispatch } = store;

export const createPathRule = async (body: BodyData) =>
  await api
    .post("/path-rules", body)
    .then((res: AxiosResponse) => {
      dispatch(clearApiError());
      return res?.data;
    })
    .catch((error) => {
      console.log("[createPathRule error]: ", error);
      dispatch(setApiError({ error: true }));
    });
