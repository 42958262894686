import React, { useEffect } from "react";

import { FONT_FAMILY, UPLOAD_FONT } from "../../utils/constants";
import {
  FileUploadLabel,
  FileUploadInput,
  FileUploadContainer,
  FileUploadButton,
  InputAdornment,
  UploadedFileContainer,
  ContentContainer,
  TextContainer,
  MessageContainer,
  Container,
  FontName,
  NameContainer,
} from "./styles";

import {
  UploadIcon,
  SuccessfulIcon,
  CrossIcon,
  PNGLogo,
  GreyCrossIcon,
} from "assets";

import useUploadStatus from "hooks/useUploadStatus";
import {
  Loader,
  UploadFileMessage,
  Text,
  ErrorMessage,
  LeftContainer,
} from "components/brandLogo/styles";

export interface FileInputProps {
  title: string;
  fontFile: string;
  resetFont: boolean;
  fontFamily: string;
  semiBold?: boolean;
  bold?: boolean;
}

const FileInput = ({
  title,
  fontFile,
  resetFont,
  fontFamily,
  semiBold,
  bold,
}: FileInputProps) => {
  const {
    uploadFontStatus,
    isSuccessful,
    errorMessage,
    uploadedFont,
    setUploadedFont,
    resetFontUploadStatus,
    handleRemoveFont,
    handleFontUpload,
    resetFontStatus,
  } = useUploadStatus();

  useEffect(() => {
    if (resetFont) resetFontStatus();
  }, [resetFont]);

  let fontFamilyKey = "regularFontFile";
  if (semiBold) fontFamilyKey = "semiBoldFontFile";
  if (bold) fontFamilyKey = "boldFontFile";

  useEffect(() => {
    if (fontFile !== "null") setUploadedFont(fontFile);
  }, [fontFile, setUploadedFont]);

  const handleFileInput = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files ? event.target.files[0] : null;

    handleFontUpload(file, fontFamilyKey);
  };

  useEffect(() => {
    if (isSuccessful) setTimeout(resetFontUploadStatus, 2000);
  }, [isSuccessful]);

  return (
    <>
      <FileUploadContainer>
        <NameContainer>
          <Text>{FONT_FAMILY}: </Text>
          <FontName fontFamily={fontFamily}> {title}</FontName>
        </NameContainer>
        {uploadedFont ? (
          <Container>
            <UploadedFileContainer>
              <ContentContainer>
                <TextContainer>
                  <Text>{uploadedFont}</Text>
                </TextContainer>
              </ContentContainer>
              <InputAdornment
                className="pointer"
                onClick={async () => await handleRemoveFont(fontFamilyKey)}
              >
                <GreyCrossIcon />
              </InputAdornment>
            </UploadedFileContainer>
          </Container>
        ) : (
          <>
            <FileUploadButton>
              <InputAdornment>
                <UploadIcon />
              </InputAdornment>
              <FileUploadLabel htmlFor={title}>{UPLOAD_FONT}</FileUploadLabel>
              <FileUploadInput
                id={title}
                type="file"
                onChange={handleFileInput}
              />
            </FileUploadButton>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </>
        )}
      </FileUploadContainer>
      {uploadFontStatus && (
        <MessageContainer>
          <LeftContainer>
            {!isSuccessful && <Loader />}
            {isSuccessful && (
              <InputAdornment>
                <SuccessfulIcon />
              </InputAdornment>
            )}

            <UploadFileMessage>{uploadFontStatus}</UploadFileMessage>
          </LeftContainer>
          {isSuccessful && (
            <InputAdornment onClick={resetFontUploadStatus} className="pointer">
              <CrossIcon />
            </InputAdornment>
          )}
        </MessageContainer>
      )}
    </>
  );
};

export default FileInput;
