import { BlankPencilIcon, DarkCross, TickIcon } from "assets";
import styled from "styled-components";

export const Container = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "32px",
  };
});

export const InfoContainer = styled(Container)(({ theme }) => {
  return {
    paddingTop: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };
});

export const StyledPencilIcon = styled(BlankPencilIcon)(({ theme }) => {
  return {
    marginLeft: "16px",
    cursor: "pointer",
  };
});

export const IconContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "8px",
    gap: "8px",
  };
});

export const StyledTickIcon = styled(TickIcon)(({ theme }) => {
  return {
    cursor: "pointer",
    path: {
      fill: theme.customPalette.success,
    },
  };
});

export const StyledCrossIcon = styled(DarkCross)(({ theme }) => {
  return {
    cursor: "pointer",
    path: {
      fill: theme.customPalette.error,
    },
  };
});
