import { MenuItem, menuClasses } from "@mui/material";
import { StyledSelect, StyledLabel, ValueContainer } from "./styles";
import theme from "../../definitions/theme";

import { ExpandMore } from "../../assets";

export type Option = {
  key: number;
  value: string;
};

export interface CustomSelectProps {
  selectedValues: Option[];
  handleValueClick: (value: number) => void;
  options: Record<number, string>;
  isModal?: boolean;
  placeholder?: string;
}

const CustomSelect = ({
  selectedValues,
  handleValueClick,
  options,
  isModal = false,
  placeholder = "Select an option",
}: CustomSelectProps) => {
  const handleSelectClick = (key: number) => {
    handleValueClick(key);
  };

  return (
    <StyledSelect
      isModal={isModal}
      disableUnderline
      variant="standard"
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        sx: {
          maxWidth: "450px",
          alignItems: "center",
          marginTop: "4px",
          cursor: "pointer",
          [`& .${menuClasses.paper}`]: {
            borderRadius: "7px",
            border: `2px solid ${theme.customPalette.secondary}`,
          },
          [`& .${menuClasses.list}`]: {
            paddingTop: 0,
            paddingBottom: 0,
            background: "white",
            "& li.Mui-selected": {
              color: "white",
              backgroundColor: "transparent !important",
            },
            "& li.Mui-selected:hover": {
              backgroundColor: "transparent !important",
            },
          },
        },
      }}
      value={selectedValues.map((option) => option.key)}
      renderValue={() => (
        <ValueContainer isPlaceholder={selectedValues.length === 0}>
          {selectedValues.length > 0
            ? selectedValues.map((option) => option.value).join(", ")
            : placeholder}{" "}
        </ValueContainer>
      )}
      displayEmpty
      IconComponent={ExpandMore}
    >
      {Object.entries(options).map(([key, value]) => (
        <MenuItem
          key={key}
          style={{
            cursor: "pointer",
            wordWrap: "break-word",
            whiteSpace: "wrap",
            overflow: "hidden",
          }}
          disableRipple
          onClick={() => handleSelectClick(Number(key))}
        >
          <StyledLabel>{value}</StyledLabel>
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export default CustomSelect;
