import styled from "styled-components";

export const TopContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  marginBottom: "32px",
  gap: "16px",
  width: "370px",
}));

export const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "424px",
  paddingTop: "56px",
  paddingBottom: "56px",
}));

export const Subtitle = styled("h2")(({ theme }) => {
  const {
    customSizes: {
      body: {
        title2: { semibold },
      },
      textField: { text },
    },
    customPalette: { primary },
    customFonts: { poppinsSemiBold },
  } = theme;

  return {
    fontSize: semibold.fontSize,
    fontWeight: semibold.fontWeight,
    lineHeight: semibold.lineHeight,
    color: primary,
    fontFamily: poppinsSemiBold,
    margin: "0px",

    [theme.breakpoints.down(760)]: {
      fontSize: text.fontSize,
    },
  };
});

export const FileUploadContainer = styled("div")(({ theme }) => {
  const {
    customPalette: { hover, dottedBorder, darkTransparent },
  } = theme;

  return {
    display: "flex",
    flexDirection: "column",
    width: "370px",
    height: "217px",
    border: `1px dashed ${dottedBorder}`,
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "32px",
    backgroundColor: darkTransparent,
    "&.dragging": {
      borderColor: hover,
      transition: "border-color 0.3s ease",
    },
  };
});

export const FileUploadButton = styled("div")(({ theme }) => {
  const {
    colors: { white },
    customPalette: { secondary },
  } = theme;
  return {
    width: "337px",
    height: "48px",
    borderRadius: "8px",
    backgroundColor: white,
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    border: `1px solid ${secondary}`,
  };
});

export const LabelContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "191px",
    height: "73px",
    textAlign: "center",
  };
});

export const FileUploadLabel = styled("label")(({ theme }) => {
  const {
    customSizes: {
      textField: {
        placeholder: { fontSize, fontWeight, lineHeight },
      },
    },
    customPalette: { secondGray },
    customFonts: { poppins },
  } = theme;
  return {
    fontSize,
    fontWeight,
    lineHeight,
    fontFamily: poppins,
    color: secondGray,
  };
});

export const UploadLinkText = styled("span")(({ theme }) => {
  const {
    customSizes: {
      textField: {
        placeholder: { fontSize, fontWeight, lineHeight },
      },
    },
    customPalette: { secondary },
    customFonts: { poppins },
  } = theme;
  return {
    fontSize,
    fontWeight,
    lineHeight,
    fontFamily: poppins,
    color: secondary,
    textDecoration: "underline",
    cursor: "pointer",
  };
});

export const ContentContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px",
    padding: "16px",
  };
});

export const TextContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
  };
});

export const AclarationText = styled("label")(({ theme }) => {
  const {
    customSizes: {
      tag: {
        text: { fontSize, fontWeight, lineHeight },
      },
    },
    customPalette: { grey },
    customFonts: { poppins },
  } = theme;
  return {
    fontSize,
    fontWeight,
    lineHeight,
    fontFamily: poppins,
    color: grey,
  };
});

export const FileUploadInput = styled("input")(({ theme }) => {
  return {
    display: "none",
  };
});

export const InputAdornment = styled("div")(({ theme }) => {
  return {
    display: "flex",
    "&.pointer": {
      cursor: "pointer",
    },
  };
});

export const Text = styled("label")<{ description?: boolean }>(
  ({ theme, description }) => {
    const {
      customSizes: {
        body: {
          smallDescription: {
            semibold: { fontSize, fontWeight, lineHeight },
          },
        },
      },
      customPalette: { darkMediumGrey, primary },
      customFonts: { poppins },
    } = theme;

    return {
      fontSize,
      fontWeight,
      lineHeight,
      color: description ? darkMediumGrey : primary,
      fontFamily: poppins,
      marginBottom: "16px",
      width: description ? "370px" : "auto",
    };
  }
);

export const MessageContainer = styled("div")(({ theme }) => {
  const {
    customPalette: { darkGrey },
  } = theme;

  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px",
    backgroundColor: darkGrey,
    borderRadius: "8px",
    height: "49px",
    width: "294px",
    position: "fixed",
    top: "80%",
    left: "673px",
    justifyContent: "space-between",
    paddingRight: "16px",
    paddingLeft: "16px",
    paddingTop: "8px",
    paddingBottom: "8px",
  };
});

export const UploadFileMessage = styled("h1")(({ theme }) => {
  const {
    customSizes: {
      uploadStatus: { fontSize, fontWeight, lineHeight },
    },
    customPalette: { white },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    color: white,
    fontFamily: poppins,
  };
});

export const Loader = styled("div")(({ theme }) => {
  const {
    customPalette: { darkGrey, white, mediumGrey },
  } = theme;

  return {
    position: "relative",
    border: `2px solid ${mediumGrey}`,
    borderTop: `2px solid ${white}`,
    width: "20px",
    height: "20px",
    borderRadius: "50%",

    animation: "spin 2s linear infinite",
    backgroundColor: darkGrey,
    "&::before": {
      content: '""',
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",

      borderRadius: "50%",
      boxSizing: "border-box",
      animation: "spin-border 2s linear infinite",
    },
    "@keyframes spin": {
      "0%": { transform: "rotate(0deg)" },
      "100%": { transform: "rotate(360deg)" },
    },
    "@keyframes spin-border": {
      "0%": { transform: "rotate(0deg)" },
      "100%": { transform: "rotate(360deg)" },
    },
  };
});

export const LeftContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
  width: "100%",
}));

export const UploadedFileContainer = styled("div")(({ theme }) => {
  const {
    customPalette: { darkTransparent, mediumLightGrey },
  } = theme;

  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: darkTransparent,
    borderRadius: "7px",
    height: "79px",
    width: "338px",
    position: "absolute",
    justifyContent: "space-between",
    border: `1px solid ${mediumLightGrey}`,
    paddingRight: "16px",
  };
});

export const ErrorMessage = styled("h1")(({ theme }) => {
  const {
    customSizes: {
      tag: {
        text: { fontSize, fontWeight, lineHeight },
      },
    },
    customPalette: { error },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    color: error,
    fontFamily: poppins,
    margin: "0px",
  };
});
