import styled from "styled-components";
import { TextField } from "@mui/material";

export const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

export const TopContainer = styled("div")({
  display: "flex",
  maxWidth: "740px",
  width: "100%",
  flexDirection: "row",
  gap: "8px",
});

export const SelectInputContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  position: "relative",
  justifyContent: "flex-end",
  maxWidth: "320px",
  width: "80%"
});

export const SelectInput = styled("input")(({ theme }) => {
  const {
    typography: { fontFamily },
    customSizes: {
      textAreaInput: {
        desktop: { placeholder: textDesktop },
      },
    },
    customPalette: {
      textAreaInput: { border, activeText },
    },
  } = theme;
  return {
    fontFamily: `${fontFamily} !important`,
    fontWeight: textDesktop.fontWeight,
    color: activeText,
    border: `2px solid ${border}`,
    width: "320px",
    height: "48px",
    backgroundColor: theme.customPalette.darkTransparent,
    borderColor: theme.customPalette.mediumLightGrey,
    borderRadius: "8px",
    pointerEvents: "none",
  };
});

export const IconContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    position: "absolute",
    marginRight: "16px",
    color: theme.customPalette.darkGrey,
  };
});

export const StyledTextField = styled(TextField)(({ theme }) => {
  return {
    cursor: "initial",
    width: "280px",

    ".MuiInputBase-root": {
      padding: "0",
      borderRadius: "8px",
      height: "48px",
    },

    ".Mui-disabled": {
      ".MuiOutlinedInput-notchedOutline": {
        border: `2px solid ${theme.customPalette.textField.disabled.border} !important`,
      },
      ".MuiInputBase-input": {
        "-webkit-text-fill-color": `${theme.customPalette.darkMediumGrey}`,
      },
    },
  };
});
