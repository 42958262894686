import styled from "styled-components";

export const Container = styled("div")({
  display: "flex",
  alignItems: "center",
  paddingBottom: "16px",
  gap: "16px",
});

export const ColumnContainer = styled("div")<{
  flexGrow?: number;
}>(({ flexGrow = 1 }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  flexGrow,
}));

export const Text = styled("label")(({ theme }) => {
  const {
    customSizes: {
      card: {
        desktop: {
          text: { fontSize, fontWeight, lineHeight },
        },
      },
    },
    customPalette: { darkMediumGrey },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    color: darkMediumGrey,
    fontFamily: poppins,
  };
});

export const Placeholder = styled("div")({
  height: "22px",
});
