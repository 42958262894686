import { AxiosResponse } from "axios";

import api from "../../../api";
import {
  clearApiError,
  setApiError,
  setBranding,
} from "../../../redux/slices/companySlice";
import store from "../../../redux/store";

export interface BodyData {
  primaryColor?: string;
  secondaryColor?: string;
  tagColor?: string;
  linkColor?: string;
  primaryTextColor?: string;
  homePageTextColor?: string;
  secondaryTextColor?: string;
  backgroundColor?: string;
  logo?: File;
  regularFontFile?: File | string;
  semiBoldFontFile?: File | string;
  boldFontFile?: File | string;
}

const { dispatch } = store;

export const updateBranding = async (body: BodyData) => {
  let formData = new FormData();

  if (body) {
    Object.entries(body).forEach(([key, value]) => {
      if (value instanceof File) {
        formData.append(key, value, value.name);
      } else {
        if (value === null) formData.append(key, "");
        else formData.append(key, value);
      }
    });
  }

  return await api
    .put(`/branding`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res: AxiosResponse) => {
      dispatch(setBranding(res?.data));
      dispatch(clearApiError());
    })
    .catch((error) => {
      console.log("[updateBranding error]: ", error);
      setApiError({ error: true });
    });
};
