import React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { IconButton } from "@mui/material";

import Sidebar from "../sidebar";
import { Container, HeaderLogo, MobileHeader, StyledDrawer } from "./styles";
import { MenuIcon } from "../../assets";

const drawerWidth = 200;
interface Props {
  children: any;
  setSelectedSubSection?: (value: string) => void;
  selectedSubsection?: string;
}

const PageWrapper = ({
  children,
  setSelectedSubSection,
  selectedSubsection,
}: Props) => {
  const selectedIcon = `/${window.location.pathname.split("/")[1]}`;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <MobileHeader>
        <HeaderLogo />
        <IconButton
          edge="end"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
      </MobileHeader>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <StyledDrawer
          variant="temporary"
          anchor="right"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{ display: { xs: "block", sm: "none" } }}
        >
          <Sidebar
            selectedIcon={selectedIcon}
            setSelectedSubSection={setSelectedSubSection}
            selectedSubsection={selectedSubsection}
          />
        </StyledDrawer>
        <StyledDrawer
          variant="permanent"
          sx={{ display: { xs: "none", sm: "block" } }}
          open
        >
          <Sidebar
            selectedIcon={selectedIcon}
            setSelectedSubSection={setSelectedSubSection}
            selectedSubsection={selectedSubsection}
          />
        </StyledDrawer>
      </Box>
      <Container
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {children}
      </Container>
    </Box>
  );
};

export default PageWrapper;
