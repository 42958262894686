import { Autocomplete, styled, TextField, Theme } from "@mui/material";

import theme from "../../definitions/theme";

const getTextColor = (
  disabled: boolean | undefined,
  error: boolean | undefined,
  theme: Theme
) => {
  return disabled
    ? theme.customPalette.textField.disabled.text
    : error
    ? theme.customPalette.textField.error.text
    : theme.customPalette.textField.default.text;
};

const getBorderHoverColor = (
  disabled: boolean | undefined,
  error: boolean | undefined,
  theme: Theme
) => {
  return disabled
    ? theme.customPalette.textField.disabled.border
    : error
    ? theme.customPalette.error
    : theme.customPalette.textField.hover.border;
};

interface InputProps {
  id?: string;
  placeholder: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  disabled?: boolean;
  error?: boolean;
  width?: number | string;
  label?: string;
  type?: string;
  hasError?: boolean;
}

export const StyledAutocomplete = styled(Autocomplete)<{ hasError: boolean }>(
  ({ hasError }) => {
    return {
      ".MuiOutlinedInput-notchedOutline": {
        border: `2px solid ${
          hasError
            ? theme.customPalette.error
            : theme.customPalette.mediumLightGrey
        }`,
        borderRadius: "7px",
        "&:hover": {
          borderColor: `${
            hasError
              ? theme.customPalette.error
              : theme.customPalette.mediumLightGrey
          }`,
        },

        "&:focus": {
          borderColor: `${
            hasError
              ? theme.customPalette.error
              : theme.customPalette.mediumLightGrey
          }`,
          color: "#1B1B1B",
        },
      },
    };
  }
);

export const StyledTextField = styled(TextField)<InputProps>(
  ({ disabled, hasError }) => {
    return {
      ".MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: `2px solid ${getBorderHoverColor(
            disabled,
            hasError,
            theme
          )} `,
        },
      },

      ".MuiInputBase-input": {
        fontSize: theme.customSizes.textField.placeholder.fontSize,
        fontWeight: theme.customSizes.textField.placeholder.fontWeight,
        fontFamily: theme.customFonts.poppins,
        lineHeight: theme.customSizes.textField.placeholder.lineHeight,
        color: getTextColor(disabled, hasError, theme),
      },

      "&.MuiOutlinedInput-notchedOutline": {
        borderRadius: "7px",
        borderColor: `${hasError ? theme.customPalette.error : "#DCDCDC"}`,
      },

      ":hover": {
        ".MuiOutlinedInput-notchedOutline": {
          border: `2px solid ${getBorderHoverColor(
            disabled,
            hasError,
            theme
          )} !important`,
        },
        color: `${theme.customPalette.textField.hover.text}`,
      },

      ".Mui-focused": {
        ".MuiOutlinedInput-notchedOutline": {
          border: `2px solid
          ${getBorderHoverColor(disabled, hasError, theme)} !important`,
        },
        ".MuiInputBase-input": {
          color: `${theme.customPalette.textField.active.text}`,
        },
        color: `${theme.customPalette.textField.active.text}`,
        borderColor: `${
          disabled
            ? theme.customPalette.textField.disabled.border
            : theme.customPalette.textField.active.border
        }`,
      },
    };
  }
);

export const Container = styled("div")(() => {
  return {
    display: "flex",
    flexDirection: "column",
    gap: "7px",
    height: "fit-content",
    width: "100%",
    color: theme.customPalette.darkMediumGrey,
    fontSize: "14px",
  };
});

export const TagsContainer = styled("div")(({}) => {
  return {
    display: "flex",
    width: "100%",
    marginTop: "8px",
    flexWrap: "wrap",
    gap: "8px",
  };
});

export const Label = styled("p")(() => {
  return {
    fontFamily: theme.customFonts.poppins,
    fontSize: theme.customSizes.tagLabel.fontSize,
    fontWeight: theme.customSizes.tagLabel.fontWeight,
    lineHeight: theme.customSizes.tagLabel.lineHeight,
    color: theme.customPalette.mediumGrey,
    padding: "0px",
    margin: "0px",
    marginTop: "8px",
  };
});

export const AddTagLabel = styled("p")(() => {
  return {
    fontFamily: theme.customFonts.poppins,
    fontSize: theme.customSizes.tagLabel.fontSize,
    fontWeight: theme.customSizes.body.smallDescription.semibold.fontWeight,
    lineHeight: theme.customSizes.tagLabel.lineHeight,
    color: theme.customPalette.primary,
    padding: "0px",
    margin: "0px",
    marginTop: "8px",
  };
});

export const InputContainer = styled("div")({
  marginLeft: "32px",
  width: "680px",
});
