import styled from "styled-components";

export const Container = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "32px",
  };
});

export const InfoContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    paddingTop: "8px",
  };
});

export const InputAdornment = styled("div")(({ theme }) => {
  return {
    display: "flex",
    "&.pointer": {
      cursor: "pointer",
    },
  };
});

export const LabelContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "191px",
    height: "73px",
    textAlign: "center",
  };
});

export const FileUploadContainer = styled("div")(({ theme }) => {
  const {
    customPalette: { hover, dottedBorder, darkTransparent },
  } = theme;

  return {
    display: "flex",
    flexDirection: "column",
    width: "556px",
    height: "302px",
    border: `1px dashed ${dottedBorder}`,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: darkTransparent,
    "&.dragging": {
      borderColor: hover,
      transition: "border-color 0.3s ease",
    },
  };
});

export const UploadLinkText = styled("span")(({ theme }) => {
  const {
    customSizes: {
      textField: {
        placeholder: { fontSize, fontWeight, lineHeight },
      },
    },
    customPalette: { secondary },
    customFonts: { poppins },
  } = theme;
  return {
    fontSize,
    fontWeight,
    lineHeight,
    fontFamily: poppins,
    color: secondary,
    textDecoration: "underline",
    cursor: "pointer",
    display: "block",
  };
});

export const FileUploadInput = styled("input")(({ theme }) => {
  return {
    display: "none",
  };
});

export const FileUploadLabel = styled("label")(({ theme }) => {
  const {
    customSizes: {
      textField: {
        placeholder: { fontSize, fontWeight, lineHeight },
      },
    },
    customPalette: { secondGray },
    customFonts: { poppins },
  } = theme;
  return {
    fontSize,
    fontWeight,
    lineHeight,
    fontFamily: poppins,
    color: secondGray,
    display: "block",
  };
});

export const TextContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
  };
});

export const AclarationText = styled("label")(({ theme }) => {
  const {
    customSizes: {
      tag: {
        text: { fontSize, fontWeight, lineHeight },
      },
    },
    customPalette: { grey },
    customFonts: { poppins },
  } = theme;
  return {
    fontSize,
    fontWeight,
    lineHeight,
    fontFamily: poppins,
    color: grey,
  };
});

export const UploadedFileContainer = styled("div")(({ theme }) => {
  const {
    customPalette: { darkTransparent, mediumLightGrey },
  } = theme;

  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: darkTransparent,
    borderRadius: "7px",
    height: "79px",
    width: "500px",
    position: "absolute",
    justifyContent: "space-between",
    border: `1px solid ${mediumLightGrey}`,
    paddingRight: "16px",
  };
});

export const MessageContainer = styled("div")(({ theme }) => {
  const {
    customPalette: { darkGrey },
  } = theme;

  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px",
    backgroundColor: darkGrey,
    borderRadius: "8px",
    height: "49px",
    width: "294px",
    position: "fixed",
    top: "90%",
    left: "673px",
    justifyContent: "space-between",
    padding: "16px",
    paddingTop: "8px",
    paddingBottom: "8px",
  };
});

export const LeftContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
  width: "100%",
}));
