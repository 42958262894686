import { useState, ChangeEvent, useEffect } from "react";
import { useDebounce } from "use-debounce";

import { createQuestionOption } from "../pages/SurveyDetails/services/createQuestionOption";
import { deleteQuestionOption } from "../pages/SurveyDetails/services/deleteQuestionOption";
import { updateQuestion } from "../pages/SurveyDetails/services/updateQuestion";
import { QuestionType } from "../utils/utils";
import {
  QuestionGroupState,
  QuestionOptionState,
  QuestionState,
} from "../types";

export interface DataType {
  type: QuestionType;
  updated_at: string;
  required: boolean;
  questionText: string;
  index: number;
  label?: string;
  questionSubtitleText?: string;
  currency?: string;
  questionOptions?: QuestionOptionState[];
  arrayIndex: number;
  questionGroup?: QuestionGroupState;
}

interface CardLogicHookProps {
  id: number;
  initialData: DataType;
  hasOptions?: boolean;
  setQuestions: (value: React.SetStateAction<QuestionState[]>) => void;
}

const useCardLogic = ({
  id,
  initialData,
  hasOptions = true,
  setQuestions,
}: CardLogicHookProps) => {
  const [options, setOptions] = useState<QuestionOptionState[]>(
    initialData.questionOptions?.length ? initialData.questionOptions : []
  );
  const [checked, setChecked] = useState<boolean>(initialData.required);
  const [title, setTitle] = useState<string>(initialData.questionText);
  const [subtitle, setSubtitle] = useState<string>(
    initialData.questionSubtitleText
  );
  const [firstRender, setFirstRender] = useState<boolean>(false);
  const [newData, setNewData] = useState<DataType>(initialData);

  const DEBOUNCE_DELAY = 1000;
  const [debouncedTitle] = useDebounce(title, DEBOUNCE_DELAY);
  const [debouncedSubtitle] = useDebounce(subtitle, DEBOUNCE_DELAY);
  const [debouncedOptions] = useDebounce(options, DEBOUNCE_DELAY);

  const switchHandler = (event: ChangeEvent<HTMLInputElement>) =>
    setChecked(event.target.checked);

  const onClickAddButton = async () => {
    const response = await createQuestionOption(id, { optionText: "" });

    setOptions((prevState) => [...prevState, response]);
  };

  const onChangeLabel = (
    event: ChangeEvent<HTMLInputElement>,
    idToChange: number
  ) =>
    setOptions((prevState) =>
      prevState.map((option) =>
        option.id === idToChange
          ? { ...option, optionText: event.target.value }
          : option
      )
    );

  const onClickDeleteButton = async (idToDelete: number) => {
    await deleteQuestionOption(idToDelete);

    setOptions((prevState) => prevState.filter(({ id }) => id !== idToDelete));
  };

  const onChangeTitle = (event: ChangeEvent<HTMLInputElement>) =>
    setTitle(event.target.value);

  const onChangeSubtitle = (event: ChangeEvent<HTMLInputElement>) =>
    setSubtitle(event.target.value);

  const updateQuestionCall = async () => {
    const updatedQuestionData = await updateQuestion(id, {
      questionText: debouncedTitle,
      questionSubtitleText: debouncedSubtitle,
      required: checked,
      type: initialData.type,
      questionOptions: hasOptions ? debouncedOptions : null,
    });

    delete updatedQuestionData.id;
    setNewData({ ...updatedQuestionData, arrayIndex: initialData.arrayIndex });

    setQuestions((prevState) => {
      let newState = [...prevState];
      let index = prevState.findIndex(({ id: prevId }) => prevId === id);
      let newQuestion = { ...updatedQuestionData, id };
      newState[index] = newQuestion;

      return newState;
    });
  };

  useEffect(() => {
    const optionsChanged =
      JSON.stringify(debouncedOptions) !==
      JSON.stringify(newData.questionOptions);
    const hasChanged =
      debouncedTitle !== newData.questionText ||
      debouncedSubtitle !== newData.questionSubtitleText ||
      checked !== newData.required ||
      optionsChanged;

    if (hasChanged && firstRender) updateQuestionCall();
  }, [debouncedTitle, debouncedSubtitle, checked, debouncedOptions]);

  useEffect(() => {
    setFirstRender(true);
  }, []);

  return {
    options,
    checked,
    title,
    subtitle,
    debouncedTitle,
    debouncedSubtitle,
    debouncedOptions,
    switchHandler,
    onClickAddButton,
    onChangeLabel,
    onClickDeleteButton,
    onChangeTitle,
    onChangeSubtitle,
    newData,
  };
};

export default useCardLogic;
