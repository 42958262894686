import styled from "styled-components";

export const RowContainer = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "16px",
  };
});

export const Container = styled("div")(({ theme }) => {
  const {
    customPalette: { mediumLightGrey: defaultBorder },
  } = theme;

  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    border: `2px solid ${defaultBorder}`,
    borderRadius: "7px",
    height: "32px",
    padding: "8px",
  };
});

interface IconProps {
  disabled?: boolean;
  plus?: boolean;
}

export const Icon = styled("button")<IconProps>(({ theme, disabled, plus }) => {
  const {
    customPalette: { hover: defaultColor, tagsActive: disabledColor },
  } = theme;

  return {
    all: "unset",
    color: disabled ? disabledColor : defaultColor,
    fontWeight: 400,
    fontSize: "24px",
    cursor: !disabled && "pointer",
    marginBottom: plus ? "2px" : "0",
    "-webkit-tap-highlight-color": "transparent",
  };
});

export const Label = styled("label")(({ theme }) => {
  const {
    customPalette: { darkGrey },
    customFonts: { poppins },
  } = theme;

  return {
    marginLeft: "8px",
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "20px",
    color: darkGrey,
    fontFamily: poppins,
  };
});

export const Input = styled("input")(({ theme }) => ({
  all: "unset",
  fontSize: "14px",
  width: "50px",
  textAlign: "center",
  "&.disabled": {
    color: `${theme.customPalette.disabled}`,
  },
}));

export const LabelInput = styled("input")(({ theme }) => {
  const {
    customPalette: {
      textField: { hover },
      darkGrey,
    },
    customFonts: { poppins },
  } = theme;

  return {
    all: "unset",
    width: 652,
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "20px",
    color: darkGrey,
    fontFamily: poppins,
    paddingLeft: "15px",
    border: "2px solid transparent",
    borderRadius: "4px",
    ":hover": {
      border: `2px solid ${hover.border}`,
      borderRadius: "4px",
    },
  };
});

export const IconContainer = styled("div")(() => {
  return {
    display: "flex",
    marginLeft: "8px",
  };
});
