import styled from "styled-components";
import { CopyPasteIcon } from "assets";

export const Container = styled("div")(({ theme }) => {
  const {
    customPalette: {
      card: { boxShadow },
    },
  } = theme;

  return {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: "8px 8px 16px 30px",
    backgroundColor: theme.customPalette.white,
    borderRadius: "10px",
    boxShadow: boxShadow,
    marginTop: "16px",
  };
});

export const SummaryContainer = styled("div")(() => ({
  alignItems: "center",
  justifyContent: "center",
  width: "95%",
  height: "100%",
}));

export const HeaderContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  justifyContent: "space-between",
}));

export const IconContainer = styled("div")<{ width: string }>(({ width }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width,

  "&.title": {
    justifyContent: "flex-start",
    display: "flex",
  },
}));

export const ContentContainer = styled("div")(() => ({
  justifyContent: "center",
  width: "90%",
  padding: "16px",
}));

export const Text = styled("p")(({ theme }) => {
  const {
    customPalette: { primary },
    customSizes: {
      body: {
        description: {
          regular: { fontSize, lineHeight, fontWeight },
        },
      },
    },
  } = theme;

  return {
    fontSize,
    lineHeight,
    fontWeight,
    marginBottom: "20px",
    color: primary,

    "&.title": {
      paddingLeft: "10px",
      fontSize: "16px",
      fontWeight: "600",
    },
  };
});

export const HorizontalBorder = styled(Text)(({ theme }) => ({
  borderBottom: `1px solid ${theme.customPalette.mediumGrey}`,
  paddingBottom: "10px",
}));

export const ButtonContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  justifyContent: "flex-end",
}));

export const CustomButton = styled("button")(({ theme }) => {
  const {
    customPalette: { white, secondary },
    customSizes: {
      button: {
        primary: { fontSize, fontWeight, lineHeight },
      },
    },
  } = theme;

  return {
    display: "flex",
    alignItems: "center",
    border: "none",
    width: "fit-content",
    backgroundColor: white,
    fontSize,
    fontWeight,
    lineHeight,
    color: secondary,
    justifyContent: "space-between",
    gap: "5px",
    cursor: "pointer",
  };
});

export const StyledCopyPasteIcon = styled(CopyPasteIcon)<{
  disabled?: boolean;
}>(({ theme }) => {
  return {
    alignItems: "center",
    cursor: "pointer",
    path: {
      stroke: theme.customPalette.secondary,
    },
  };
});

export const Loader = styled("div")(({ theme }) => {
  const {
    customPalette: { white },
  } = theme;

  return {
    position: "relative",
    border: `2px solid ${white}`,
    borderTop: `2px solid ${white}`,
    width: "20px",
    height: "20px",
    borderRadius: "50%",

    animation: "spin 2s linear infinite",
    "&.blue": {
      border: `2px solid ${theme.customPalette.secondary}`,
      borderTop: `2px solid ${theme.customPalette.secondary}`,
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",

      borderRadius: "50%",
      boxSizing: "border-box",
      animation: "spin-border 2s linear infinite",
    },
    "@keyframes spin": {
      "0%": { transform: "rotate(0deg)" },
      "100%": { transform: "rotate(360deg)" },
    },
    "@keyframes spin-border": {
      "0%": { transform: "rotate(0deg)" },
      "100%": { transform: "rotate(360deg)" },
    },
  };
});
