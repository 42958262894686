import React, { ChangeEvent } from "react";

import { IconButtonStyled, LabelInput, StyledTag } from "./styles";
import { DeleteIcon } from "../../assets";

export interface TagProps {
  labelValue: string;
  hasMargin?: boolean;
  id: number;
  onChange?: (event: ChangeEvent<HTMLInputElement>, idToChange: number) => void;
  onClickDeleteIcon?: (idToDelete: number) => void;
}

const Tag = ({
  id,
  labelValue,
  hasMargin = true,
  onClickDeleteIcon,
  onChange,
}: TagProps) => {
  return (
    <StyledTag variantType="default" hasMargin={hasMargin}>
      <LabelInput
        type="text"
        value={labelValue}
        onChange={(e) => onChange(e, id)}
        placeholder="Edit text"
        style={{
          width: `${labelValue.length * 0.9}ch`,
        }}
      />
      <IconButtonStyled onClick={() => onClickDeleteIcon(id)}>
        <DeleteIcon />
      </IconButtonStyled>
    </StyledTag>
  );
};

export default Tag;
