import {
  IconButton,
  IconButtonProps,
  Menu,
  MenuItem,
  Switch,
} from "@mui/material";
import styled from "styled-components";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

export const Container = styled("div")(({ theme }) => {
  const {
    customPalette: {
      card: { boxShadow },
    },
  } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    backgroundColor: theme.customPalette.white,
    width: "100%",
    borderRadius: "16px",
    boxShadow: boxShadow,
    marginTop: "8px",
    paddingTop: "16px",
    paddingBottom: "8px",
    marginBottom: "16px",
  };
});

export const Text = styled("h1")(({ theme }) => {
  const {
    customPalette: { darkMediumGrey, secondary },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "24px",
    color: darkMediumGrey,
    fontFamily: poppins,
    opacity: "60%",
    marginTop: 0,
    paddingLeft: "50px",

    "&.footer": {
      fontSize: "13px",
      opacity: "100%",
      alignSelf: "center",
      marginBottom: 0,
      paddingLeft: 0,
    },

    "&.link": {
      fontSize: "13px",
      color: secondary,
      padding: 0,
      margin: 0,
      opacity: "100%",
    },
  };
});

export const Footer = styled("div")(() => {
  return {
    display: "flex",
    width: "100%",
    height: "40px",
    marginBottom: "8px",
    marginTop: "24px",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: "48px",

    "&.add-button": {
      justifyContent: "space-between",
    },
  };
});

export const SwitchStyled = styled(Switch)(({ theme }) => {
  const {
    customPalette: { secondary, mediumLightGrey, white, lightGrey },
  } = theme;

  return {
    "&.MuiSwitch-root": {
      width: "33px",
      height: "17px",
      padding: 0,
    },
    "& .MuiSwitch-switchBase": {
      position: "absolute",
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: white,
        "& + .MuiSwitch-track": {
          backgroundColor: secondary,
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        border: `6px solid ${white}`,
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: mediumLightGrey,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: "13px",
      height: "13px",
      backgroundColor: white,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: lightGrey,
      opacity: 1,
      width: "33px",
      height: "17px",
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  };
});

export const LinkContainer = styled("div")(({ theme }) => {
  const {
    customPalette: { secondary },
  } = theme;
  return {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginLeft: "40px",
    path: {
      fill: secondary,
    },
  };
});

export const Content = styled("div")(() => {
  return {
    padding: "0 48px 0 48px",

    "&.subtitle": {
      padding: "0 96px 0 0px",
    },
  };
});

export const IconContainer = styled("div")(() => {
  return {
    minWidth: "26px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "16px",
    marginRight: "8px",

    "&.question-icon": {
      marginLeft: "8px",
      marginRight: "0px",
    },
  };
});

export const RowIconContainer = styled("div")(() => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "16px",
  };
});

export const RowLeftContainer = styled("div")({
  display: "flex",
  width: "100%",
  maxWidth: "720px",
});

export const RowContainer = styled("div")(() => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&.input": {
      width: "100%",
    },
  };
});

export const MenuStyled = styled(Menu)(({ theme }) => {
  const {
    customPalette: { mediumLightGrey },
  } = theme;

  return {
    "& .MuiPaper-root": {
      borderRadius: "8px",
      border: `1px solid ${mediumLightGrey}`,
      boxShadow: "none",
      position: "absolute",
    },

    "& .MuiList-root": {
      display: "flex",
      flexDirection: "column",
    },
  };
});

export const MobileMenuItem = styled(MenuItem)(({ theme }) => {
  const {
    customPalette: { secondary },
  } = theme;

  return {
    width: "100%",

    "&.MuiButtonBase-root": {
      justifyContent: "flex-start",
      paddingLeft: "16px",
    },

    ":hover": {
      color: secondary,
      "&.fill": {
        path: {
          fill: secondary,
        },
      },
      "&.stroke": {
        path: {
          stroke: secondary,
        },
      },
    },
  };
});

export const IconButtonStyled = styled("div")(({ theme }) => {
  const {
    customPalette: { secondary },
  } = theme;

  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    height: "35px",
    width: "35px",
    borderRadius: "5px",
    ":hover": {
      "&.fill": {
        path: {
          fill: secondary,
        },
      },
      "&.stroke": {
        path: {
          stroke: secondary,
        },
      },
    },
  };
});

export const ButtonsContainer = styled("div")(({}) => {
  return {
    display: "flex",
    flexDirection: "column",
    width: "142px",
    height: "88px",
    justifyContent: "center",
    alignItems: "flex-start",
  };
});

export const ExpandMoreButton = styled(IconButton)<ExpandMoreProps>(
  ({ theme, expand }) => {
    return {
      transform: expand ? "rotate(0deg)" : "rotate(180deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    };
  }
);
