import { ACCESS, MOBILE_MESSAGE } from "../../utils/constants";

import { Container, SubContainer, Text, Logo, TextContainer } from "./styles";

const Mobile = () => (
  <Container>
    <SubContainer>
      <Logo />
      <TextContainer>
        <Text>{MOBILE_MESSAGE}</Text>
        <Text>{ACCESS}</Text>
      </TextContainer>
    </SubContainer>
  </Container>
);

export default Mobile;
