import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import {
  BottomContainer,
  BottomDivider,
  ButtonContainer,
  Container,
  Item,
  ItemButton,
  ItemIcon,
  ItemsContainer,
  Label,
  ListStyled,
  LogoToolbar,
  SidebarLogo,
} from "./styles";
import { LogoutIcon } from "../../assets";
import { logout } from "../../pages/Login/services/logout";
import { LOGOUT } from "../../utils/constants";
import { sidebarOptions } from "../../utils/utils";

interface SidebarProps {
  selectedIcon: string;
  selectedSubsection?: string;
  setSelectedSubSection?: (value: string) => void;
}

const Sidebar = ({
  selectedIcon,
  selectedSubsection,
  setSelectedSubSection,
}: SidebarProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    const selectedOption = sidebarOptions.find(
      (option) => option.path === selectedIcon
    );
    if (selectedOption?.subSections?.length > 0)
      setSelectedSubSection(selectedOption.subSections[0]);
  }, [selectedIcon]);

  const handleListItemClick = (path: string) => {
    navigate(path);
  };

  const handleSubSectionClick = (path: string) => {
    setSelectedSubSection(path);
  };

  return (
    <Container>
      <LogoToolbar>
        <SidebarLogo />
      </LogoToolbar>
      <ListStyled>
        <ItemsContainer>
          {sidebarOptions.map(
            ({ Icon, text, key, editRect, editStroke, path, subSections }) => (
              <div key={key}>
                <Item disablePadding>
                  <ItemButton
                    selected={path === selectedIcon}
                    onClick={() => handleListItemClick(path)}
                    editStroke={editStroke}
                    editRect={editRect}
                  >
                    <ButtonContainer>
                      <ItemIcon>
                        <Icon />
                      </ItemIcon>
                      <Label selected={path === selectedIcon}>{text}</Label>
                    </ButtonContainer>
                  </ItemButton>
                </Item>
                {path === selectedIcon &&
                  subSections?.map((section, index) => (
                    <Item key={`${key}-${index}`}>
                      <ItemButton
                        selected={section === selectedSubsection}
                        onClick={() => handleSubSectionClick(section)}
                      >
                        <ButtonContainer>
                          <Label selected={section === selectedSubsection}>
                            {section}
                          </Label>
                        </ButtonContainer>
                      </ItemButton>
                    </Item>
                  ))}
              </div>
            )
          )}
        </ItemsContainer>
        <BottomContainer>
          <BottomDivider variant="middle" />
          <Item disablePadding>
            <ItemButton editStroke onClick={logout}>
              <ButtonContainer>
                <ItemIcon>
                  <LogoutIcon />
                </ItemIcon>
                <Label>{LOGOUT}</Label>
              </ButtonContainer>
            </ItemButton>
          </Item>
        </BottomContainer>
      </ListStyled>
    </Container>
  );
};

export default Sidebar;
