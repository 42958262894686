import styled from "styled-components";

interface ContainerProps {
  position?: { left: number; top: number };
}

export const Container = styled("div")<ContainerProps>(
  ({ theme, position }) => {
    const {
      customPalette: {
        card: { boxShadow },
      },
    } = theme;

    return {
      display: "flex",
      flexDirection: "column",
      gap: "5px",
      alignItems: "center",
      boxShadow,
      position: "absolute",
      borderRadius: "8px",
      left: position.left,
      top: position.top,
      backgroundColor: "white",
      padding: "8px 8px 8px 8px",
    };
  }
);

export const Text = styled("h1")<{ color?: string }>(({ theme, color }) => {
  const {
    customSizes: {
      tagLabel: { fontSize, fontWeight },
    },
    customPalette: { darkMediumGrey },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight: "4px",
    padding: "0px",
    textTransform: "capitalize",
    color: color || darkMediumGrey,
    fontFamily: poppins,
  };
});

export const TagContainer = styled("div")<{ backgroundColor: string }>(
  ({ backgroundColor }) => {
    return {
      backgroundColor,
      borderRadius: "8px",
      padding: "4px 8px 4px 8px",
    };
  }
);
