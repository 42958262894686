import React from "react";

import { StyledButton } from "./styles";

export interface ButtonProps {
  id?: string;
  disabled?: boolean;
  height?: number;
  label: string | React.ReactNode;
  onClick?: () => void;
  borderRadius?: number;
  variantType: "primary" | "secondary";
  width?: number | string;
  fontSize?: number;
  fontWeight?: number;
  bottomButton?: string;
  position?: string;
  lineHeight?: number;
  padding?: string;
  color?: string;
  fontFamily?: string;
  textColor?: string;
  minWidth?: number;
  icon?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  id,
  disabled = false,
  height,
  onClick = () => null,
  label,
  variantType,
  width,
  fontSize,
  borderRadius = 10,
  bottomButton,
  position = "relative",
  lineHeight,
  padding = "0",
  color,
  fontFamily,
  textColor = "",
  minWidth,
  icon,
}) => (
  <StyledButton
    id={id}
    disabled={disabled}
    height={height}
    label={label}
    onClick={onClick}
    variantType={variantType}
    width={width}
    fontSize={fontSize}
    bottomButton={bottomButton}
    padding={padding}
    position={position}
    borderRadius={borderRadius}
    lineHeight={lineHeight}
    color={color}
    fontFamily={fontFamily}
    textColor={textColor}
    minWidth={minWidth}
  >
    {icon && <span style={{ display: "flex" }}>{icon}</span>}
    {label}
  </StyledButton>
);
export default Button;
