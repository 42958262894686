import { LeftArrow, RightArrow } from "../../assets";
import { Container, ArrowButton, Page } from "./styles";
import theme from "../../definitions/theme";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "redux/slices/userResponseSlice";

interface Props {
  currentPage: number;
  pages: number;
}

const PageCounter = ({ currentPage, pages }: Props) => {
  const dispatch = useDispatch();

  const setPage = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  const pagesArray = Array.from({ length: pages }, (_, index) => index + 1);
  return (
    <Container>
      {currentPage > 1 && (
        <ArrowButton
          cursor={currentPage === 1 ? "initial" : "pointer"}
          onClick={() => setPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <LeftArrow
            fill={
              currentPage === 1
                ? theme.customPalette.grey
                : theme.customPalette.secondary
            }
          />
        </ArrowButton>
      )}
      {pagesArray.map((page, index) => (
        <Page
          key={index}
          currentPage={currentPage === page}
          onClick={() => setPage(page)}
        >
          {page}
        </Page>
      ))}
      {currentPage < pages && (
        <ArrowButton
          cursor={currentPage === pages ? "initial" : "pointer"}
          disabled={currentPage === pages}
          onClick={() => setPage(currentPage + 1)}
        >
          <RightArrow
            fill={
              currentPage === pages
                ? theme.customPalette.grey
                : theme.customPalette.secondary
            }
          />
        </ArrowButton>
      )}
    </Container>
  );
};
export default PageCounter;
