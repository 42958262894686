import { AxiosResponse } from "axios";

import api from "../../../api";
import store from "../../../redux/store";
import {
  clearApiError,
  setApiError,
} from "redux/slices/userResponseSlice";

const { dispatch } = store;

export const createEmptyCategory = async (responseId: number) => {
  const url = `categories/${responseId}/empty`;
  let response;
  await api
    .post(url)
    .then((res: AxiosResponse) => {
      dispatch(clearApiError());
      response = res?.data;
    })
    .catch((error) => {
      console.log("[createEmptyCategory error]: ", error);
      dispatch(setApiError({ error: true }));
    });

  return response;
};
