import { PanelItemContainer, InputAdornment, Text } from "./styles";
import { getQuestionTypeData } from "../../utils/utils";

const QuestionTypeMatcherComponent = ({ id, onClick, questionType }) => {
  const { title, Icon } = getQuestionTypeData(questionType);

  return (
    <PanelItemContainer onClick={onClick} key={id}>
      <InputAdornment>
        <Icon />
      </InputAdornment>
      <Text>{title}</Text>
    </PanelItemContainer>
  );
};

export default QuestionTypeMatcherComponent;
