import { Container, ContentContainer, Text } from "./styles";

const InfoModal = () => {
  return (
    <Container>
      <ContentContainer>
        <Text>
          Follow{" "}
          <a
            href="https://developer.calendly.com/how-to-authenticate-with-personal-access-tokens"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "white" }}
          >
            these steps
          </a>{" "}
          to get your Calendly token and URL.
        </Text>
      </ContentContainer>
    </Container>
  );
};

export default InfoModal;
