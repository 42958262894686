import styled from "styled-components";

export const Container = styled("div")(({ theme }) => {
  return {
    height: "32px",
    bottom: 16,
    left: 432,
    display: "flex",
    alignItems: "center",
    border: `1px solid ${theme.customPalette.darkGrey}`,
    backgroundColor: theme.customPalette.darkGrey,
    position: "absolute",
    borderRadius: "8px",
    padding: "10px",
    zIndex: 100,
    "&::before": {
      content: "''",
      position: "absolute",
      borderTop: "16px solid transparent",
      borderBottom: "16px solid transparent",
      borderRight: `16px solid ${theme.customPalette.darkGrey}`,
      left: "-8px",
    },
  };
});

export const ContentContainer = styled("div")(({ theme }) => {
  return {
    padding: "16px",
  };
});

export const Text = styled("p")(({ theme }) => {
  const {
    customPalette: { white },
    customSizes: {
      preview: {
        subtitle: { fontSize, fontWeight, lineHeight },
      },
    },
  } = theme;

  return {
    fontSize,
    fontWeight,
    lineHeight,
    color: white,
  };
});

export const InfoModalContainer = styled("div")``;
