import styled from "styled-components";

export const CustomTooltipContainer = styled("div")<{
  backgroundColor?: string;
}>(({ theme, backgroundColor }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.customPalette.lightGrey,
  padding: "8px",
  borderRadius: "8px",
  border: `1px solid ${theme.customPalette.white}`,
}));

export const TextTooltip = styled("p")<{
  type: "label" | "value";
}>(({ theme, type }) => {
  const {
    customSizes: {
      chart: {
        value: {
          fontSize: valueFontSize,
          lineHeight: valueLineHeight,
          fontWeight: valueFontWeight,
        },
        label: {
          fontSize: labelFontSize,
          lineHeight: labelLineHeight,
          fontWeight: labelFontWeight,
        },
      },
    },
    customFonts: { poppins },
  } = theme;

  return {
    fontSize: type === "label" ? labelFontSize : valueFontSize,
    fontWeight: type === "label" ? labelFontWeight : valueFontWeight,
    lineHeight: type === "label" ? labelLineHeight : valueLineHeight,
    fontFamily: poppins,
    color: theme.customPalette.black,
  };
});
