import React from "react";
import { menuClasses } from "@mui/material/Menu";
import { selectClasses } from "@mui/material/Select";
import { Select, MenuItem } from "@mui/material";

import CheckoutButton from "../../components/checkbox";
import theme from "../../definitions/theme";
import { ExpandMore } from "../../assets";
import { CategoryState } from "types";
import CategoryCard from "components/categoryCard";
import { SelectInput } from "../ResponseDetails/styles";
import { TagContainer } from "./styles";

export interface Props {
  selectedTags: CategoryState[];
  handleTagClick: (value: CategoryState) => void;
  options: Record<number, string>;
  disabled?: boolean;
}

const StyledSelect = ({
  selectedTags,
  handleTagClick,
  options,
  disabled,
}: Props) => {
  return (
    <Select
      disabled={disabled}
      disableUnderline
      variant="standard"
      multiple
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        sx: {
          alignItems: "center",
          marginTop: "4px",
          [`& .${menuClasses.paper}`]: {
            borderRadius: "7px",
            border: `2px solid ${theme.customPalette.secondary}`,
          },
          [`& .${menuClasses.list}`]: {
            paddingTop: 0,
            paddingBottom: 0,
            background: "white",
            marginTop: "16px",
            "& li:hover": {
              backgroundColor: "transparent !important",
            },
            "& li.Mui-selected": {
              color: "white",
              backgroundColor: "transparent !important",
            },
            "& li.Mui-selected:hover": {
              backgroundColor: "transparent !important",
            },
          },
        },
      }}
      value={selectedTags}
      renderValue={() => (
        <SelectInput>
          {selectedTags?.length > 0 ? (
            <>
              {selectedTags.slice(0, 2).map((tag) => (
                <CategoryCard key={tag.id} text={tag.name} />
              ))}
              {selectedTags.length > 2 && (
                <CategoryCard text={`+${selectedTags.length - 2} more`} />
              )}
            </>
          ) : (
            <TagContainer>Add Tag</TagContainer>
          )}
        </SelectInput>
      )}
      displayEmpty
      IconComponent={ExpandMore}
      sx={{
        minWidth: 200,
        width: "100%",
        [`& .${selectClasses.select}`]: {
          background: "white",
          display: "flex",
          color: theme.customPalette.grey,
          borderRadius: "7px",
          border: `2px solid ${theme.customPalette.textField.default.border}`,
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingTop: "10px",
          paddingBottom: "10px",
          alignItems: "center",
          "&:focus": {
            borderRadius: "7px",
            border: `2px solid ${theme.customPalette.textField.default.border}`,
          },
          "&:hover": {
            borderRadius: "7px",
            border: disabled
              ? `2px solid ${theme.customPalette.textField.default.border}`
              : `2px solid ${theme.customPalette.hover}`,
          },
        },
        [`& .${selectClasses.icon}`]: {
          right: "12px",
          top: "12px",
        },
      }}
    >
      {Object.entries(options).map(([key, label]) => (
        <MenuItem
          key={key}
          value={parseInt(key)}
          style={{ cursor: "default" }}
          disableRipple
        >
          <CheckoutButton
            labelValue={label}
            focus={selectedTags.some((tag) => tag.id === parseInt(key))}
            onClick={() => handleTagClick({ id: parseInt(key), name: label })}
            tag
          />
        </MenuItem>
      ))}
    </Select>
  );
};

export default StyledSelect;
