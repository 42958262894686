import { AxiosResponse } from "axios";

import api from "../../../api";
import store from "../../../redux/store";
import {
  clearApiError,
  setApiError,
  setBranding,
  setNda,
} from "../../../redux/slices/companySlice";
import { setSurveys } from "../../../redux/slices/surveySlice";

const { dispatch } = store;

export const getCompany = async (id: number) => {
  await api
    .get(`/company/${id}`)
    .then((res: AxiosResponse) => {
      const { branding, nda, survey } = res?.data;

      dispatch(setBranding({ ...branding }));
      dispatch(setNda({ ...nda }));
      dispatch(setSurveys(survey));
      dispatch(clearApiError());
    })

    .catch((error) => {
      console.log("[getCompany error]: ", error);
      dispatch(setApiError({ error: true }));
    });
};
