import React from "react";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Card,
  Container,
  HeaderContainer,
  HomeLogo,
  TextContainer,
  Title,
  Text,
  InputContainer,
  TextFieldContainer,
  Label,
} from "./styles";
import {
  ENTER_PASSWORD,
  ENTER_EMAIL,
  LOGIN,
  PASSWORD,
  EMAIL,
} from "../../utils/constants";
import { Button, ErrorModal, TextInput } from "../../components";
import { login } from "./services/login";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { clearApiError, clearAuthError } from "../../redux/slices/authSlice";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { errorMessage, accessToken, apiError } = useAppSelector(
    (state) => state.authSlice
  );

  const inputData = [
    {
      text: EMAIL,
      id: "inputEmail",
      placeholder: ENTER_EMAIL,
      onChange: (event: ChangeEvent<HTMLInputElement>) =>
        setEmail(event.target.value),
      value: email,
      password: false,
    },
    {
      text: PASSWORD,
      id: "inputPassword",
      placeholder: ENTER_PASSWORD,
      onChange: (event: ChangeEvent<HTMLInputElement>) =>
        setPassword(event.target.value),
      value: password,
      password: true,
      onIconClick: () => setShowPassword(!showPassword),
      showPassword,
      type: showPassword ? "text" : "password",
    },
  ];

  const onClickLogin = async () => await login({ email, password });

  useEffect(() => {
    if (accessToken) {
      navigate("/survey");
    }
  }, [accessToken, navigate]);

  useEffect(() => {
    dispatch(clearAuthError());
    dispatch(clearApiError());
  }, []);

  return (
    <Container>
      <HeaderContainer>
        <HomeLogo />
      </HeaderContainer>
      <Card>
        <TextContainer>
          <Title>{LOGIN}</Title>
        </TextContainer>
        <TextFieldContainer>
          {inputData.map(
            ({
              text,
              id,
              placeholder,
              onChange,
              value,
              password,
              onIconClick,
              showPassword,
              type,
            }) => (
              <InputContainer key={id}>
                <Text isError={!!errorMessage}>{text}</Text>
                <TextInput
                  id={id}
                  placeholder={placeholder}
                  onChange={onChange}
                  value={value}
                  password={password}
                  onIconClick={onIconClick}
                  showErrorLabel={false}
                  showPassword={showPassword}
                  type={type}
                  error={!!errorMessage}
                />
              </InputContainer>
            )
          )}
          <Label>{errorMessage || ""}</Label>
          <Button
            id="btn"
            variantType="primary"
            onClick={onClickLogin}
            label={LOGIN}
            disabled={email === "" || password === ""}
            width="100%"
            height={48}
          />
        </TextFieldContainer>
      </Card>
      {apiError && (
        <ErrorModal
          isOpen
          handleClose={() => dispatch(clearApiError())}
          handleTryAgain={onClickLogin}
        />
      )}
    </Container>
  );
};

export default Login;
