import { Option } from "components/customSelect";

export const filters = [
  { key: 1, value: "Industries" },
  { key: 2, value: "Locations" },
  { key: 3, value: "Temperatures" },
  { key: 4, value: "Technologies" },
  { key: 5, value: "Maturities" },
];

export const xAxisOptions = [
  { key: 1, value: "Industry" },
  { key: 2, value: "Location" },
  { key: 3, value: "Temperature" },
  { key: 4, value: "Technology" },
  { key: 5, value: "Maturity" },
  { key: 6, value: "Date" },
];

export const presetFilters: Option[] = [
  { key: 0, value: "7 Days" },
  { key: 1, value: "1 Month" },
  { key: 2, value: "3 Months" },
  { key: 3, value: "6 Months" },
  { key: 4, value: "1 Year" },
  { key: 5, value: "All Time" },
];
